import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SortStrPipe } from './sort-str.pipe';

@NgModule({
  declarations: [SortStrPipe],
  imports: [CommonModule],
  exports: [SortStrPipe]
})
export class SortStrPipeModule {}
