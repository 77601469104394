import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';

import { PageConstant } from '../constants';
import { SelfServiceSelectors } from '../store/self-service/self-service.selector';

@Injectable({
  providedIn: 'root'
})
export class SelfServiceGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean> | boolean {
    return this.store.select(SelfServiceSelectors.isSetupComplete).pipe(
      map((isSetupComplete) => {
        if (!isSetupComplete) {
          this.router.navigateByUrl(PageConstant.SELF_SERVICE_SETUP_PAGE, {
            replaceUrl: true
          });
          return false;
        }
        return true;
      })
    );
  }
}
