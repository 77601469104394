import { NormalizedSeat } from 'src/app/models';

export class SelectSeat {
  static readonly type = '[Seat] Select a Seat';
  constructor(
    public readonly seatId: number = null,
    public readonly removeTicket: boolean = false
  ) {}
}

export class UnselectSelectedSeat {
  static readonly type = '[Seat] Unselect a Seat';
}

export class UpdateLocalSeat {
  static readonly type = '[Seat] Update Local Seat';
  constructor(public readonly normalizedSeat: Partial<NormalizedSeat>) {}
}

export class SelectNewSeat {
  static readonly type = '[Seat] Select New Seat';
  constructor(public readonly seatId: number) {}
}

export class SelectNewSeatSuccess {
  static readonly type = '[Seat] Select New Seat Success';
  // constructor(public readonly seatId: number) {}
}

export class SelectSeatFromDiagram {
  static readonly type = '[Seat] Select Seat From Diagram';
  constructor(
    public readonly seatId: number,
    public readonly tableId: number
  ) {}
}

export class SelectSeatFromDiagramSuccess {
  static readonly type = '[Seat] Select Seat From Diagram Success';
}

export class UnselectSeat {
  static readonly type = '[Seat] Unselect Seat';
}
