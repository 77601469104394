import { NormalizedSchema, schema } from 'normalizr';
import { NormalizedEntities, PaymentRequestType, Transaction } from '..';

export const transactionsSchema = new schema.Entity<Transaction>(
  'transactions',
  {},
  {
    idAttribute: 'id'
  }
);
export const transactionsSchemaArray = new schema.Array(transactionsSchema);

export interface NormalizedTransaction {
  id: number;
  ticket_uuid: string;
  amount: number;
  type: PaymentRequestType;
  diner_id: number;
  diner_name: string;
  pos_location_id: number;
  department_id?: number;
  trancloud_transaction_id?: number;
  pos_charge_account_id?: number;
  pos_gift_card_id?: number;
}

export type NormalizedTransactionData = NormalizedSchema<
  Pick<NormalizedEntities, 'transactions'>,
  number[]
>;
