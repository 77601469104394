import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { DepartmentChargeAccountPaymentModal } from './department-charge-account.modal';

@NgModule({
  declarations: [DepartmentChargeAccountPaymentModal],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    DebounceClickDirectiveModule,
    NgSelectModule
  ],
  exports: [DepartmentChargeAccountPaymentModal]
})
export class DepartmentChargeAccountPaymentModalModule {}
