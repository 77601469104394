import { POSManagerOperator } from 'src/app/models/pos-manager.model';

export class GetPOSOperators {
  static readonly type = '[Operators] Get POS Operators';
}

export class CreatePOSOperator {
  static readonly type = '[Operators] Create POS Operator';
  constructor(public newOperator: POSManagerOperator) {}
}

export class CreatePOSOperatorSuccess {
  static readonly type = '[Operators] Operator Created Successfully';
  constructor(public operator: POSManagerOperator) {}
}

export class UpdatePOSOperator {
  static readonly type = ' [Operator] Update POS Operator';
  constructor(public operator: POSManagerOperator) {}
}

export class UpdatePOSOperatorSuccess {
  static readonly type = '[Operators] Operator Updated Successfully';
}

export class DeletePOSOperator {
  static readonly type = 'Delete POS Operator';
  constructor(public id: number) {}
}

export class DeletePOSOperatorSuccess {
  static readonly type = '[Operators] Operator Deleted Successfully';
}
