import { StateToken } from '@ngxs/store';
import { RefundResponse, Ticket, TransactionList } from 'src/app/models';

export interface TransactionLookupStateModel {
  transactions: TransactionList[];
  selectedTransaction: number;
  originalTicket: Ticket;
  refundTicket: RefundResponse;
  isManualRefundModalOpen: boolean;
  //Approving operator ID. Clear after refund is complete.
  pos_operator_id: number;
}

export const TRANSACTION_LOOKUP_STATE_TOKEN =
  new StateToken<TransactionLookupStateModel>('transaction_lookup');
