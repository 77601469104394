import { ComponentPortal, ComponentType, Portal } from '@angular/cdk/portal';
import { Injectable, Injector, StaticProvider } from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  firstValueFrom,
  from,
  switchMap
} from 'rxjs';
import { MenuRef } from './menu.ref';
import { MenuController } from '@ionic/angular';
import { MENU_DATA_TOKEN } from './menu.token';
import _ from 'lodash';

export interface MenuOptions<T = any> {
  width: string;
  maxWidth: string;
  minWidth: string;
  side: 'start' | 'end';
  type: 'overlay' | 'reveal' | 'push';
  data: T;
}

export type MenuAttributes = Omit<MenuOptions, 'data'>;

const DEFAULT_MENU_ATTRS: MenuAttributes = {
  width: '100%',
  maxWidth: '530px',
  minWidth: '0px',
  side: 'end',
  type: 'overlay'
};

@Injectable()
export class PosMenuController {
  private readonly _menuId$ = new BehaviorSubject<string>('menuId');
  readonly menuId$ = this._menuId$.asObservable();
  get menuId(): string {
    return this._menuId$.value;
  }

  private readonly _portal$: BehaviorSubject<Portal<any> | null> =
    new BehaviorSubject(null);
  readonly portal$ = this._portal$.asObservable();

  set portal(val: Portal<any> | null) {
    this._portal$.next(val);
  }

  get portal(): Portal<any> | null {
    return this._portal$.value;
  }

  private readonly _menuAttrs$: BehaviorSubject<MenuAttributes> =
    new BehaviorSubject(DEFAULT_MENU_ATTRS);
  readonly menuAttrs$ = this._menuAttrs$.asObservable();

  private readonly _closedData$ = new Subject<any>();

  constructor(
    private readonly _injector: Injector,
    private readonly _menuController: MenuController
  ) {}

  setMenuId(id: string) {
    this._menuId$.next(id);
  }

  async open<T, D = any>(
    component: ComponentType<T>,
    options?: Partial<MenuOptions<D>>
  ) {
    if (!!this.portal) {
      await this.close();
    }

    if (!!options) {
      this._loadAttrs(options);
    }

    const createInjectors = () => {
      const providers: StaticProvider[] = [
        {
          provide: MenuRef,
          useValue: new MenuRef(this)
        }
      ];

      if (!!options?.data) {
        providers.push({
          provide: MENU_DATA_TOKEN,
          useValue: _.cloneDeep(options.data)
        });
      }

      return Injector.create({
        parent: this._injector,
        providers
      });
    };

    const componentPortal = new ComponentPortal(
      component,
      null,
      createInjectors(),
      null
    );

    this._portal$.next(componentPortal);

    await this._menuController.open(this.menuId);

    return firstValueFrom(this._closedData$.asObservable());
  }

  private _loadAttrs<D = any>(options: Partial<MenuOptions<D>>) {
    const attrs: Partial<MenuAttributes> = {};

    for (const key in options) {
      if (key === 'data') {
        continue;
      }

      attrs[key] = options[key];
    }

    this._menuAttrs$.next({
      ...this._menuAttrs$.value,
      ...attrs
    });
  }

  async close<K = any>(data?: K) {
    const opened = await this._menuController.isOpen();

    if (!!this.portal && !opened) {
      return;
    }

    await this._menuController.close(this.menuId);

    this.clearView();

    this._closedData$.next(data);
  }

  clearView() {
    this._menuAttrs$.next(DEFAULT_MENU_ATTRS);

    if (!this.portal) {
      return;
    }

    this.portal.detach();
    this._portal$.next(null);
  }

  setAttrs(attrs: Partial<MenuAttributes>) {
    this._loadAttrs(attrs);
  }
}
