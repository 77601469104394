import { StateToken } from '@ngxs/store';
import {
  BaseData,
  Department,
  GatewayTransactionResult,
  GiftCard,
  Ticket,
  PosPaymentRequest
} from 'src/app/models';

export interface LastGatewayTransaction {
  ticket: Ticket;
  paymentRequest: PosPaymentRequest;
  result?: GatewayTransactionResult;
  stripe_payment_secret?: string;
  stripe_pk_test?: string;
  stripe_pk_production?: string;
  stripe_account_id?: string;
}

export interface PaymentStateModel {
  departments: Department[];
  businessLines: BaseData[];
  selectedGiftCard: GiftCard;
  lastGatewayTransaction: LastGatewayTransaction;
}

export const PAYMENT_STATE_TOKEN = new StateToken<PaymentStateModel>('payment');
