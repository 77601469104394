import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NumericKeyboardComponent } from './numeric-keyboard.component';
import { PasswordPipeModule } from 'src/app/pipes/password-pipe/password-pipe.module';
import { NegativePipeModule } from 'src/app/pipes/negative-pipe/negative-pipe.module';
import { IonicModule } from '@ionic/angular';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';

@NgModule({
  declarations: [NumericKeyboardComponent],
  imports: [
    CommonModule,
    IonicModule,
    DebounceClickDirectiveModule,
    PasswordPipeModule,
    NegativePipeModule
  ],
  exports: [NumericKeyboardComponent]
})
export class NumericKeyboardComponentModule {}
