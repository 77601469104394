import { PriceType, PriceTypeWeightTypeMap, WeightUnit } from 'src/app/models';
import { ScaleConstant } from 'src/app/constants/scale.constant';

export class ScaleHelper {
  static PriceTypeToWeightType(priceType: PriceType): WeightUnit {
    const weightType: WeightUnit = PriceTypeWeightTypeMap[priceType];
    return weightType;
  }

  static conversionTo(
    weight: number,
    weightType: WeightUnit,
    convertTo: WeightUnit
  ) {
    if (!weightType) {
      return 0;
    }

    // e.g WeightUnitValue[`OneLbMadeOf`]['gram'] or e.g.WeightUnitValue.OneLbMadeOf.gram
    let conversionRatePerUnit = 0;
    try {
      conversionRatePerUnit =
        ScaleConstant.WeightUnitValue[`One${weightType}MadeOf`][`${convertTo}`];
    } catch (e) {
      const er = `Unsupported weight conversion for Weight "${weight}" from "${weightType}" to "${convertTo}" eq=> One${weightType}MadeOf => ${convertTo}`;
      e['message'] = `${er} exception:${e['message']}`;
    }
    return weight * conversionRatePerUnit;
  }

  static weightConversion(
    _weight: any,
    _from: WeightUnit,
    _to: WeightUnit
  ): any {
    const weight: number = _weight as number;
    return ScaleHelper.conversionTo(weight, _from, _to);
  }

  static convertRawWeight(weightStr: string) {
    const supportedWeightUnits = Object.values([
      WeightUnit.Kilograms,
      WeightUnit.Pound,
      WeightUnit.Ounce,
      WeightUnit.Gram
    ]);
    const currentUnit = supportedWeightUnits.find((unit) =>
      weightStr.toLowerCase().includes(unit)
    );
    const unitIndex = currentUnit && weightStr.indexOf(currentUnit);
    if (unitIndex > -1) {
      const weight = weightStr.substring(0, unitIndex);
      return { weight: +weight, unit: currentUnit };
    }
    return undefined;
  }
}
