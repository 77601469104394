import _ from 'lodash';

import {
  PosTime,
  TimeRange,
  TimeRangeValue,
  TimeValue
} from 'src/app/models/time.model';

export class TimeHelper {
  static getPosTime(time: string): PosTime {
    const timeParts = time.trim().split(':');

    if (timeParts.length !== 2) {
      throw new Error('Invalid time');
    }

    const hour = Number(timeParts[0]);
    const minute = Number(timeParts[1]);

    if (hour == null || hour < 0 || minute == null || minute < 0) {
      throw new Error('Invalid time');
    }

    return { hour: Number(hour), minute: Number(minute) };
  }

  static getTimeWithMarker(
    hour: number,
    minute: number,
    isShort = false
  ): string {
    const hourToShow = hour % 12;
    const marker: string = Math.floor(hour / 12) === 1 ? 'PM' : 'AM';

    const minutesToDisplay =
      isShort && minute === 0 ? '' : `:${_.toString(minute).padStart(2, '0')}`;

    if (hourToShow === 0) {
      return `12${minutesToDisplay} ${marker}`;
    }

    return `${
      isShort ? hourToShow : _.toString(hourToShow).padStart(2, '0')
    }${minutesToDisplay} ${marker}`;
  }

  static getTimeString(hour: number, minute: number): string {
    return `${_.toString(hour).padStart(2, '0')}:${_.toString(minute).padStart(
      2,
      '0'
    )}`;
  }

  static getTimeRange(time: PosTime, duration: PosTime): TimeRange {
    return {
      start: time,
      end: TimeHelper.addTime(time, duration)
    };
  }

  static minusByMinute(time: PosTime, minute: number): PosTime {
    const minusHour = Math.round(minute / 60);
    const minusMinute = minute % 60;

    return {
      hour: time.hour - minusHour,
      minute: time.minute - minusMinute
    };
  }

  static getTimeDurationFromTimeRange(range: TimeRange): PosTime {
    return TimeHelper.convertTimeValueToTime(
      TimeHelper.convertTimeToTimeValue(range.end) -
        TimeHelper.convertTimeToTimeValue(range.start)
    );
  }

  static isTimeInRange(
    time: PosTime,
    range: TimeRange,
    options?: { ignoreEnd?: boolean; ignoreStart?: boolean }
  ): boolean {
    const startCompare = TimeHelper.compareTime(range.start, time);
    const endCompare = TimeHelper.compareTime(range.end, time);

    const ignoreStart = options?.ignoreStart;
    const ignoreEnd = options?.ignoreEnd;

    if (!!ignoreEnd && !!ignoreStart) {
      return startCompare < 0 && endCompare > 0;
    } else if (!!ignoreEnd && !ignoreStart) {
      return startCompare <= 0 && endCompare > 0;
    } else if (!ignoreEnd && !!ignoreStart) {
      return startCompare < 0 && endCompare >= 0;
    } else {
      return startCompare <= 0 && endCompare >= 0;
    }
  }

  static compareTime(time1: PosTime, time2: PosTime): number {
    const value1 = TimeHelper.convertTimeToTimeValue(time1);
    const value2 = TimeHelper.convertTimeToTimeValue(time2);

    return value1 - value2;
  }

  static convertTimeToTimeValue(time: PosTime): TimeValue {
    return time.hour + time.minute / 60;
  }

  static convertTimeValueToTime(value: TimeValue): PosTime {
    const minute = Math.round((value % 1) * 60);
    const hour = Math.floor(value);

    return { hour, minute };
  }

  static convertTimeRangeValueToTimeRange(
    rangeValue: TimeRangeValue
  ): TimeRange {
    return {
      start: TimeHelper.convertTimeValueToTime(rangeValue.start),
      end: TimeHelper.convertTimeValueToTime(rangeValue.end)
    };
  }

  static convertTimeRangeToTimeRangeValue(
    timeRange: TimeRange
  ): TimeRangeValue {
    return {
      start: TimeHelper.convertTimeToTimeValue(timeRange.start),
      end: TimeHelper.convertTimeToTimeValue(timeRange.end)
    };
  }

  static isTimeOverlap(time1: TimeRange, time2: TimeRange): boolean {
    const timeRangeValue1 = TimeHelper.convertTimeRangeToTimeRangeValue(time1);
    const timeRangeValue2 = TimeHelper.convertTimeRangeToTimeRangeValue(time2);

    return !(
      (timeRangeValue1.end >= timeRangeValue2.end &&
        timeRangeValue1.start >= timeRangeValue2.end) ||
      (timeRangeValue1.end <= timeRangeValue2.start &&
        timeRangeValue1.start <= timeRangeValue2.start)
    );
  }

  static addTime(time1: PosTime, time2: PosTime): PosTime {
    const totalHour = time1.hour + time2.hour;
    const totalMinute = time1.minute + time2.minute;

    const minute = totalMinute % 60;
    const hourToAdd = Math.floor(totalMinute / 60);

    return { hour: totalHour + hourToAdd, minute };
  }

  static subtractTime(time1: PosTime, time2: PosTime): PosTime {
    let subHour = time1.hour - time2.hour;
    subHour = subHour < 0 ? 0 : subHour;
    let subMin = time1.minute - time2.minute;
    subMin = subMin < 0 && !subHour ? 0 : subMin;

    if (subMin < 0) {
      return { hour: subHour - 1, minute: 60 + subMin };
    }

    return { hour: subHour, minute: subMin };
  }

  static generateTimeSeries(
    range: TimeRange,
    step: PosTime,
    includeEnd = false
  ): PosTime[] {
    const { hour: startHour, minute: startMinute } = range.start;
    const { hour: endHour, minute: endMinute } = range.end;
    const { hour: durationHour, minute: durationMinute } = step;

    let availableTime: PosTime = { hour: startHour, minute: startMinute };
    const availableTimes: PosTime[] = [];

    const isAvailableTimeValid = (): boolean => {
      const leftHour = endHour - availableTime.hour;

      if (leftHour > durationHour) {
        return true;
      } else if (leftHour < durationHour) {
        return false;
      } else {
        const leftMinute = endMinute - availableTime.minute;

        return includeEnd ? leftMinute >= 0 : leftMinute >= durationMinute;
      }
    };

    while (isAvailableTimeValid()) {
      availableTimes.push({
        hour: availableTime.hour,
        minute: availableTime.minute
      });

      availableTime = TimeHelper.addTime(availableTime, {
        hour: durationHour,
        minute: durationMinute
      });
    }

    return availableTimes;
  }

  static mergeTimeRanges(
    ranges: TimeRange[],
    excludes: TimeRange[] = []
  ): TimeRange[] {
    ranges.sort(
      (range1, range2) =>
        TimeHelper.convertTimeToTimeValue(range1.start) -
        TimeHelper.convertTimeToTimeValue(range2.start)
    );

    let mergedRanges: TimeRange[] = [];

    for (let i = 0; i < ranges.length; i++) {
      if (i === 0) {
        mergedRanges = [ranges[i]];
        continue;
      }

      const lastRangeOfMerged = mergedRanges[mergedRanges.length - 1];

      if (TimeHelper.isTimeOverlap(lastRangeOfMerged, ranges[i])) {
        const { start: start1, end: end1 } =
          TimeHelper.convertTimeRangeToTimeRangeValue(lastRangeOfMerged);
        const { start: start2, end: end2 } =
          TimeHelper.convertTimeRangeToTimeRangeValue(ranges[i]);

        const maxTime = Math.max(start1, start2, end1, end2);
        const minTime = Math.min(start1, start2, end1, end2);

        mergedRanges[mergedRanges.length - 1] = {
          start: TimeHelper.convertTimeValueToTime(minTime),
          end: TimeHelper.convertTimeValueToTime(maxTime)
        };
      } else {
        mergedRanges.push(ranges[i]);
      }
    }

    if (excludes.length === 0) {
      return mergedRanges;
    }

    excludes.forEach((exclude) => {
      const excludedRanges: TimeRange[] = [];

      mergedRanges.forEach((range) => {
        if (TimeHelper.isTimeOverlap(range, exclude)) {
          const { start: itemStart, end: itemEnd } =
            TimeHelper.convertTimeRangeToTimeRangeValue(range);
          const { start: excludeStart, end: excludeEnd } =
            TimeHelper.convertTimeRangeToTimeRangeValue(exclude);

          const arr: number[] = [itemStart, itemEnd, excludeStart, excludeEnd];
          const max = Math.max(...arr);
          const min = Math.min(...arr);

          if (min === excludeStart && max === excludeEnd) {
            return;
          } else if (min === itemStart && max === itemEnd) {
            excludedRanges.push(
              ...[
                {
                  start: TimeHelper.convertTimeValueToTime(min),
                  end: TimeHelper.convertTimeValueToTime(excludeStart)
                },
                {
                  start: TimeHelper.convertTimeValueToTime(excludeEnd),
                  end: TimeHelper.convertTimeValueToTime(max)
                }
              ]
            );
          } else if (min === excludeStart && max === itemEnd) {
            excludedRanges.push({
              start: TimeHelper.convertTimeValueToTime(excludeEnd),
              end: TimeHelper.convertTimeValueToTime(max)
            });
          } else {
            excludedRanges.push({
              start: TimeHelper.convertTimeValueToTime(min),
              end: TimeHelper.convertTimeValueToTime(excludeStart)
            });
          }
        } else {
          excludedRanges.push(range);
        }
      });

      mergedRanges = excludedRanges;
    });

    return mergedRanges;
  }

  static convertTimeStringToValue(time: string): number {
    const [hours, minutes] = _.split(time, ':');
    return _.sum([_.multiply(_.toNumber(hours), 60), _.toNumber(minutes)]);
  }
}
