import { DeviceInfo } from '@capacitor/device';
import { StateToken } from '@ngxs/store';

import {
  Authentication,
  CardTerminal,
  DevicePlatform,
  DeviceState,
  Operator,
  PaymentLocation,
  Printer,
  VisitingFacility
} from 'src/app/models';

export const APP_STATE_TOKEN = new StateToken<AppStateModel>('app');

export interface IOSDetails {
  name: string;
  version: string;
  versionName: string;
}

export interface PosHardwareDetails {
  local_os: Partial<IOSDetails>;
  uuid: string;
  platform: DevicePlatform;
  nfcEnabled: boolean;
  version: string;
  deviceInfo: DeviceInfo;
}

export interface AppStateModel extends Authentication {
  apiBaseUrl: string;
  device: DeviceState;
  defaults: {
    diet_id: number;
    texture_id: number;
  };
  operator: Operator;
  tempOperator: Operator;
  payment_location: PaymentLocation;
  // receipt_location: PaymentLocation;
  visiting_facilities: VisitingFacility[];
  idleSetup: boolean;
  printers: Printer[];
  defaultPrinter: number;
  defaultCardTerminal: CardTerminal;
  isInputFocused: boolean;
  // hardwareDetails: Partial<PosHardwareDetails>;
}
