import {
  createModelSelector,
  createPropertySelectors,
  createSelector
} from '@ngxs/store';

import { HardwareDeviceStateModel } from './hardware-device.model';
import { HardwareDeviceState } from './hardware-device.state';
import { DeviceHelper } from 'src/app/helpers/device.helper';

export class HardwareDeviceSelectors {
  static slices =
    createPropertySelectors<HardwareDeviceStateModel>(HardwareDeviceState);

  static localOsAndUuid = createModelSelector({
    local_os: HardwareDeviceSelectors.slices.local_os,
    uuid: HardwareDeviceSelectors.slices.uuid
  });

  static nfcEnabled = createSelector(
    [HardwareDeviceSelectors.slices.nfcEnabled],
    (nfcEnabled) => nfcEnabled
  );

  static platform = createSelector(
    [HardwareDeviceSelectors.slices.platform],
    (platform) => platform
  );

  static version = createSelector(
    [HardwareDeviceSelectors.slices.version],
    (version) => version
  );

  static isAndroid = createSelector(
    [HardwareDeviceSelectors.slices.platform],
    (platform) => platform === 'android'
  );

  static isIOS = createSelector(
    [HardwareDeviceSelectors.slices.platform],
    (platform) => platform === 'ios'
  );

  static deviceInfo = createSelector(
    [HardwareDeviceSelectors.slices.deviceInfo],
    (deviceInfo) => deviceInfo
  );

  static deviceModel = createSelector(
    [HardwareDeviceSelectors.deviceInfo],
    ({ model }) => model
  );

  static isEloI3 = createSelector(
    [HardwareDeviceSelectors.deviceModel],
    (model) => DeviceHelper.isEloI3(model)
  );

  static webViewVersion = createSelector(
    [HardwareDeviceSelectors.deviceInfo],
    ({ webViewVersion }) => webViewVersion
  );
}
