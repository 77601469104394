import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PosAlertType } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class HardwareDeviceService {
  constructor(private readonly http: HttpClient) {}

  supportAlerts(payload: {
    alert_type: PosAlertType;
    alert_message: string;
  }): Observable<{ success: boolean }> {
    const { alert_type, alert_message } = payload;

    return this.http.post<{ success: boolean }>('support_alerts', {
      alert_type,
      alert_message
    });
  }
}
