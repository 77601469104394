import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelfServiceGatewayTransactionModal } from './self-service-gateway-transaction.modal';
import { IonicModule } from '@ionic/angular';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [SelfServiceGatewayTransactionModal],
  imports: [
    CommonModule,
    IonicModule,
    DebounceClickDirectiveModule,
    FontAwesomeModule
  ],
  exports: [SelfServiceGatewayTransactionModal]
})
export class SelfServiceGatewayTransactionModalModule {}
