import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable, switchMap, finalize } from 'rxjs';

import { PosLoadingModalService } from 'src/app/components/shared/loading-modal/service';
import { MenuConstant } from 'src/app/constants/menu.constant';
import { POSFullMenu, POSMenuRequest } from 'src/app/models/pos-manager.model';
import { ApiType } from 'src/app/pos.config';
import { ErrorService } from 'src/app/services/error/error.service';
import { AppStateHelper } from 'src/app/store/app/app.state.helper';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';

@Injectable({ providedIn: 'root' })
export class PosMenuService {
  constructor(
    private readonly http: HttpClient,
    private readonly store: Store,
    private readonly posLoadingModal: PosLoadingModalService,
    private readonly errorService: ErrorService
  ) {}

  private get getBaseUrl(): string {
    const baseUrl = this.store.selectSnapshot(APP_STATE_TOKEN).apiBaseUrl;
    const posManagerApiUrl = AppStateHelper.getPosManagerApiUrl(baseUrl);

    return `${posManagerApiUrl}menu_addons`;
  }

  getMenus(request: POSMenuRequest): Observable<POSFullMenu> {
    if (!request) {
      throw new Error('Menu request can not be empty');
    }
    const { date, pos_location_id, meal_id, include_all_items } = request;
    const newRequest = meal_id
      ? request
      : { date, pos_location_id, include_all_items };
    const items$ = this.http
      .get<POSFullMenu>(this.getBaseUrl, {
        params: { ...newRequest }
      })
      .pipe(
        this.errorService.retryPipe(ApiType.POSItemsManagingLoading),
        map((response) => ({
          ...response,
          menu_items: response.menu_items.map((item) => ({
            ...item,
            locationId: pos_location_id
          })),
          also_available_menu_items: response.also_available_menu_items.map(
            (item) => ({
              ...item,
              locationId: pos_location_id
            })
          ),
          products: response.products.map((item) => ({
            ...item,
            locationId: pos_location_id
          }))
        }))
      );

    return this.posLoadingModal
      .presentLoadingModal(
        MenuConstant.LOADING_MODAL_MSG.LOADING_ITEMS,
        MenuConstant.LOADING_MODAL_MSG.MENU_TALLY_LOADING_LONGER
      )
      .pipe(
        switchMap(() => items$),
        finalize(() => this.posLoadingModal.dismiss())
      );
  }
}
