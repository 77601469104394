import { MealHelper } from 'src/app/helpers';
import { LocationType, PosLocation } from 'src/app/models';

export class LocationStateHelper {
  static getMealId = (currentMealId: number, locationType: LocationType) =>
    currentMealId || (locationType === LocationType.Services ? undefined : 0);

  static getIsCoursingEnabled = (location: PosLocation) =>
    location?.type === LocationType.DiningRoom &&
    location.dining_room_settings &&
    location.dining_room_settings.coursing_enabled;

  static getIsPersonalizedResidentMenus = (location: PosLocation) =>
    location &&
    [
      LocationType.DiningRoom,
      LocationType.QuickService,
      LocationType.SelfServe
    ].includes(location.type) &&
    location.dining_room_settings?.personalized_resident_menus;

  static getAutoAddProductsForMealPlan = (
    currentMealId: number,
    location: PosLocation
  ) => {
    const meal = MealHelper.ALL_MEALS_BY_ID[currentMealId];
    const result: number[] =
      location?.auto_add_products_when_meal_plan_selected[
        meal?.name.toLowerCase()
      ] || [];

    return result;
  };

  static getLocationUrl(
    url?: string,
    locationId?: number,
    defaultLocationId?: number
  ): string {
    const locationUrl = `locations/${locationId || defaultLocationId}`;

    if (!url) {
      return locationUrl;
    }

    return `${locationUrl}/${url}`;
  }
}
