import { Injectable } from '@angular/core';
import { Plugin, registerPlugin } from '@capacitor/core';

export interface NFCReaderPlugin extends Plugin {
  startNFC(): Promise<{ value: string }>;
  stopNFC(): Promise<{ value: string }>;
  isNFCEnabled(): Promise<{ success: boolean }>;
}

export interface NFCDataI {
  data: string;
}

@Injectable({
  providedIn: 'root'
})
export class NfcReaderService {
  NFCReader: NFCReaderPlugin;
  constructor() {
    this.NFCReader = registerPlugin<NFCReaderPlugin>('CloudStormNFC');
  }

  async startNFC(callback) {
    await this.NFCReader.startNFC();

    await this.NFCReader.addListener('NFCReaderData', callback);
  }

  async stopNFC() {
    await this.NFCReader.removeAllListeners();
    await this.NFCReader.stopNFC();
  }

  async isNFCEnabled() {
    return await this.NFCReader.isNFCEnabled();
  }
}
