import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { BillToRoomPaymentModal } from './bill-to-room.modal';
import { AvatarControlModule } from 'src/app/components/shared/avatar-control/avatar-control.module';

@NgModule({
  declarations: [BillToRoomPaymentModal],
  imports: [
    CommonModule,
    IonicModule,
    DebounceClickDirectiveModule,
    AvatarControlModule
  ],
  exports: [BillToRoomPaymentModal]
})
export class BillToRoomPaymentModalModule {}
