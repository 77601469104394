import { StateToken } from '@ngxs/store';

import {
  FloorplanStatus,
  NormalizedObject,
  NormalizedTable,
  PosTableStatus,
  StateObject,
  Table,
  TableShape
} from 'src/app/models';
import { TableReservationsStateModel } from 'src/app/store/tables/reservations/table-reservation.model.state';

export interface TableStateModel {
  newTables: StateObject<Table[]>;
  tables: NormalizedObject<NormalizedTable, number>;
  posListingTables: PosListingTable[];
  selectedTable: StateObject<number>;
  resetSelectedTableIds: boolean;
  selectedTableManagementFloorPlan: Table[];
  selectedTableManagementLocationId: number;
  selectedTableManagementEditingStatus: boolean;
  showTablesList: boolean;
  reservations?: TableReservationsStateModel;
}

export interface PosListingTable {
  id: number;
  status: PosTableStatus;
  name: string;
  timeSeated: string;
  reservedTime: number;
  totalSeatsNo: number;
  occupiedSeatsNo: number;
  isOverMaxHours?: boolean;
}

export interface PosFloorplanSeat {
  id: number;
  number: number;
  floorplan_status: FloorplanStatus;
  align: string;
  focus: string;
}

export interface PosFloorplanTable
  extends Omit<PosListingTable, 'timeSeated' | 'reservedTime'> {
  tableshape: TableShape;
  location: string;
  top: number;
  left: number;
  right: number;
  bottom: number;
  seats: PosFloorplanSeat[];
  pendingAssignment: boolean;
  assigned_staff_name: string;
  assigned_staff_id: number;
}

export const TABLES_STATE_TOKEN = new StateToken<TableStateModel>('tables');
