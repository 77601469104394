import _ from 'lodash';
import { MenuHelper } from 'src/app/helpers';

import { Menu, MenuItem, PriceType } from 'src/app/models';
import {
  ModifierPromptOption,
  ModifierPromptOptionType,
  PosQuantityRestriction
} from 'src/app/models/pos-manager.model';

export class MenuStateHelper {
  static formatMenuWithPrompt(menu: Menu): MenuItem[] {
    const fullMenu: MenuItem[] = [].concat(
        menu.menu_items,
        menu.products,
        menu.also_available
      ),
      // menuItems is a clone of fullMenu and it should be use as lookup with the real menu items
      // because real menu items may contains less data due to the options such as (show_standard_menu_items, show_non_food_products, ...)
      menuItems = _.cloneDeep(fullMenu);

    menuItems.forEach((menuItem) => {
      if (_.isEmpty(menuItem.modifier_prompt_assignments)) {
        return;
      }

      menuItem.modifier_prompt_assignments.forEach((modifierPrompt) => {
        let formattedOptions: ModifierPromptOption[] = [];
        if (_.isNil(modifierPrompt.modifier_prompt_options)) {
          modifierPrompt.formattedOptions = formattedOptions;
          return;
        }

        modifierPrompt.modifier_prompt_options.forEach((opt) => {
          switch (opt.option_type) {
            case ModifierPromptOptionType.Category:
              //Filter full menu for items that match the category in the modifier prompts
              const optionsMenuItems = fullMenu.filter(
                (i) =>
                  i.category_id === opt.category.id &&
                  i.price_type === PriceType.FIXED
              );
              if (optionsMenuItems?.length) {
                const promptsFromMenuItem = _.sortBy(
                  optionsMenuItems,
                  'sort'
                ).map(
                  (fullMenuItem) =>
                    <ModifierPromptOption>{
                      fullMenuItem,
                      id: fullMenuItem.id,
                      text_label: fullMenuItem.name,
                      option_type: ModifierPromptOptionType.CategoryChildItem,
                      added_price: fullMenuItem.price?.toString(),
                      category: opt.category,
                      sort_order: opt.sort_order
                    }
                );
                formattedOptions = formattedOptions.concat(promptsFromMenuItem);
              }
              break;
            case ModifierPromptOptionType.PosProduct:
              const product = fullMenu.find(
                (i) => i.id === opt.pos_product.id
              ) as MenuItem;
              formattedOptions.push(
                product
                  ? {
                      ...opt,
                      text_label: product.name,
                      added_price: product.price
                        ? product.price.toString()
                        : undefined,
                      fullMenuItem: product
                    }
                  : opt
              );
              break;
            default:
              formattedOptions.push(opt);
              break;
          }
        });

        modifierPrompt.formattedOptions = formattedOptions;
      });
    });
    return menuItems;
  }

  static addQuantityRestrictionsToPrompts(
    menuItem: MenuItem,
    qtyRestrictions: PosQuantityRestriction[]
  ) {
    if (
      _.isEmpty(menuItem.modifier_prompt_assignments) ||
      _.isEmpty(qtyRestrictions)
    ) {
      return menuItem;
    }
    const clonedMenuItem = _.cloneDeep(menuItem);
    clonedMenuItem.modifier_prompt_assignments.forEach((modifierPrompt) => {
      if (_.isNil(modifierPrompt.formattedOptions)) {
        return;
      }

      modifierPrompt.formattedOptions?.forEach((opt) => {
        opt.soldOut = MenuHelper.isMenuItemSoldOut(
          opt.fullMenuItem?.id,
          opt.fullMenuItem?.food_id,
          opt.fullMenuItem?.type,
          qtyRestrictions
        );
      });
    });
    return clonedMenuItem;
  }

  static getBarcodeProductsDictionary(menu: Menu): _.Dictionary<MenuItem[]> {
    // Check if the menuItem is a modifier type
    const products = _.cloneDeep(
      menu.products?.filter((x) => !x.modifier) || []
    );
    const dictionary = _.groupBy(products, 'barcode');
    return dictionary;
  }
}
