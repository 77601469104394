import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';

import { PageConstant } from '../constants';
import { AppState } from '../store/app/app.state';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private navController: NavController) {}

  canActivate() {
    return this.store.select(APP_STATE_TOKEN).pipe(
      map((appState) => {
        if (!appState.operator) {
          this.navController.navigateRoot([PageConstant.LOGIN_PAGE]);
          return false;
        }
        return true;
      })
    );
  }
}
