import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { AppStateHelper } from 'src/app/store/app/app.state.helper';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let url: string = request.url;

    if (!url.includes('http')) {
      const apiBaseUrl = this.store.selectSnapshot(APP_STATE_TOKEN).apiBaseUrl;
      const apiUrl = AppStateHelper.getApiUrl(apiBaseUrl);

      url = apiUrl + url;
    }
    const dupReq = request.clone({ url });
    return next.handle(dupReq);
  }
}
