import { DeviceId, DeviceInfo } from '@capacitor/device';
import { DeviceData, Operator } from 'src/app/models';

import { PosHardwareDetails } from 'src/app/store/app/app.state.model';

//#region App Setup

export class SetApiUrl {
  static readonly type = '[App Setup] Set API Host';
  constructor(public url: string) {}
}

export class SetDefaultDietAndTexture {
  static readonly type = '[App Setup] Set Default Diet And Texture';
  constructor(public tenant: string) {}
}

export class SetVisitingFacilities {
  static readonly type = '[App Setup] Set Visiting Facilities';
}

//#endregion

//#region Device Registration

export class GetDeviceRegistrationCode {
  static readonly type = '[Device Registration] Get Registration Code';
}

export class GetDeviceRegistrationCodeSuccess {
  static readonly type = '[Device Registration] Get Registration Code Success';
}

export class CompleteDeviceRegistration {
  static readonly type = '[Device Registration] Complete Device Registration';
}

export class UpdatedDevice {
  static readonly type = '[Device Registration] Updated Device';
  constructor(public readonly device: DeviceData) {}
}

//#endregion

//#region Auth

export class GetAuthentication {
  static readonly type = '[Auth] Get Authentication';
}

export class UpdatePinCode {
  static readonly type = '[Auth] Update the pin code for Operator';
  constructor(
    public currentOperator: Operator,
    public pin_code: string,
    public processLogin?: boolean
  ) {}
}

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public readonly operator: Operator) {}
}

export class TempLogin {
  static readonly type = '[Auth] Temporary Login';
  constructor(public operator: Operator) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class PatchAuthOperator {
  static readonly type = '[Auth] Update Auth Operator';
  constructor(
    public readonly operator: Partial<Operator> | ((prev: Operator) => Operator)
  ) {}
}

export class TempLogout {
  static readonly type = '[Auth] Temporary Logout';
}

export class LoginSelfService {
  static readonly type = '[Auth] Login Self Service';
  constructor(public readonly operator_id: number) {}
}

export class StartLRAndSentrySession {
  static readonly type = '[LogRocket] Start Log Rocket And Sentry Session';
  constructor(public readonly operator: Operator) {}
}

//#endregion

//#region Tool
export class GetCashoutReport {
  static readonly type = '[Tool] Get Cashout Report';
  constructor(public printer_id: number) {}
}

export class OpenCashDrawer {
  static readonly type = '[Tool] Open Cashout Drawer';
  constructor(public operatorId?: number, public fromTools = false) {}
}

export class SelectPaymentLocation {
  static readonly type = '[Tool] Select Payment Location';
  constructor(public readonly paymentLocationId: number) {}
}

export class SelectPaymentTerminal {
  static readonly type = '[Tool] Select Payment Terminal';
  constructor(public id: number) {}
}

export class SwitchDeviceLocation {
  static readonly type = '[Tools] Switch Device Location';
  constructor(public readonly pos_location_id: number) {}
}

export class SwitchDeviceLocationSuccess {
  static readonly type = '[Tools] Switch Device Location Success';
}

export class SwitchDeviceLocationError {
  static readonly type = '[Tools] Switch Device Location Error';
  constructor(public errorMessage: string) {}
}

export class GetPrinters {
  static readonly type = '[Tool] Get Printers';
}

export class SelectDefaultPrinter {
  static readonly type = '[Tool] Select Default Printer';
  constructor(public printer_id: number) {}
}

//#endregion

//#region Idle Timeout

export class StartIdleTime {
  static readonly type = '[Idle Timeout] Start Idle Time';
}

export class StopIdle {
  static readonly type = '[Idle Timeout] Stop Idle Time';
}

//#endregion

//#region Input Focused

export class FocusInput {
  static readonly type = '[Barcode] Input Focused';
}

export class InputLosesFocus {
  static readonly type = '[Barcode] Input Loses Focus';
}
//#endregion

export class ResetApplication {
  static readonly type = '[App] Reset Application';
}

export class WSCreatePOSOperator {
  static readonly type = '[Operators] WS Operator Created Successfully';
  constructor(public readonly operator: Operator) {}
}

export class WSUpdatePOSOperator {
  static readonly type = '[Operators] WS Operator Updated Successfully';
  constructor(public readonly operator: Operator) {}
}

export class WSDeletePOSOperator {
  static readonly type = '[Operators] WS Operator Deleted Successfully';
  constructor(public readonly pos_operator_id: number) {}
}

export class NetworkStatusChange {
  static readonly type = '[App] Network Status Change';
  constructor(public readonly networkConnected: boolean) {}
}

export class LoadHardwareDetails {
  static readonly type = '[Device] Load Hardware Details';
  constructor(
    public readonly deviceInfo: DeviceInfo,
    public readonly deviceId: DeviceId,
    public readonly nfcEnabled: boolean
  ) {}
}

export class LoadHardwareDetailsSuccess {
  static readonly type = '[Device] Load Hardware Details Success';
  constructor(public readonly posHardwareDetails: PosHardwareDetails) {}
}
