import { TakeoutOrderStatusChangeData, TicketLockedData } from 'src/app/models';

export class EnableTakeOutDelivery {
  static readonly type = '[TakeOut Delivery] Enable TakeOut Delivery';
}

export class UpdateTakeoutDeliveryFlag {
  static readonly type = '[TakeOut Delivery] Update TakeOut Delivery Flag';
  constructor(public enableTakeOutDelivery: boolean) {}
}

export class ExitTakeOutDelivery {
  static readonly type = '[TakeOut Delivery] Exit Take Out Delivery';
}

export class GetTakeoutDeliveryTickets {
  static readonly type = '[TakeOut Delivery] Get Takeout Delivery Tickets';
}

export class UpdateTakeoutDeliveryStatus {
  static readonly type = '[TakeOut Delivery] Update Takeout Delivery Status';
  constructor(public readonly wsMessage: TakeoutOrderStatusChangeData) {}
}

export class LockTakeoutTicket {
  static readonly type = '[TakeOut Delivery] Lock Takeout Ticket';
  constructor(public ticketId: string) {}
}

export class UnlockTakeoutTicket {
  static readonly type = '[TakeOut Delivery] Unlock Takeout Ticket';
  constructor(public ticketId: string) {}
}

export class CreateTakeoutDelivery {
  static readonly type = '[TakeOut Delivery] Create Takeout Delivery';
}

export class OpenTransactionTicketUuid {
  static readonly type = '[TakeOut Delivery] Open Transaction Ticket Uuid';
  constructor(public ticketUuid?: string) {}
}

export class UpdateTakeoutDeliveryLocked {
  static readonly type = '[TakeOut Delivery] Update Takeout Delivery Locked';
  constructor(public data: TicketLockedData) {}
}

export class LoadMdaTimings {
  static readonly type = '[TakeOut Delivery] Load Mda Timings';
  constructor(public dates: string[]) {}
}
