import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { PageConstant } from '../constants';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';

@Injectable({
  providedIn: 'root'
})
export class DeviceGuard implements CanActivate {
  constructor(private store: Store, private navController: NavController) {}

  canActivate(): Observable<boolean> | boolean {
    const isDeviceRegistered =
      !!this.store.selectSnapshot(APP_STATE_TOKEN).device.token;
    if (!isDeviceRegistered) {
      this.navController.navigateRoot(PageConstant.DEVICE_REGISTRATION_PAGE);
    }

    return isDeviceRegistered;
  }
}
