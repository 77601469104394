export enum BarcodeReaderEventType {
  PRODUCT = 'product',
  PATIENT = 'patient',
  GIFT_CARD = 'giftCard',
  RFID = 'RFID'
}

export interface BarcodeResult {
  barcode?: string;
  rfidOutput?: string;
  type: BarcodeReaderEventType;
}
