import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DinerAssignmentModal } from './diner-assignment.modal';
import { IonicModule } from '@ionic/angular';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { AvatarControlModule } from 'src/app/components/shared/avatar-control/avatar-control.module';
// eslint-disable-next-line max-len
import { DinerAssignmentSkeletonComponentModule } from 'src/app/components/shared/skeleton/diner-assignment-skeleton/diner-assignment-skeleton/diner-assignment-skeleton-component.module';
import { InputFocusDirectiveModule } from 'src/app/directives/input-focus-directive/input-focus-directive.module';
import { BlurButtonDirectiveModule } from 'src/app/directives/blur-button/blur-button-directive.module';

@NgModule({
  declarations: [DinerAssignmentModal],
  imports: [
    CommonModule,
    IonicModule,
    DebounceClickDirectiveModule,
    AvatarControlModule,
    DinerAssignmentSkeletonComponentModule,
    InputFocusDirectiveModule,
    BlurButtonDirectiveModule
  ],
  exports: [DinerAssignmentModal]
})
export class DinerAssignmentModalModule {}
