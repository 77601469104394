import { BaseDiner } from './diner.model';
export interface PendingSeatAssignmentResponse {
  id: number;
  diner_id: number;
  guest: boolean;
  first_name: string;
  last_name: string;
  created_at: Date;
  table_id: number;
  table_name: string;
}

export interface HostMostConfig {
  borderColor: string;
}

export interface HostModeGuest {
  tableId: number;
  isGuest: true;
}

export type HostModeDiner = HostModeGuest | BaseDiner;

export enum PendingAssignmentStatusChangeType {
  CREATED = 'created',
  SEATED = 'seated',
  DELETED = 'deleted'
}
