import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderTypeRadioGroupComponent } from './order-type-radio-group.component';

@NgModule({
  declarations: [OrderTypeRadioGroupComponent],
  imports: [CommonModule],
  exports: [OrderTypeRadioGroupComponent]
})
export class OrderTypeRadioGroupComponentModule {}
