import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GiftCardPaymentModal } from './gift-card.modal';
import { IonicModule } from '@ionic/angular';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { FormsModule } from '@angular/forms';
import { InputFocusDirectiveModule } from 'src/app/directives/input-focus-directive/input-focus-directive.module';
import { BlurButtonDirectiveModule } from 'src/app/directives/blur-button/blur-button-directive.module';

@NgModule({
  declarations: [GiftCardPaymentModal],
  imports: [
    CommonModule,
    IonicModule,
    DebounceClickDirectiveModule,
    FormsModule,
    InputFocusDirectiveModule,
    BlurButtonDirectiveModule
  ],
  exports: [GiftCardPaymentModal]
})
export class GiftCardPaymentModalModule {}
