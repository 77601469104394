import { Ticket, TicketStatus } from './ticket.model';
import { BaseData, MealTime, Operator } from './base.model';
import { PosTableStatus } from 'src/app/models';

export type PosPendingSeatAssignment =
  | DinerPendingAssignment
  | GuestPendingAssignment;

export enum TableShape {
  Square = 'square',
  Circle = 'circle',
  Rectangle = 'rectangle'
}

export enum FloorplanStatus {
  PendingItems = 'pending_items',
  AllFired = 'all_fired',
  EmptyTicket = 'empty_ticket'
}

export interface ResponseTable {
  assigned_staff_id: number;
  assigned_staff_name: string;
  id: number;
  location: string;
  max_number_of_seats: number;
  name: string;
  seats: Seat[];
  sort: number;
  tableshape: TableShape;
  pending_seat_assignments: PosPendingSeatAssignment[];
  reservations?: FloorPlanTableReservation[];
}

export interface PendingAssignment extends BaseData {
  guest: boolean;
  table_id: number;
  table_name: string;
  created_at: Date;
  diner_id: number;
  live_location: string;
  first_name?: string;
  last_name?: string;
  status_name?: string;
  status_class?: string;
}

export interface SeatedDiner {
  diner_id: number;
  name: string;
  table_name: string;
  table_id: number;
  last_name: string;
  first_name: string;
  floorplan_status: FloorplanStatus;
  created_at: Date;
}

export enum HostModeStatus {
  AVAILABLE = 'Available',
  ASSIGNED = 'Assigned',
  OCCUPIED = 'Occupied',
  RESERVED = 'Reserved',
  SELECTED = 'Selected'
}

export interface TableLocalAttributes {
  status_name: string;
  status_class: string;
  time_seated: string;
  hostModeStatus?: HostModeStatus;
}

export interface Table extends BaseData {
  sort: number;
  tableshape: TableShape;
  location: string;
  assigned_staff_id: number;
  assigned_staff_name: string;
  seats: Seat[];
  pending_seat_assignments: PendingAssignment[];
  /** Category/Shape of table ('TableRect' or 'TableCircle') */
  category?: string;
  occupied?: boolean;
  max_number_of_seats?: number;
  staff?: Operator;
  isSelected?: boolean;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;

  status?: PosTableStatus;
  tableStatusName?: string;
  tableSeatedTime?: string;
  local_attributes?: TableLocalAttributes;
  totalAssignedSeatNumber?: number;
  reservations?: (TableReservation | FloorPlanTableReservation)[];
}

export interface DinerPendingAssignment {
  diner: {
    live_location: string;
    name: string;
    meal_suite_id: number;
  };
  diner_id: number;
  id: number;
  guest: false;
}

export interface GuestPendingAssignment {
  id: number;
  guest: true;
  diner_id: null;
}

export type TableDetailPendingSeatAssignment =
  | DinerPendingAssignment
  | GuestPendingAssignment;

export interface TableDetailSeat {
  id: number;
  is_temporary_seat: boolean;
  number: number;
  ticket?: Ticket;
}

export interface TableDetailResponse extends BaseData {
  pending_seat_assignments: PosPendingSeatAssignment[];
  seats: TableDetailSeat[];
}

export interface Seat {
  id: number;
  number?: number;
  diner_id?: number;
  diner_name?: string;
  last_name?: string;
  first_name?: string;
  align?: string;
  focus?: string;
  is_temporary_seat?: boolean;
  floorplan_status?: FloorplanStatus;
  ticket?: Ticket;
  device_ticket_uuid?: string;
  // ticket_id?: number;
  ticket_locked?: boolean;
  ticket_locked_by_operator_id?: number;
  ticket_status?: TicketStatus;
  ticket_created_at?: string;
  staff?: Operator;
  isSelected?: boolean;
  local_attributes?: {
    isReserved?: boolean;
    isTableSelected?: boolean;
  };
  tableId?: number;
}

export interface SeatEditorData {
  top: number;
  left?: number;
  right?: number;
  bottom?: number;
  seats?: Seat[];
}

export interface TableUpdateData {
  location?: string;
  loc?: string;
  seat_attributes?: SeatAttributes;
}

export interface SeatAttributes {
  id?: number;
  align?: string;
  focus?: string;
  location?: string;
  table_id?: number;
}

export interface ReservationTable {
  id: number;
  table_id: number;
  table_name: string;
  additional_temp_seats: number | null;
  local_attrs?: {
    start_time: string;
    end_time: string;
    date: string;
    reservationId: number;
  };
  table_detail?: Table;
}

export interface ReservationDiner {
  id: number;
  diner_id: number | null;
  diner_first_name: string | null;
  diner_last_name: string | null;
  diner_name: string | null;
  owner: boolean;
  live_location?: string;
}

export enum ReservationType {
  SEATED_RESERVATION = 'seated_reservation',
  DINE_IN = 'dine_in'
}

export enum ReservationStatus {
  AWAITING_ASSIGNMENT = 'awaiting_assignment'
}

export interface FloorPlanTableReservation {
  id: number;
  date: string;
  start_time: string;
  end_time: string;
  party_size: number;
  meal_id: MealTime;
  reservation_type: ReservationType;
  time_to_block_table_before_reservation: number;
  reservation_diners: ReservationDiner[];
}

export interface TableReservation {
  id: number;
  date: string;
  start_time: string;
  end_time: string;
  party_size: number;
  meal_id: MealTime;
  booked_in_portal: boolean;
  reservation_type: ReservationType;
  status: ReservationStatus;
  notes: string;
  facility_id: number;
  pos_location_id: number;
  pos_operator_id: number;
  reservation_diners: ReservationDiner[];
  reservation_tables?: ReservationTable[];
}

export interface TableReservationDTO {
  date: string;
  start_time: string;
  end_time: string;
  party_size: number;
  meal_id: MealTime;
  booked_in_portal: boolean;
  reservation_type: ReservationType;
  status: ReservationStatus;
  notes: string;
  pos_location_id: number;
  pos_operator_id: number;
  facility_id: number;
  reservations_tables_attributes: {
    table_id: number;
    additional_temp_seats: number;
  }[];
  reservations_diners_attributes: {
    diner_id: number;
    owner: boolean;
  }[];
}

export enum TableListingStatus {
  Available = 'Available',
  NoOrdersTaken = 'NoOrdersTaken',
  Pending = 'Pending',
  Fired = 'Fired',
  Reserved = 'Reserved'
}

export enum FloorPlanTableStatusText {
  Reserved = 'Reserved',
  Available = 'Available',
  Selected = 'Selected',
  Occupied = 'Occupied'
}

export enum TableStatusName {
  Available = 'Available',
  NoOrdersTaken = 'No orders taken',
  PendingCourse = 'Pending course',
  PendingItem = 'Pending item',
  AllCoursesFired = 'All courses fired',
  AllItemsFired = 'All items fired',
  Reserved = 'Reserved'
}

export enum ReservationViewFocus {
  TIME_SLOT = 'time-slot',
  LIST = 'list'
}

export type PosTable = Table | TableDetailResponse;
