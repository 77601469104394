import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MealRadioGroupComponent } from './meal-radio-group.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [MealRadioGroupComponent],
  imports: [CommonModule, FontAwesomeModule],
  exports: [MealRadioGroupComponent]
})
export class MealRadioGroupComponentModule {}
