import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';

import { TableConstant } from 'src/app/constants';
import { SeatEditorData, Table } from 'src/app/models';
import { BaseProvider } from 'src/app/providers';
import { SeatMapProvider } from 'src/app/providers/seat-map.provider';
import { UpdateSelectedTableEditorData } from 'src/app/store/tables/tables.action';

@Component({
  selector: 'pos-edit-table',
  templateUrl: './edit-table.modal.html',
  styleUrls: ['./edit-table.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTableModal implements OnInit {
  @Input() table: Table;

  editorData: SeatEditorData = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };

  readonly TABLE_SHAPE = TableConstant.TABLE_SHAPE;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly baseProvider: BaseProvider,
    private readonly seatMapProvider: SeatMapProvider,
    private readonly store: Store
  ) {}

  ngOnInit() {
    this.editorData = {
      top: this.table.top,
      right: this.table.right,
      bottom: this.table.bottom,
      left: this.table.left
    };
  }

  onCloseModal() {
    this.dismiss();
  }

  saveTable() {
    if (!this.isEqualMaxSeats()) {
      this.baseProvider.popupAlert(
        'Unable to save table',
        'Seats on each side of table must equal Number of Seats.'
      );
      return;
    }
    this.dismiss(true);
  }

  private dismiss(save = false) {
    if (save && this.table) {
      if (this.table.seats) {
        this.table.seats = this.seatMapProvider.parseSeatEditorData(
          this.table.seats,
          this.editorData,
          this.table.tableshape === this.TABLE_SHAPE.CIRCLE
        );
      }

      this.store.dispatch(
        new UpdateSelectedTableEditorData({ ...this.table, ...this.editorData })
      );
    }
    this.modalCtrl.dismiss();
  }

  isEqualMaxSeats() {
    return (
      this.editorData.top +
        this.editorData.right +
        this.editorData.left +
        this.editorData.bottom ===
      this.table.max_number_of_seats
    );
  }
}
