import {
  HostModeDiner,
  PendingAssignment,
  Table,
  TableDetailResponse
} from 'src/app/models';

export interface NewPendingAssignmentPayload {
  guest: boolean;
  diner_id: number;
  name: string;
}

export class HostModeSelectTable {
  static readonly type = '[HostMode] Select Table';
  constructor(public readonly id: number, public readonly fromDiagram = true) {}
}

export class HostModeDeselectTable {
  static readonly type = '[HostMode] Deselect Table';
  constructor(public fromDiagram = false, public tableId?: number) {}
}

export class AddToPendingDiners {
  static readonly type = '[HostMode] Add To Pending Diners';
  constructor(public diner: HostModeDiner) {}
}

export class RemovePendingDiner {
  static readonly type = '[HostMode] Remove Pending Diner';
  constructor(public index: number) {}
}

export class NewSeatAssignments {
  static readonly type = '[HostMode] New Seat Assignments';
  constructor(
    public readonly diners: NewPendingAssignmentPayload[],
    public readonly tables: Table[]
  ) {}
}

export class NewSeatAssignmentsSuccess {
  static readonly type = '[HostMode] New Seat Assignments Success';
  constructor(public pendingSeatAssignments: PendingAssignment[]) {}
}

export class RemoveAllPendingDiners {
  static readonly type = '[HostMode] Remove All Pending Diners';
}

export class GetPendingAssignments {
  static readonly type = '[HostMode] Get Pending Assignments';
}

export class DeletePendingAssignment {
  static readonly type = '[HostMode] Delete Pending Assignment';
  constructor(public readonly id: number, public readonly fromWS = false) {}
}

export class PendingAssignmentDeletedWsEvent {
  static readonly type = '[HostMode] Pending Assignment Deleted Ws Event';
  constructor(public readonly id: number, public readonly table_id: number) {}
}

export class ClosePendingSeatAssignment {
  static readonly type = '[HostMode] Close Pending Seat Assignment';
  constructor(public id: number, public dinerId?: number) {}
}

export class PendingSeatCreatedWsEvent {
  static readonly type = '[HostMode] Pending Seat Created Ws Event';
  constructor(public readonly tableId: number) {}
}

export class PendingSeatCreatedWsEventSuccess {
  static readonly type = '[HostMode] Pending Seat Created Ws Event Success';
  constructor(public readonly table: TableDetailResponse) {}
}
