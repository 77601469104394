import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, map } from 'rxjs';

import { PosLocation, LocationList } from 'src/app/models';
import { ApiType } from 'src/app/pos.config';
import { ErrorService } from 'src/app/services/error/error.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(
    private http: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  async getLocations(): Promise<LocationList[]> {
    const { pos_locations } = await lastValueFrom(
      this.http
        .get<{ pos_locations: LocationList[] }>('locations')
        .pipe(this.errorService.retryPipe(ApiType.Query))
    );
    return pos_locations;
  }

  getLocationById(locationId: number): Observable<PosLocation> {
    return this.http
      .get<{ pos_location: PosLocation }>(`locations/${locationId}`)
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((res) => res.pos_location)
      );
  }

  getLocation(locationId: number): Observable<PosLocation> {
    return this.http
      .get<{ pos_location: PosLocation }>(`locations/${locationId}`)
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((res) => res.pos_location)
      );
  }
}
