import { produce } from 'immer';
import { switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import _ from 'lodash';

import {
  BaseDiner,
  DinerType,
  LocationType,
  NormalizedDiner,
  NormalizedObject
} from 'src/app/models';
import { DinerService } from 'src/app/services';
import { GetMenuTally } from '../menu/menu.action';
import {
  AddDinerToTicket,
  AddDinerToTicketSuccess,
  AddTicketMealPlan
} from '../tickets/tickets.action';
import {
  AssignMealPlan,
  ClearSearchDinersResult,
  GetAssignedResidentsSuccess,
  GetDinerByBarcode,
  GetDinerByBarcodeFailed,
  GetDinerDetail,
  GetDinerDetailSuccess,
  GetDiners,
  GetSearchingDinersSuccess,
  MultipleDinersFound,
  SelectDiner,
  ShowMealPlanPrompt,
  UpdateChargeAccountBalance
} from './diners.action';
import { NormalizrHelper } from 'src/app/helpers';
import {
  LogRocketProvider,
  LOG_ROCKET_CUSTOM_EVENTS
} from 'src/app/providers/logrocket.provider';
import { AddToPendingDiners } from '../host-mode/host-mode.action';
import { TICKETS_STATE_TOKEN } from 'src/app/store/tickets/tickets.state';
import { AddReservationDiner } from '../reservation-control/reservation-control.action';
import { UpdateSelectedMealId } from 'src/app/store/location/location.action';
import { SelectNewSeat } from 'src/app/store/seats/seats.action';
import { SEATS_STATE_TOKEN } from 'src/app/store/seats/seats.state';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';
import { LocationStateHelper } from 'src/app/store/location/location.state.helper';

export interface DinersStateModel {
  diners: NormalizedObject<NormalizedDiner, number>;
  filteredDiners: BaseDiner[];
  assignedResidents: BaseDiner[];
}

export const DINERS_STATE_TOKEN = new StateToken<DinersStateModel>('diners');

@State({
  name: DINERS_STATE_TOKEN,
  defaults: {
    diners: {
      byId: null,
      allIds: []
    },
    filteredDiners: [],
    assignedResidents: []
  }
})
@Injectable()
export class DinersState {
  constructor(
    private dinerService: DinerService,
    private store: Store,
    private logRocketProvider: LogRocketProvider
  ) {}

  @Action(SelectDiner)
  selectDiner(
    ctx: StateContext<DinersStateModel>,
    { ticket, addMealPlan, getDinerDetail, forceApplyMealPlan }: SelectDiner
  ) {
    const dinerId = ticket?.diner_id;
    this.logRocketProvider.track(LOG_ROCKET_CUSTOM_EVENTS.Diner.Assigned, {
      device_ticket_uuid: ticket?.device_ticket_uuid,
      diner_name: ticket?.diner_name,
      diner_id: ticket?.diner_id
    });
    if (dinerId) {
      const state = ctx.getState();
      if (state.diners.allIds.indexOf(dinerId) === -1 || getDinerDetail) {
        ctx.dispatch(
          new GetDinerDetail(ticket, addMealPlan, forceApplyMealPlan)
        );
      } else if (addMealPlan) {
        ctx.dispatch(
          new AssignMealPlan(
            state.diners.byId[dinerId],
            ticket.device_ticket_uuid,
            forceApplyMealPlan
          )
        );
      }
    }
  }

  @Action(GetDiners, { cancelUncompleted: true })
  getDiners(
    { dispatch }: StateContext<DinersStateModel>,
    { name, patients_only, visitingFacilityId }: GetDiners
  ) {
    return this.dinerService
      .getDiners(name, patients_only, visitingFacilityId)
      .pipe(
        switchMap((diners) => dispatch(new GetSearchingDinersSuccess(diners)))
      );
  }

  @Action(GetSearchingDinersSuccess)
  getSearchingDinersSuccess(
    { setState }: StateContext<DinersStateModel>,
    { diners }: GetSearchingDinersSuccess
  ) {
    setState(
      produce((draft: DinersStateModel) => {
        draft.filteredDiners = diners;
      })
    );
  }

  @Action(GetDinerByBarcode)
  getDinerByBarcode(
    ctx: StateContext<DinersStateModel>,
    { barcode, searchType }: GetDinerByBarcode
  ) {
    return this.dinerService.getDinerByBarcode(barcode).pipe(
      switchMap((diners) => {
        // If no diners are found, dispatch an error
        if (!diners || diners.length === 0) {
          return ctx.dispatch(new GetDinerByBarcodeFailed(barcode));
        }
        // If only one diner is found
        if (diners.length === 1) {
          // If the search is for host mode, dispatch an action to add it to the pending diners
          if (searchType === 'hostMode') {
            return ctx.dispatch(new AddToPendingDiners(diners[0]));
          } else if (searchType === 'ticketAssignment') {
            return ctx.dispatch(new AddDinerToTicket(diners[0]));
          } else if (searchType === 'reservation') {
            return ctx.dispatch(new AddReservationDiner(diners[0]));
          }
        }

        // If more than one diner is found, dispatch an action to show the list of diners
        return ctx.dispatch(new MultipleDinersFound(diners, barcode));
      })
    );
  }

  // Retrieves the diner detail after assigning it to a Ticket or selecting an existing ticket with diner
  // After updating the local store, if the diner has a valid meal plan it will add it or ask if it needs to be added based on the location configuration
  @Action(GetDinerDetail)
  getDinerDetail(
    ctx: StateContext<DinersStateModel>,
    { ticket, addMealPlan, forceApplyMealPlan }: GetDinerDetail
  ) {
    const dinerId = ticket?.diner_id;
    return this.dinerService.getDinerDetail(dinerId).pipe(
      tap((diner) => {
        ctx.dispatch(new GetDinerDetailSuccess(diner));
        const normalizeDiners = NormalizrHelper.normalizeDiners([diner])
          .entities.diners;
        ctx.setState(
          produce((draft: DinersStateModel) => {
            if (normalizeDiners) {
              draft.diners = {
                byId: { ...draft.diners.byId, ...normalizeDiners },
                allIds: _.union(
                  draft.diners.allIds,
                  Object.keys(normalizeDiners).map((key) => +key)
                )
              };
            }
          })
        );

        const location =
          this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
        const locationType = location.type;
        const isQuickService: boolean =
          locationType === LocationType.QuickService;

        const isSelectMenuTally =
          LocationStateHelper.getIsPersonalizedResidentMenus(location) &&
          diner.id &&
          diner.type === DinerType.Resident;

        if (isSelectMenuTally) {
          const ticketsState = this.store.selectSnapshot(TICKETS_STATE_TOKEN);
          const selectedTicketId = ticketsState.selectedTicket.data;
          const ticketsById = ticketsState.tickets.byId;

          const createdTicket =
            !selectedTicketId || !ticketsById
              ? null
              : ticketsById[selectedTicketId];

          if (ticket) {
            this.store.dispatch(
              new GetMenuTally(diner.id, createdTicket.meal_id)
            );
          }
        }

        if (isQuickService) {
          ctx.dispatch(
            new AddDinerToTicketSuccess(diner, false, false, forceApplyMealPlan)
          );
        }

        if (addMealPlan) {
          ctx.dispatch(
            new AssignMealPlan(
              diner,
              ticket.device_ticket_uuid,
              forceApplyMealPlan
            )
          );
        }
      })
    );
  }

  @Action(AssignMealPlan)
  assignMealPlan(
    ctx: StateContext<DinersStateModel>,
    { diner, device_ticket_uuid, forceApplyMealPlan }: AssignMealPlan
  ) {
    if (
      device_ticket_uuid &&
      diner.meal_plan &&
      (diner.meal_plan.tender_type === 'Unlimited' ||
        diner.meal_plan.balance > 0)
    ) {
      this.logRocketProvider.track(
        LOG_ROCKET_CUSTOM_EVENTS.Diner.OptForMealPlan,
        {
          device_ticket_uuid,
          mealsuite_id: diner.mealsuite_id,
          diner_id: diner.id
        }
      );
      const location = this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
      const locationType = location.type;
      const isSelfService: boolean = locationType === LocationType.SelfServe;
      const autoChooseMealPlan = isSelfService
        ? false
        : location.auto_choose_mealplan_when_available;
      let action = new ShowMealPlanPrompt();
      if (autoChooseMealPlan || forceApplyMealPlan) {
        action = new AddTicketMealPlan(
          diner.meal_plan.id,
          diner.meal_plan.tender_type,
          device_ticket_uuid
        );
      }
      return ctx.dispatch(action);
    }
  }

  @Action(GetAssignedResidentsSuccess)
  getAssignedResidentsSuccess(
    ctx: StateContext<DinersStateModel>,
    { residents }: GetAssignedResidentsSuccess
  ) {
    ctx.patchState({ assignedResidents: residents });
  }

  @Action(ClearSearchDinersResult)
  clearSearchDinersResult(ctx: StateContext<DinersStateModel>) {
    ctx.patchState({ filteredDiners: [] });
  }

  @Action(UpdateChargeAccountBalance)
  updateChargeAccountBalance(
    ctx: StateContext<DinersStateModel>,
    { dinerId, transaction }: UpdateChargeAccountBalance
  ) {
    ctx.setState(
      produce((draft) => {
        const diner = draft.diners.byId[dinerId];
        const chargeAccount = diner?.charge_accounts.find(
          (i) => i.id === transaction.pos_charge_account_id
        );
        if (chargeAccount) {
          const newValue = chargeAccount.available_credit - transaction.amount;
          chargeAccount.available_credit = newValue > 0 ? newValue : 0;
        }
      })
    );
  }

  //Get new tally from dropdown selector
  @Action(UpdateSelectedMealId)
  getNewMenuTallyOnMealChange(
    ctx: StateContext<DinersStateModel>,
    { meal_id }: UpdateSelectedMealId
  ) {
    const state = ctx.getState();
    const ticketState = this.store.selectSnapshot(TICKETS_STATE_TOKEN);
    const selectedTicket =
      ticketState.tickets.byId &&
      ticketState.tickets.byId[ticketState.selectedTicket.data];
    const selectedDinerId: number = selectedTicket?.diner_id;
    const location = this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
    const isPersonalizedResidentMenus =
      LocationStateHelper.getIsPersonalizedResidentMenus(location);

    if (!isPersonalizedResidentMenus || !selectedDinerId) {
      return;
    }

    const diner = state.diners.byId && state.diners.byId[selectedDinerId];

    if (!diner || (diner.id && diner.type !== DinerType.Resident)) {
      return;
    }

    return ctx.dispatch(new GetMenuTally(diner.id, meal_id));
  }

  @Action(SelectNewSeat)
  addDinerToNewSeat(
    ctx: StateContext<DinersStateModel>,
    payload: SelectNewSeat
  ) {
    const selectedSeat =
      this.store.selectSnapshot(SEATS_STATE_TOKEN).seats.byId[payload.seatId];
    const device_ticket_uuid = selectedSeat.device_ticket_uuid;

    if (!device_ticket_uuid) {
      return;
    }

    const { selectedTicket, tickets } =
      this.store.selectSnapshot(TICKETS_STATE_TOKEN);
    const ticket = tickets.byId[device_ticket_uuid];
    const dinerId = ticket?.diner_id;
    if (!dinerId) {
      return;
    }

    const dinerState = ctx.getState();
    const isDinerLoaded = dinerState.diners.allIds.includes(dinerId);
    const selectedDinerId: number =
      tickets.byId &&
      selectedTicket?.data &&
      tickets.byId[selectedTicket.data].diner_id;

    if (isDinerLoaded && selectedDinerId === dinerId) {
      return;
    }
    const selectedDiner =
      dinerState.diners.byId && dinerState.diners.byId[dinerId];

    if (!selectedDiner) {
      return this.dinerService.getDinerDetail(dinerId).pipe(
        tap((diner) => {
          ctx.setState(
            produce((draft: DinersStateModel) => {
              const dinerEntities = NormalizrHelper.normalizeDiners([diner])
                .entities.diners;
              draft.diners = {
                byId: {
                  ...draft.diners.byId,
                  ...dinerEntities
                },
                allIds: _.union(draft.diners.allIds, [dinerId])
              };
            })
          );
          const location =
            this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
          const isPersonalizedResidentMenus =
            LocationStateHelper.getIsPersonalizedResidentMenus(location);

          if (
            isPersonalizedResidentMenus &&
            diner.type === DinerType.Resident
          ) {
            ctx.dispatch(new GetMenuTally(diner.id, ticket.meal_id));
          }
        })
      );
    }
  }
}
