import { IExternalScreen } from 'src/app/models/hardware/external-screen.model';

export class EloExternalScreen implements IExternalScreen {
  static session: any = null;
  sessionUrl =
    'file:///android_asset/public/second-screen/pos-default-display/index.html#HDMI%20Screen';
  isScreenStarted = false;
  logEnabled = false;
  displayAvailable = false;
  screenDetectionOn = false;
  messageQueue: Array<any> = [];

  detectScreen() {
    const presentation = navigator['presentation'];

    presentation.onavailablechange = (availableChangeEvent) => {
      if (availableChangeEvent.available) {
        this.displayAvailable = true;
        console.log('External display is available.');
        EloExternalScreen.session = navigator['presentation'].requestSession(
          this.sessionUrl
        );
      } else {
        this.displayAvailable = false;

        console.log('External display not available.');
      }
    };
    this.screenDetectionOn = true;
  }

  isSessionStarted() {
    return (
      EloExternalScreen.session &&
      EloExternalScreen.session.state === 'connected'
    );
  }

  isPluginAvailable(): boolean {
    return (
      navigator['presentation'] && !!navigator['presentation'].requestSession
    );
  }

  startSession(): void {
    if (this.isPluginAvailable()) {
      if (!this.screenDetectionOn) {
        this.detectScreen();
      }
    }
  }

  postMessage(message: any): void {
    const postCurrentMessage = (value: any) => {
      if (this.logEnabled) {
        console.log('External Screen -> posting data to screen-> data', value);
      }
      value = typeof value !== 'string' ? JSON.stringify(value) : value;
      value = value.replace(/\'/g, '&apos;');
      EloExternalScreen.session.postMessage(value);
    };

    if (EloExternalScreen.session && this.messageQueue.length < 1 && message) {
      // if session is active and queue is empty skip the queue
      postCurrentMessage(message);
    } else {
      if (this.isPluginAvailable()) {
        if (this.logEnabled) {
          console.log(
            'External Screen -> posting data to screen-> message saved to queue',
            message
          );
        }

        if (message) {
          this.messageQueue.push(message);
        }
        this.startSession();
        setTimeout(
          (_message, that) => {
            if (that.isSessionStarted()) {
              // post data to screen
              that.messageQueue.forEach((value) => {
                postCurrentMessage(value);
              });
            }
            that.messageQueue = [];
          },
          2000,
          message,
          this
        );
      }
    }
  }

  startWelcomeScreen(): void {
    if (this.logEnabled) {
      console.log('External Screen -> start welcome screen');
    }
    if (!this.isSessionStarted()) {
      this.startSession();
    }
  }

  showMessage(message: any): void {
    this.postMessage({
      action: 'DisplayMessage',
      message
    });
  }

  displayHome(): void {
    this.postMessage({
      action: 'DisplayHome'
    });
  }

  initializeOrder(orderDetails: any): void {
    if (!this.isSessionStarted()) {
      this.startWelcomeScreen();
    }

    this.displayOrderDetails(orderDetails);
  }

  updateOrderData(data: any, action: any) {
    switch (action) {
      case 'DisplayOrderDetails': {
        this.postMessage({
          action: 'DisplayOrderDetails',
          orderDetails: data
        });
        break;
      }
      case 'DisplayPaymentDetails': {
        this.postMessage({
          action: 'DisplayPaymentDetails',
          paymentDetails: data
        });
        break;
      }
    }
  }

  displayOrderDetails(orderDetails: any): void {
    if (!this.isSessionStarted()) {
      this.startWelcomeScreen();
    }
    this.updateOrderData(orderDetails, 'DisplayOrderDetails');
  }

  displayPaymentDetails(paymentDetails: any): void {
    if (!this.isSessionStarted()) {
      this.startWelcomeScreen();
    }

    this.updateOrderData(paymentDetails, 'DisplayPaymentDetails');
  }

  updateGraphics(graphicsUrl: string) {
    this.postMessage({
      action: 'UpdateGraphics',
      graphicsUrl
    });
  }

  turnOffBacklight(): void {}
  turnOnBacklight() {}
}
