import {
  BaseDiner,
  ReservationTable,
  ReservationTimeSlot
} from 'src/app/models';
import {
  ReservationControlMode,
  ReservationControlView
} from './reservation-control.state';

export class ChangeReservationControlMode {
  static readonly type =
    '[Reservation Control] Change Reservation Control Mode';
  constructor(public readonly mode: ReservationControlMode) {}
}

export class ChangeReservationControlView {
  static readonly type =
    '[Reservation Control] Change Reservation Control View';
  constructor(public readonly view: ReservationControlView) {}
}

export class ReservationControlSearchDiner {
  static readonly type =
    '[Reservation Control] Reservation Control Search Diner';
  constructor(public readonly searchTerm: string) {}
}

export class ReservationControlDinerChanged {
  static readonly type =
    '[Reservation Control] Reservation Control Diner Changed';
  constructor(public readonly changed: boolean) {}
}

export class ReservationControlFormChanged {
  static readonly type =
    '[Reservation Control] Reservation Control Form Changed';
  constructor(public readonly changed: boolean) {}
}

export class ReservationControlFormValidCheck {
  static readonly type =
    '[Reservation Control] Reservation Control Form Valid Check';
  constructor(public readonly valid: boolean) {}
}

export class TriggerGoBackFromDinerAssignView {
  static readonly type =
    '[Reservation Control] Trigger Go Back From Diner Assign View';
}

export class SetSelectedReservationTables {
  static readonly type =
    '[Reservation Control] Set Selected Reservation Tables';
  constructor(public readonly tables: ReservationTable[]) {}
}

export class AddSelectedReservationTable {
  static readonly type = '[Reservation Control] Add Selected Reservation Table';
  constructor(public readonly table: ReservationTable) {}
}

export class RemoveSelectedReservationTable {
  static readonly type =
    '[Reservation Control] Remove Selected Reservation Table';
  constructor(public readonly table: ReservationTable) {}
}

export class UpdateSelectedTableTempSeats {
  static readonly type =
    '[Reservation Control] Update Selected Reservation Table Temp Seats';
  constructor(
    public readonly table: ReservationTable,
    public readonly tempSeats: number
  ) {}
}

export class SubmitReservationControl {
  static readonly type = '[Reservation Control] Submit Reservation Control';
}

export class SetSelectedTimeSlot {
  static readonly type = '[Reservation Control] Set Selected Time Slot';
  constructor(public readonly timeSlot: ReservationTimeSlot) {}
}

export class AddReservationDiner {
  static readonly type = '[Reservation Control] Add Reservation Diner';
  constructor(public readonly diner: BaseDiner) {}
}
