import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { produce } from 'immer';
import _ from 'lodash';

import { PosManagerServiceState } from './pos-services-manager/pos-services.state';
import { PosScheduleState } from './pos-schedules/pos-schedules.state';
import { NormalizrHelper } from 'src/app/helpers';
import {
  LocationType,
  MacroGridView,
  NormalizedModifierPromptOption,
  NormalizedObject
} from 'src/app/models';
import { NormalizedModifierPrompt } from 'src/app/models/normalized-models/pos-manager/normalized-modifier-prompt.model';
import { PosManagerService } from 'src/app/services';
import { ExceptionError, HttpRequestError } from '../error/error.action';
import {
  ClearSelectedModifierPrompt,
  CreateModifierPrompt,
  CreateModifierPromptSuccess,
  DeleteModifierPrompt,
  GetEmbeddedDashboardUrl,
  GetModifierPrompts,
  GetModifierPromptsSuccess,
  GetProducts,
  GetSharedObjects,
  PosManagerBackToPos,
  SelectModifierPrompt,
  SelectModifierPromptError,
  UpdateModifierPrompt,
  UpdateModifierPromptSuccess
} from './pos-manager.action';
import {
  ModifierPromptOption,
  ModifierPromptOptionCreateRequest,
  ModifierPromptOptionEditRequest,
  ModifierPromptRequest,
  PosManagerSharedObjects,
  PosQuantityRestriction,
  SharedObject
} from 'src/app/models/pos-manager.model';
import { PageConstant } from 'src/app/constants';
import { UpdateMacroGridView } from '../menu/menu.action';
import { ExitTakeOutDelivery } from '../takeout-delivery/takeout-delivery.action';
import { TempLogout } from '../app/app.action';
import { TAKEOUT_DELIVERY_STATE_TOKEN } from 'src/app/store/takeout-delivery/takeout-delivery.state';
import { TICKETS_STATE_TOKEN } from 'src/app/store/tickets/tickets.state';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';

export interface ModifierPromptsData {
  loaded: boolean;
  items: NormalizedObject<NormalizedModifierPrompt, number>;
  modifierPromptOptions: NormalizedObject<
    NormalizedModifierPromptOption,
    number
  >;
  selectedModifierPromptId: number;
  products: SharedObject[];
  quantityRestrictions: PosQuantityRestriction[];
}

export interface PosManagerStateModel {
  modifierPrompts: ModifierPromptsData;
  sharedObjects: PosManagerSharedObjects;
  embeddedDashboardUrl: string;
}

export const POS_MANAGER_STATE_TOKEN = new StateToken<PosManagerStateModel>(
  'posManager'
);

@State({
  name: POS_MANAGER_STATE_TOKEN,
  defaults: {
    modifierPrompts: {
      loaded: false,
      items: {
        byId: null,
        allIds: []
      },
      modifierPromptOptions: {
        byId: null,
        allIds: []
      },
      selectedModifierPromptId: null,
      products: null,
      quantityRestrictions: null
    },
    sharedObjects: null,
    embeddedDashboardUrl: null
  },
  children: [PosManagerServiceState, PosScheduleState]
})
@Injectable()
export class PosManagerState {
  constructor(
    private readonly posManagerService: PosManagerService,
    private readonly navController: NavController,
    private readonly store: Store
  ) {}

  @Action(GetSharedObjects)
  getSharedObjects(ctx: StateContext<PosManagerStateModel>) {
    return this.posManagerService.getSharedObjects().pipe(
      tap((sharedObjects) => ctx.patchState({ sharedObjects })),
      catchError(() =>
        ctx.dispatch(
          new HttpRequestError(
            'There was an error while fetching POS Manager Shared Objects. Please exit POS Manager and re-enter. If the problem persists, please call support.'
          )
        )
      )
    );
  }

  @Action(GetEmbeddedDashboardUrl)
  getEmbeddedDashboardUrl(ctx: StateContext<PosManagerStateModel>) {
    return this.posManagerService.getEmbeddedDashboardUrl().pipe(
      tap((url) => ctx.patchState({ embeddedDashboardUrl: url })),
      catchError(() =>
        ctx.dispatch(
          new HttpRequestError(
            'There was an error while fetching Embedded Dashboard URL. Please try again or contact support.'
          )
        )
      )
    );
  }

  @Action(GetModifierPrompts)
  getModifierPrompts(ctx: StateContext<PosManagerStateModel>) {
    const state = ctx.getState();

    //Set back to not loaded if loading again
    if (state.modifierPrompts.loaded) {
      ctx.setState(
        produce((draft: PosManagerStateModel) => {
          //Clear all items when re-downloading
          draft.modifierPrompts.items.byId = null;
          draft.modifierPrompts.items.allIds = [];
          draft.modifierPrompts.modifierPromptOptions.allIds = [];
          draft.modifierPrompts.modifierPromptOptions.byId = null;
          draft.modifierPrompts.loaded = false;
        })
      );
    }

    return this.posManagerService.getModifierPrompts().pipe(
      switchMap((modifierPrompts) =>
        ctx.dispatch(new GetModifierPromptsSuccess(modifierPrompts))
      ),
      catchError(() =>
        ctx.dispatch(
          new HttpRequestError(
            'There was an error while fetching Modifier Prompts. Please try again or contact support.'
          )
        )
      ),
      tap(() => {
        ctx.setState(
          produce((draft: PosManagerStateModel) => {
            draft.modifierPrompts.loaded = true;
          })
        );
      })
    );
  }

  @Action(GetModifierPromptsSuccess)
  getModifierPromptsSuccess(
    ctx: StateContext<PosManagerStateModel>,
    { modifierPrompts }: GetModifierPromptsSuccess
  ) {
    const normalizedEntities =
      NormalizrHelper.normalizeModifierPrompts(modifierPrompts).entities;

    if (normalizedEntities && normalizedEntities.modifierPrompts) {
      ctx.setState(
        produce((draft: PosManagerStateModel) => {
          draft.modifierPrompts.items = {
            byId: {
              ...draft.modifierPrompts.items.byId,
              ...normalizedEntities.modifierPrompts
            },
            allIds: _.union(
              draft.modifierPrompts.items.allIds,
              Object.keys(normalizedEntities.modifierPrompts).map((key) => +key)
            )
          };

          if (normalizedEntities.modifierPromptOptions) {
            draft.modifierPrompts.modifierPromptOptions = {
              byId: {
                ...draft.modifierPrompts.modifierPromptOptions.byId,
                ...normalizedEntities.modifierPromptOptions
              },
              allIds: _.union(
                draft.modifierPrompts.modifierPromptOptions.allIds,
                Object.keys(normalizedEntities.modifierPromptOptions).map(
                  (key) => +key
                )
              )
            };
          }
        })
      );
    }
  }

  @Action(SelectModifierPrompt)
  selectModifierPrompt(
    ctx: StateContext<PosManagerStateModel>,
    { id }: SelectModifierPrompt
  ) {
    const state = ctx.getState();

    //Check if ID exists on modifier prompt list
    if (!state.modifierPrompts.items.allIds.includes(id)) {
      ctx.dispatch(new SelectModifierPromptError());
    } else {
      ctx.setState(
        produce((draft: PosManagerStateModel) => {
          draft.modifierPrompts.selectedModifierPromptId = id;
        })
      );
    }
  }

  @Action(ClearSelectedModifierPrompt)
  clearSelectedModifierPrompt(ctx: StateContext<PosManagerStateModel>) {
    ctx.setState(
      produce((draft: PosManagerStateModel) => {
        draft.modifierPrompts.selectedModifierPromptId = null;
      })
    );
  }

  @Action(CreateModifierPrompt)
  createModifierPrompt(
    ctx: StateContext<PosManagerStateModel>,
    { name, modifierPromptOptions }: CreateModifierPrompt
  ) {
    const data: ModifierPromptRequest = {
      question_label: name,
      modifier_prompt_options_attributes:
        this.transformModifierPromptOptionsToRequest(modifierPromptOptions)
    };

    return this.posManagerService.createModifierPrompt(data).pipe(
      tap(() => ctx.dispatch(new CreateModifierPromptSuccess())),
      catchError(() =>
        ctx.dispatch(
          new ExceptionError(
            'There was a problem while creating the modifier prompt.'
          )
        )
      )
    );
  }

  @Action(CreateModifierPromptSuccess)
  createModifierPromptSuccess() {
    this.navController.back();
  }

  @Action(UpdateModifierPrompt)
  updateModifierPrompt(
    ctx: StateContext<PosManagerStateModel>,
    { id, name, modifierPromptOptions }: UpdateModifierPrompt
  ) {
    const data: ModifierPromptRequest = {
      question_label: name,
      modifier_prompt_options_attributes:
        this.transformModifierPromptOptionsToRequest(modifierPromptOptions)
    };

    return this.posManagerService.updateModifierPrompt(id, data).pipe(
      tap(() => ctx.dispatch(new UpdateModifierPromptSuccess())),
      catchError(() =>
        ctx.dispatch(
          new ExceptionError(
            'There was a problem while updating the modifier prompt.'
          )
        )
      )
    );
  }

  @Action(UpdateModifierPromptSuccess)
  updateModifierPromptSuccess(ctx: StateContext<PosManagerStateModel>) {
    ctx.setState(
      produce((draft: PosManagerStateModel) => {
        draft.modifierPrompts.selectedModifierPromptId = null;
        draft.modifierPrompts.products = null;
      })
    );

    this.navController.navigateRoot(
      PageConstant.POS_MANAGER_MODIFIER_PROMPTS_PAGE
    );
  }

  @Action(DeleteModifierPrompt)
  deleteModifierPrompt(
    ctx: StateContext<PosManagerStateModel>,
    { id }: DeleteModifierPrompt
  ) {
    return this.posManagerService.deleteModifierPrompt(id).pipe(
      tap((success) => {
        if (success) {
          ctx.setState(
            produce((draft: PosManagerStateModel) => {
              //Remove modifier prompt from store
              if (_.has(draft.modifierPrompts.items.byId, id)) {
                delete draft.modifierPrompts.items.byId[id];
                _.remove(
                  draft.modifierPrompts.items.allIds,
                  (item) => item === id
                );

                //Unselect deleted modifier prompt
                draft.modifierPrompts.selectedModifierPromptId = null;
              }
            })
          );

          this.navController.navigateRoot(
            PageConstant.POS_MANAGER_MODIFIER_PROMPTS_PAGE
          );
        }
      }),
      catchError(() =>
        ctx.dispatch(
          new ExceptionError(
            `There was an error while deleting modifier prompt id ${id}`
          )
        )
      )
    );
  }

  @Action(SelectModifierPromptError)
  selectModifierPromptError(ctx: StateContext<PosManagerStateModel>) {
    ctx.dispatch(new ExceptionError('Modifier Prompt not found'));
    this.navController.back();
  }

  @Action(GetProducts)
  getProducts(ctx: StateContext<PosManagerStateModel>) {
    return this.posManagerService.getProducts().pipe(
      map(
        (products) =>
          products.map((p) => ({ id: p.id, name: p.name })) as SharedObject[]
      ),
      tap((mappedProducts) => {
        ctx.setState(
          produce((draft: PosManagerStateModel) => {
            draft.modifierPrompts.products = mappedProducts;
          })
        );
      }),
      catchError(() =>
        ctx.dispatch(
          new ExceptionError('There was an error while fetching products')
        )
      )
    );
  }

  @Action(PosManagerBackToPos)
  posManagerBackToPos() {
    let path = PageConstant.QUICK_SERVICE_PAGE;
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isDiningRoom: boolean = locationType === LocationType.DiningRoom;
    const isServiceAppointment: boolean =
      locationType === LocationType.Services;
    const isSelfService: boolean = locationType === LocationType.SelfServe;
    if (isServiceAppointment) {
      path = PageConstant.SERVICES_BOOKING_PAGE;
    }
    if (isDiningRoom) {
      path = PageConstant.DINING_FLOORPLAN_PAGE;
    }
    if (isSelfService) {
      path = PageConstant.SELF_SERVICE_SETUP_PAGE;
      this.store.dispatch(new TempLogout());
    }

    // POSV3-1499 Takeout order - When returning back from POS Manager, I should expect to be on the ticket order screen that I was on before I went to POS Manager.
    const isTakeoutDelivery = this.store.selectSnapshot(
      TAKEOUT_DELIVERY_STATE_TOKEN
    ).enableTakeOutDelivery;

    if (isTakeoutDelivery) {
      const selectedTicketId =
        this.store.selectSnapshot(TICKETS_STATE_TOKEN).selectedTicket.data;

      if (selectedTicketId && isDiningRoom) {
        this.store.dispatch(new UpdateMacroGridView(MacroGridView.MAIN));
        path = `${PageConstant.TAKEOUT_DELIVERY_VIEW_ORDER_PAGE(
          isDiningRoom
        )}/${selectedTicketId}`;
      } else {
        // only disabled the takeout delivery mode
        path = isDiningRoom
          ? PageConstant.TAKEOUT_DELIVERY_PAGE(isDiningRoom)
          : undefined;
        this.store.dispatch(ExitTakeOutDelivery);
      }
    }

    if (path) {
      this.navController.navigateRoot(path, {
        animated: true,
        animationDirection: 'forward'
      });
    }
  }

  //Transform modifier prompt option from local format to server format
  private transformModifierPromptOptionsToRequest(
    modifierPromptOptions: ModifierPromptOption[]
  ): (ModifierPromptOptionCreateRequest | ModifierPromptOptionEditRequest)[] {
    return modifierPromptOptions.map((o) => {
      const tmp:
        | ModifierPromptOptionCreateRequest
        | ModifierPromptOptionEditRequest = {
        option_type: o.option_type,
        sort_order: o.sort_order
      };

      if (o.id) {
        (<ModifierPromptOptionEditRequest>tmp).id = o.id;

        if (o._destroy) {
          (<ModifierPromptOptionEditRequest>tmp)._destroy = true;
        }
      }

      if (o.option_type === 'text') {
        tmp.text_label = o.text_label;
        tmp.added_price = o.added_price;
      } else if (o.option_type === 'category') {
        tmp.category_id = o.category.id;
      } else if (o.option_type === 'pos_product') {
        tmp.pos_product_id = o.pos_product.id;
      }

      return tmp;
    }) as (
      | ModifierPromptOptionCreateRequest
      | ModifierPromptOptionEditRequest
    )[];
  }
}
