import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SskButton } from 'src/app/pages/self-service/modals/ssk-confirm-modal/ssk-confirm.modal';

@Component({
  selector: 'pos-payment-locations-warning-modal',
  templateUrl: './pos-payment-locations-warning-modal.html',
  styleUrls: ['./pos-payment-locations-warning-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentLocationsWarningModal {
  @Input() description?: string;
  @Input() title?: string;
  @Input() yesBtn: SskButton = {
    text: 'Close',
    color: 'danger',
    fill: 'solid'
  };

  constructor(public readonly modalCtrl: ModalController) {}
}
