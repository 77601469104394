<div class="confirm-modal">
  <div class="confirm-modal__wrapper">
    <p class="question">
      {{ header }}
    </p>
    <p class="description" *ngIf="!!subTitle">
      {{ subTitle }}
    </p>
  </div>
  <div class="confirm-modal__buttons">
    <ion-button *ngIf="!redBtnHidden"
                id="ss-confirm-modal__cancel-button"
                color="dark-red"
                class="close-modal-button ion-float-center"
                debounce-click
                [debounceTime]="0"
                (debounceClick)="onClick(false)">{{ redBtnLabel }}
    </ion-button>
    <ion-button id="ss-confirm-modal__yes-button"
                color="dark"
                class="cancel-order-button ion-float-center"
                debounce-click
                [debounceTime]="0"
                (debounceClick)="onClick(true)">
      {{ grayBtnLabel }}
    </ion-button>
  </div>
  <fa-icon [icon]="faTimes" (click)="closeModal()"></fa-icon>
</div>