import { POSServiceCalendarSchedule } from '../models/pos-manager.model';
import { ScheduleAdvancedForm } from '../models/service-calendar.model';

export class ScheduleAdvanceConstants {
  static readonly MAX_SCHEDULE_NUMBER = 7 * 2; // 7 days * 2 ranges
  static readonly DEFAULT_TIME = '01:00';
  static readonly DEFAULT_SCHEDULE_ADVANCED_FORM_VALUE: ScheduleAdvancedForm = {
    enabled: false,
    isAllowTwoRange: false,
    first_range: {
      id: undefined,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME
    },
    second_range: {
      id: undefined,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME
    }
  };
  static readonly DEFAULT_SCHEDULES: POSServiceCalendarSchedule[] = [
    {
      day: 0,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 0
    },
    {
      day: 0,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 1
    },
    {
      day: 1,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 0
    },
    {
      day: 1,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 1
    },
    {
      day: 2,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 0
    },
    {
      day: 2,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 1
    },
    {
      day: 3,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 0
    },
    {
      day: 3,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 1
    },
    {
      day: 4,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 0
    },
    {
      day: 4,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 1
    },
    {
      day: 5,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 0
    },
    {
      day: 5,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 1
    },
    {
      day: 6,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 0
    },
    {
      day: 6,
      enable: false,
      start_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      end_time: ScheduleAdvanceConstants.DEFAULT_TIME,
      sort_order: 1
    }
  ];
}
