import { MenuItem, PriceType, ProductType } from 'src/app/models';

export interface ModifierPrompt {
  id: number;
  question_label: string;
  modifier_prompt_options: ModifierPromptOption[];
}

export enum ModifierPromptOptionType {
  Text = 'text',
  PosProduct = 'pos_product',
  Category = 'category',
  // this is not a real option type, just a helper to identify the child items of category
  CategoryChildItem = 'category_child_item'
}

export enum OperatorRoleNameType {
  Admin = 'admin',
  SuperAdmin = 'superadmin'
}
export interface ModifierPromptOption {
  id?: number;
  option_type: ModifierPromptOptionType;
  text_label?: string;
  added_price?: string;
  pos_product?: SharedObject;
  fullMenuItem?: MenuItem; // MenuItem;
  category?: SharedObject;
  sort_order?: number;
  _destroy?: boolean;

  // attribute for disabled option
  soldOut?: boolean;
}

export interface ModifierPromptRequest {
  question_label: string;
  modifier_prompt_options_attributes: (
    | ModifierPromptOptionCreateRequest
    | ModifierPromptOptionEditRequest
  )[];
}

export interface ModifierPromptOptionCreateRequest {
  option_type: ModifierPromptOptionType;
  //Option type 'text' attributes
  text_label?: string;
  added_price?: string;
  category_id?: number;
  pos_product_id?: number;
  sort_order: number;
}

export interface ModifierPromptOptionEditRequest
  extends ModifierPromptOptionCreateRequest {
  id: number;
  //When removing a modifier prompt option, add _destroy flag with value true
  _destroy?: boolean;
}

export interface SharedObject {
  id: number;
  name: string;
  type: string;
}

export interface GLAccountSharedObject extends SharedObject {
  account_code: number;
}

export interface SharedPosLocation extends SharedObject {
  price_book_id: number;
}

export interface BillingCodeSharedObject {
  id: number;
  code: string;
  description?: string;
}

export interface PosManagerSharedObjects {
  pos_locations: SharedPosLocation[];
  production_areas: SharedObject[];
  gl_accounts: GLAccountSharedObject[];
  categories: SharedObject[];
  pos_tax_settings: SharedObject[];
  pos_gift_card_profiles: SharedObject[];
  pos_billing_codes: BillingCodeSharedObject[];
  users: UserSharedObject[];
}

export interface UserSharedObject {
  id: number;
  username: string;
  rolename: OperatorRoleNameType;
}

export interface TaxSettings {
  id: number;
  pos_tax_setting_id: number;
  name: string;
}

export interface TaxAttributes {
  id?: number;
  pos_tax_setting_id: number;
  _destroy?: boolean;
}

export interface POSProductEditDto {
  name: string;
  billing_code_id?: number;
  taxes: number[];
  initialTaxState: TaxSettings[];
  tax_settings_attributes?: TaxAttributes[];
  gl_account_id?: number;
  production_area_id?: number;
  category_id: number;
  no_production: boolean;
  barcode?: string;
  product_type: ProductType;
  gift_card_profile_id?: number;
  price_type: PriceType;
  price?: number;
}

export interface POSProduct {
  id: number;
  name: string;
  barcode: string;
  price: number;
  category_id: number;
  product_type: ProductType;
  price_type: PriceType;
  can_be_discounted: boolean;
  type: string;
  course: number;
  sort: number;
  unit: string;
  no_production: boolean;
  tax_settings: TaxSettings[];
  initialTaxState: TaxSettings[];
  gl_account: GLAccountSharedObject;
  billing_code: BillingCodeSharedObject;
  production_area: SharedObject;
  category: SharedObject;
  gift_card_profile: SharedObject;
  modifier_prompt_assignments: POSModifierPromptAssignment[];
  locationId?: number;
  pos_quantity_restriction?: PosQuantityRestriction;
}

export interface POSMenu {
  menu_item_id: number;
  food_id: number;
  name: string;
  unit: string;
  amount: number;
  sort_order: any;
  category_id: number;
  category_name: string;
  production_area_id: string;
  production_area_name: string;
  course: number;
  price_type: PriceType;
  price: number;
  modifier_prompt_assignments: POSModifierPromptAssignment[];
  locationId?: number;
  pos_quantity_restriction?: PosQuantityRestriction;
}

export interface POSFullMenu {
  menu_items: POSMenu[];
  also_available_menu_items: POSMenu[];
  products: POSProduct[];
}

export interface POSMenuRequest {
  date: string;
  pos_location_id: number;
  meal_id?: number;
  include_all_items: boolean;
}

export interface POSModifierPromptAssignmentEditDto {
  id?: number;
  modifier_prompt_id: number;
  pos_product_id?: number;
  food_id?: number;
  menu_item_id?: number;
  min?: number;
  max?: number;
  sort_order: number;
  assignment_type?: string;
}

export interface POSModifierPromptAssignmentDeleteDto {
  id: number;
  pos_product_id?: number;
  food_id?: number;
}

export interface POSModifierPromptAssignment {
  id?: number;
  min?: number;
  max?: number;
  pos_product_id?: number;
  food_id?: number;
  sort_order?: number;
  modifier_prompt_options: ModifierPromptOption[];
  modifier_prompt_id?: number;
  question_label: string;
  // new category_id for POSV3-1254: POS Manager | Modifier Prompt Applied from Category Cannot be Edited or Removed
  // this field has value if this is the modifier prompt assignment for a category
  category_id?: number;
  _destroy?: boolean;
  isTouched?: boolean;
  isValid?: boolean;
  selectedOptions?: ModifierPromptOption[];
  formattedOptions?: ModifierPromptOption[];
}

export interface POSManagerProduct {
  id: number;
  variable_cost: boolean;
  barcode: string;
  product_type: string;
  name: string;
  no_production: boolean;
  tax: SharedObject;
  gl_account: GLAccountSharedObject;
  billing_code: BillingCodeSharedObject;
  production_area: SharedObject;
  category: SharedObject;
  gift_card_profile: SharedObject;
}

export interface POSManagerOperator {
  id?: number;
  first_name?: string;
  last_name?: string;
  staff_number?: number;
  can_approve_void?: boolean;
  can_approve_discount?: boolean;
  can_approve_refund?: boolean;
  can_view_pos_beta_reports_in_pos_manager?: boolean;
  pin_code?: string;
  operator_id?: number;
  pincode_reset_on_first_login?: boolean;
  user_id?: number;
  can_assign_tables?: boolean;
  can_manage_tables?: boolean;
}

export interface POSManagerService extends POSProduct {
  service_duration_hours: number;
  service_duration_minutes: number;
}

export interface POSServiceCalendarSchedule {
  id?: number;
  day: number;
  enable: boolean;
  start_time: string;
  end_time: string;
  sort_order: number | null;
}

export enum ExceptionType {
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available'
}

export interface POSServiceCalendarException {
  id?: number;
  index?: number;
  exception_name: string;
  start_date: string;
  end_date?: string;
  all_day?: boolean;
  start_time: string;
  end_time: string;
  exception_type: ExceptionType;
  _destroy?: boolean;
}

export interface POSServiceCalendarExceptionDisplay {
  exception: POSServiceCalendarException;
  isActive: boolean;
}
export interface POSServiceCalendarService {
  id?: number;
  pos_product_id: number;
  name: string;
}
export interface POSServiceCalendarServiceRequest {
  pos_product_id: number;
}

export interface POSSchedule {
  id?: number;
  name: string;
  allowed_services: number[];
  color: string;
  pos_location_id: number;
  end_date?: string;
  start_time?: string;
  end_time?: string;
  // deleted?: boolean;
  has_active_booking: boolean;
  enable_advance_time: boolean;
  service_calendar_schedules: POSServiceCalendarSchedule[];
  service_calendar_exceptions: POSServiceCalendarException[];
  service_calendar_services: POSServiceCalendarService[];
}
export interface POSDisplayedSchedule extends POSSchedule {
  available_days: number[];
  services: string;
}
export interface POSScheduleRequest {
  id?: number;
  name: string;
  color: string;
  pos_location_id: number;
  end_date?: string;
  service_calendar_schedules_attributes: POSServiceCalendarSchedule[];
  service_calendar_exceptions_attributes: POSServiceCalendarException[];
  service_calendar_services_attributes: POSServiceCalendarServiceRequest[];
}

export interface POSManagerAppointment {
  id: string;
  resourceID: string;
  date: string;
  time: string;
  service: string;
  schedule: string;
  client: string;
  note: string;
}

export enum PriceBookItemType {
  Product = 'Pos::Product',
  Food = 'Food',
  Plate = 'Plate'
}

export interface SetPriceBookPayload {
  priceBookId: number;
  itemId: number;
  price_type: PriceType;
  itemType: PriceBookItemType;
  price?: number;
}

export interface SetPriceBookResponse {
  price_book_item: {
    id: number;
    price?: number;
    price_type: PriceType;
    price_bookable_id: number;
    price_bookable_type: PriceBookItemType;
  };
}

export interface PosQuantityRestriction {
  id?: number;
  starting_quantity: number;
  remaining_quantity?: number;
  sold_out: boolean;
  restriction_date: string;
  product_id?: number;
  food_id?: number;
  created_at?: string;
  updated_at?: string;
}

export interface POSProductQuantityRestrictionsRequest {
  id?: number;
  food_id?: number;
  pos_product_id?: number;
  starting_quantity: number;
  remaining_quantity?: number;
  restriction_date: string;
  sold_out: boolean;
}

export interface POSProductQuantityRestrictionsResponse {
  quantity_restrictions: PosQuantityRestriction[];
}

export type PosMenuItem = (POSMenu | POSProduct) &
  Partial<{
    type: 'product' | 'menu';
    productionName: string;
    categoryName: string;
    foodId: number;
  }>;

type PosSortValue = 'asc' | 'desc';

export interface PosSortAttributes {
  sortName: PosSortValue;
  sortCategory: PosSortValue;
}

export interface PosItemsManagementColumnSettings {
  showCategoryColumn: boolean;
  showProdColumn: boolean;
  showQtyLimitColumn: boolean;
  showUnavailableColumn: boolean;
  showPriceColumn: boolean;
  showTaxColumn: boolean;
}
