import { NumericDictionary } from 'lodash';
import { denormalize } from 'normalizr';
import { Diner, NormalizedDiner, dinersSchema } from 'src/app/models';

export class DinerHelper {
  static getTicketDiner(
    dinerById: NumericDictionary<NormalizedDiner>,
    selectedDinerId: number
  ): Diner {
    return (
      dinerById &&
      selectedDinerId &&
      (denormalize(selectedDinerId, dinersSchema, {
        diners: dinerById
      }) as Diner)
    );
  }
}
