import { ComponentType } from '@angular/cdk/portal';
import {
  MenuAttributes,
  MenuOptions,
  PosMenuController
} from './pos-menu.controller';

export class MenuRef<K = any> {
  constructor(private readonly _service: PosMenuController) {}

  async open<T, D = any>(
    component: ComponentType<T>,
    options?: Partial<MenuOptions<D>>
  ) {
    return await this._service.open(component, options);
  }

  async close(data?: K) {
    await this._service.close(data);
  }

  setAttrs(attrs: Partial<MenuAttributes>) {
    this._service.setAttrs(attrs);
  }
}
