import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertButton, AlertController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import {
  MonoTypeOperatorFunction,
  Observable,
  Subscriber,
  TimeoutError,
  pipe,
  retry,
  switchMap,
  timeout
} from 'rxjs';

import { CustomErrorCode, ErrorConstant } from 'src/app/constants';
import { ApiType, PosConfig } from 'src/app/pos.config';
import { ResetApplication } from 'src/app/store/app/app.action';
import { ModalService } from '../modal/modal.service';
import { PosLoadingModalService } from 'src/app/components/shared/loading-modal/service';
import { MenuConstant } from 'src/app/constants/menu.constant';

type PosError = HttpErrorResponse | TimeoutError<unknown, unknown> | Error;

@Injectable({ providedIn: 'root' })
export class ErrorService {
  constructor(
    private readonly alertCtrl: AlertController,
    private readonly store: Store,
    private readonly modalService: ModalService,
    private readonly posLoadingModal: PosLoadingModalService
  ) {}

  alertError(message: string) {
    this.alertCtrl
      .create({
        header: 'Error',
        message,
        backdropDismiss: false,
        cssClass: 'alert-message',
        buttons: [
          {
            text: 'OK',
            role: 'ok'
          }
        ]
      })
      .then((alert) => alert.present());
  }

  retryPipe = <T>(type?: ApiType): MonoTypeOperatorFunction<T> => {
    type RetryPipe = MonoTypeOperatorFunction<T>;
    const retryFunc = retry({ delay: (error) => this.retry(error) });
    const timeoutFunc = timeout(PosConfig.POS_API_TIMEOUT_TIME(type));
    switch (type) {
      case ApiType.MenuTallyLoading:
        const menuTallyLoadingRetryFunc = retry({
          delay: (error) => {
            this.posLoadingModal.dismiss();
            return this.retry(error).pipe(
              switchMap(() =>
                this.posLoadingModal.presentLoadingModal(
                  MenuConstant.LOADING_MODAL_MSG.MENU_TALLY_LOADING,
                  MenuConstant.LOADING_MODAL_MSG.MENU_TALLY_LOADING_LONGER
                )
              )
            );
          }
        });

        return pipe(timeoutFunc, menuTallyLoadingRetryFunc) as RetryPipe;
      case ApiType.POSItemsManagingLoading:
        const posItemsLoadingRetryFunc = retry({
          delay: (error) => {
            this.posLoadingModal.dismiss();
            return this.retry(error).pipe(
              switchMap(() =>
                this.posLoadingModal.presentLoadingModal(
                  MenuConstant.LOADING_MODAL_MSG.LOADING_ITEMS,
                  MenuConstant.LOADING_MODAL_MSG.MENU_TALLY_LOADING_LONGER
                )
              )
            );
          }
        });

        return pipe(timeoutFunc, posItemsLoadingRetryFunc) as RetryPipe;
      case ApiType.Mutate:
      case ApiType.Query:
        return pipe(timeoutFunc, retryFunc) as RetryPipe;
      default:
        return pipe(retryFunc) as RetryPipe;
    }
  };

  private readonly retry = (
    error: HttpErrorResponse | TimeoutError | Error,
    retryMessage?: string
  ) =>
    new Observable<string>((sub) => {
      this.modalService.closeLoadingModal();
      const isTimeoutError = error instanceof TimeoutError;
      const httpStatus = (error as HttpErrorResponse).status;
      if (retryMessage) {
        this._retryAlert({
          retryMessage,
          sub,
          error
        });
      } else if (
        isTimeoutError ||
        httpStatus === 0 ||
        httpStatus === HttpStatusCode.InternalServerError
      ) {
        this._retryAlert({
          isTimeoutError,
          httpStatus,
          sub,
          error
        });
      } else if (httpStatus === HttpStatusCode.Unauthorized) {
        this.alertError(
          'The session has expired or the device was unregistered.'
        );
        this.store.dispatch(new ResetApplication());
        sub.error(error);
      } else {
        sub.error(error);
      }
    });

  private readonly _retryAlert = ({
    retryMessage,
    isTimeoutError,
    httpStatus,
    sub,
    error
  }: Partial<{
    retryMessage: string;
    isTimeoutError: boolean;
    httpStatus: number;
    sub: Subscriber<unknown>;
    error: PosError;
  }>) =>
    this.retryAlert(
      retryMessage
        ? retryMessage
        : isTimeoutError
        ? ErrorConstant.ErrorMessageByCode[CustomErrorCode.RequestTimeout]
        : httpStatus === 0
        ? ErrorConstant.ErrorMessageByCode[CustomErrorCode.HttpNoResponseError]
        : ErrorConstant.ErrorMessageByCode[HttpStatusCode.InternalServerError]
    ).then((isRetry) => (isRetry ? sub.next('retry') : sub.error(error)));

  private async retryAlert(message: string): Promise<boolean> {
    const buttons: (string | AlertButton)[] = [
      { text: 'CANCEL', cssClass: 'alert-button-cancel' },
      { text: 'RETRY', role: 'retry', cssClass: 'alert-button-retry' }
    ];
    const alert = await this.alertCtrl.create({
      cssClass: 'custom-alert-error-1',
      header: 'ERROR',
      mode: 'md',
      message,
      buttons
    });
    await alert.present();
    const result = await alert.onDidDismiss();
    return result.role === 'retry';
  }
}
