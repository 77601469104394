import {
  PendingAssignment,
  PendingSeatAssignmentResponse,
  GuestPendingAssignment,
  PosPendingSeatAssignment
} from 'src/app/models';

export class HostModeHelper {
  static getPendingAssignmentFromFloorPlan(
    items: PosPendingSeatAssignment[],
    table_id: number
  ): PendingAssignment[] {
    const checkGuest = (
      i: PosPendingSeatAssignment
    ): i is GuestPendingAssignment => i.guest;
    return items.map((i) => ({
      id: i.id,
      guest: i.guest,
      diner_id: i.diner_id,
      name: checkGuest(i) ? 'Guest Diner' : i.diner?.name,
      table_id,
      table_name: null,
      created_at: null,
      live_location: !checkGuest(i) ? i.diner?.live_location : null
    }));
  }

  static getAllPendingAssignments(
    item: PendingSeatAssignmentResponse[]
  ): PendingAssignment[] {
    return item.map((i) => ({
      ...i,
      name: i.guest ? 'Guest Diner' : `${i.first_name} ${i.last_name}`,
      live_location: null
    }));
  }
}
