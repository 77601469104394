import { createPropertySelectors, createSelector } from '@ngxs/store';
import { denormalize } from 'normalizr';
import _ from 'lodash';

import {
  modifierPromptsSchema,
  modifierPromptsSchemaArray
} from 'src/app/models';
import { ModifierPrompt } from 'src/app/models/pos-manager.model';
import { PosManagerState, PosManagerStateModel } from './pos-manager.state';

export class PosManagerSelectors {
  static slices =
    createPropertySelectors<PosManagerStateModel>(PosManagerState);

  static getEmbeddedDashboardUrl = createSelector(
    [PosManagerSelectors.slices.embeddedDashboardUrl],
    (embeddedDashboardUrl) => embeddedDashboardUrl
  );

  // move from pos-manager.state.ts
  static getModifierPromptsData = createSelector(
    [PosManagerSelectors.slices.modifierPrompts],
    (modifierPrompts) => modifierPrompts
  );

  // move from pos-manager.state.ts
  static getSharedObjects = createSelector(
    [PosManagerSelectors.slices.sharedObjects],
    (sharedObjects) => sharedObjects
  );

  static getModifierPromptsLoaded = createSelector(
    [PosManagerSelectors.getModifierPromptsData],
    ({ loaded }) => loaded
  );

  static getModifierPrompts = createSelector(
    [PosManagerSelectors.getModifierPromptsData],
    (modifierPrompts) => {
      const entities = {
        modifierPrompts: modifierPrompts.items.byId
      };
      const denormalizedModifierPrompts: ModifierPrompt[] = denormalize(
        modifierPrompts.items.allIds,
        modifierPromptsSchemaArray,
        entities
      );
      return denormalizedModifierPrompts;
    }
  );

  static getSelectedModifierPrompt = createSelector(
    [PosManagerSelectors.getModifierPromptsData],
    (modifierPrompts) => {
      const entities = {
        modifierPrompts: modifierPrompts.items.byId,
        modifierPromptOptions: modifierPrompts.modifierPromptOptions.byId
      };

      const denormalizedModifierPrompt: ModifierPrompt = denormalize(
        modifierPrompts.selectedModifierPromptId,
        modifierPromptsSchema,
        entities
      );

      denormalizedModifierPrompt.modifier_prompt_options.sort((a, b) =>
        a.sort_order > b.sort_order ? 1 : -1
      );

      return denormalizedModifierPrompt;
    }
  );

  static getProducts = createSelector(
    [PosManagerSelectors.getModifierPromptsData],
    (modifierPrompts) => modifierPrompts.products || []
  );

  static getPosLocations = createSelector(
    [PosManagerSelectors.getSharedObjects],
    (sharedObjects) => sharedObjects.pos_locations || []
  );

  static getProductionAreas = createSelector(
    [PosManagerSelectors.getSharedObjects],
    (sharedObjects) => sharedObjects.production_areas || []
  );

  static getGLAccounts = createSelector(
    [PosManagerSelectors.getSharedObjects],
    (sharedObjects) => sharedObjects.gl_accounts || []
  );

  static getCategories = createSelector(
    [PosManagerSelectors.getSharedObjects],
    (sharedObjects) => sharedObjects.categories || []
  );

  static getPosTaxSettings = createSelector(
    [PosManagerSelectors.getSharedObjects],
    (sharedObjects) => sharedObjects.pos_tax_settings || []
  );

  static getPosGiftCardProfiles = createSelector(
    [PosManagerSelectors.getSharedObjects],
    (sharedObjects) => sharedObjects.pos_gift_card_profiles || []
  );

  static getPosBillingCodes = createSelector(
    [PosManagerSelectors.getSharedObjects],
    (sharedObjects) => sharedObjects.pos_billing_codes || []
  );

  static getUsers = createSelector(
    [PosManagerSelectors.getSharedObjects],
    (sharedObjects) => sharedObjects.users || []
  );

  static getNonFoodCategories = createSelector(
    [PosManagerSelectors.getCategories],
    (categories) => _.filter(categories, (i) => i.type === 'non_food')
  );
}
