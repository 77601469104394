import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimeoutModal } from '../modals/timeout/timeout.modal';
import { IdleParams } from '../models';
import { ModalService } from '../services';

const DEFAULT_TIMEOUT = 30;
/** Default buffer between idle and timeout */
const IDLE_TIMEOUT_BUFFER = 10;

/*
  Provider to quickly setup idle watching and performing actions on idle and/or timeout
*/
@Injectable({
  providedIn: 'root'
})
export class IdleProvider {
  destroy$: Subject<boolean>;
  timeoutModalOpts: {};

  constructor(private idle: Idle, private modalService: ModalService) {}

  /** Setup detecting and responding to user idleness using the parameters provided */
  setupIdle(params: IdleParams) {
    const { onTimeout, name } = params;
    this.idle.setIdleName(name);
    if (!this.idle.isRunning()) {
      if (!this.idle.isIdling()) {
        this.destroy$ = new Subject<boolean>();
        const { idle, timeout } = this.timingFor(params);
        // Set idle and timeout
        this.idle.setIdle(idle);
        this.idle.setTimeout(timeout);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        // Show timeout modal when idle started
        this.idle.onIdleStart.pipe(takeUntil(this.destroy$)).subscribe(() => {
          if (timeout) {
            this.showTimeoutPopover(timeout);
          } else {
            onTimeout();
          }
        });
        // Logout on timeout
        this.idle.onTimeout.pipe(takeUntil(this.destroy$)).subscribe(() => {
          if (this.timeoutModalOpts) {
            this.timeoutModalOpts[this.modalService.MODAL_DESTROYED_PROPERTY] =
              true;
          }
          onTimeout();
        });
      }
      // Watch for idle
      this.watchIdle();
    }
  }

  private showTimeoutPopover(timeout: number | boolean) {
    this.timeoutModalOpts = {
      backdropDismiss: true,
      modalDestroyed: false,
      cssClass: 'pos-idle-modal'
    };
    this.modalService.showModal(
      TimeoutModal,
      { countdown: timeout, idle: this.idle },
      this.timeoutModalOpts,
      () => this.idle.interrupt(true)
    );
  }

  /** Get the timeout for the given params
   * TODO: Testing
   */
  private timingFor(params: Partial<IdleParams>): Partial<IdleParams> {
    let { timeout = false, idle } = params;
    if (!timeout && idle >= DEFAULT_TIMEOUT + IDLE_TIMEOUT_BUFFER) {
      timeout = DEFAULT_TIMEOUT;
      idle = idle - timeout;
    }
    return { idle, timeout: timeout || false };
  }

  /** Stop detecting user idleness */
  stopIdle() {
    if (this.idle.isRunning() && this.destroy$) {
      this.destroy$.next(true);
      // Complete subscription
      this.destroy$.complete();
      // Nullify destroy
      this.destroy$ = null;
      this.idle.stop();
      this.idle.interrupt();
    }
  }

  /** Detect user idleness */
  watchIdle() {
    this.idle.watch();
  }
}
