import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PosManagerConfirmModal } from './pos-manager-confirm.modal';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [PosManagerConfirmModal],
  imports: [CommonModule, IonicModule],
  exports: [PosManagerConfirmModal]
})
export class PosManagerConfirmModalModule {}
