import { Action, State, StateContext, Store } from '@ngxs/store';
import {
  CreatePOSSchedule,
  CreatePOSScheduleSuccess,
  CreatePOSServiceCalendarException,
  CreatePOSServiceCalendarExceptionSuccess,
  DeletePOSSchedule,
  DeletePOSScheduleSuccess,
  DeletePOSServiceCalendarException,
  DeletePOSServiceCalendarExceptionSuccess,
  GetSchedules,
  GetSchedulesSuccess,
  SelectException,
  SelectScheduleDetail,
  SetPOSScheduleLoaded,
  UpdatePOSSchedule,
  UpdatePOSScheduleSuccess,
  UpdatePOSServiceCalendarException,
  UpdatePOSServiceCalendarExceptionSuccess
} from './pos-schedules.action';
import {
  POSSchedule,
  POSServiceCalendarException
} from 'src/app/models/pos-manager.model';
import { catchError, finalize, tap } from 'rxjs';

import { CommonConstant } from 'src/app/constants';
import { ExceptionError } from 'src/app/store/error/error.action';
import { HttpRequestError } from 'src/app/store/error/error.action';
import { Injectable } from '@angular/core';
import { PosSchedulesService } from 'src/app/services/pos-manager/pos-schedules/pos-schedules.service';
import _ from 'lodash';
import moment from 'moment';
import { produce } from 'immer';
import {
  LOCATION_STATE_TOKEN,
  LocationState
} from 'src/app/store/location/location.state';
import { HttpErrorResponse } from '@angular/common/http';

export interface PosScheduleStateModel {
  loaded: boolean;
  isDeleting: boolean;
  schedules: POSSchedule[];
  exceptions: POSServiceCalendarException[];
  selectedSchedule: POSSchedule;
  selectedException: POSServiceCalendarException;
}

@State<PosScheduleStateModel>({
  name: 'posSchedule',
  defaults: {
    loaded: false,
    isDeleting: false,
    schedules: [],
    exceptions: [],
    selectedSchedule: null,
    selectedException: null
  }
})
@Injectable()
export class PosScheduleState {
  constructor(
    private readonly posSchedulesService: PosSchedulesService,
    private readonly store: Store
  ) {}

  @Action(GetSchedules)
  getSchedules(ctx: StateContext<PosScheduleStateModel>) {
    ctx.patchState({ loaded: false });
    return this.posSchedulesService.getSchedules().pipe(
      tap((schedules) => ctx.dispatch(new GetSchedulesSuccess(schedules))),
      catchError((_e: HttpErrorResponse) =>
        ctx.dispatch(
          new HttpRequestError(
            'There was an error while fetching POS Manager Schedules. Please exit POS Manager and re-enter. If the problem persists, please call support.'
          )
        )
      ),
      finalize(() => {
        ctx.setState(
          produce((draft) => {
            draft.loaded = true;
          })
        );
      })
    );
  }

  @Action(GetSchedulesSuccess)
  getSchedulesSuccess(
    ctx: StateContext<PosScheduleStateModel>,
    { schedules }: GetSchedulesSuccess
  ) {
    ctx.setState(
      produce((draft) => {
        draft.schedules = _.filter(schedules, {
          pos_location_id:
            this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.id
        });
        draft.selectedSchedule = null;
        draft.exceptions = [];
        draft.selectedException = null;
      })
    );
  }

  @Action(SelectScheduleDetail)
  selectSchedule(
    ctx: StateContext<PosScheduleStateModel>,
    { scheduleId }: SelectScheduleDetail
  ) {
    if (scheduleId !== null) {
      ctx.patchState({ loaded: false });
      return this.posSchedulesService.getScheduleDetail(scheduleId).pipe(
        tap((schedule) =>
          ctx.setState(
            produce((draft) => {
              draft.selectedSchedule = schedule;
              draft.exceptions =
                schedule.service_calendar_exceptions.map((exception) => ({
                  ...exception,
                  start_time: this.parseTime(exception.start_time),
                  end_time: this.parseTime(exception.end_time)
                })) || [];
              draft.selectedException = null;
            })
          )
        ),
        catchError((e) => {
          ctx.dispatch(
            new HttpRequestError(
              'There was an error while fetching POS Manager Schedule Detail. Please exit POS Manager and re-enter. If the problem persists, please call support.'
            )
          );
          throw e;
        }),
        finalize(() => {
          ctx.setState(
            produce((draft) => {
              draft.loaded = true;
            })
          );
        })
      );
    } else {
      ctx.setState(
        produce((draft) => {
          draft.selectedSchedule = null;
          draft.exceptions = [];
          draft.selectedException = null;
        })
      );
    }
  }

  @Action(SetPOSScheduleLoaded)
  setIsLoaded(
    ctx: StateContext<PosScheduleStateModel>,
    { isLoaded }: SetPOSScheduleLoaded
  ) {
    ctx.setState(
      produce((draft: PosScheduleStateModel) => {
        draft.loaded = isLoaded;
      })
    );
  }

  @Action(CreatePOSSchedule)
  createPOSService(
    ctx: StateContext<PosScheduleStateModel>,
    { schedule }: CreatePOSSchedule
  ) {
    ctx.patchState({ loaded: false });
    return this.posSchedulesService.createSchedule(schedule).pipe(
      tap((newSchedule) => {
        ctx.setState(
          produce((draft: PosScheduleStateModel) => {
            draft.schedules.push(newSchedule);
            draft.selectedSchedule = newSchedule;
            draft.exceptions =
              newSchedule.service_calendar_exceptions.map((exception) => ({
                ...exception,
                start_time: this.parseTime(exception.start_time),
                end_time: this.parseTime(exception.end_time)
              })) || [];
          })
        );

        ctx.dispatch(new CreatePOSScheduleSuccess(newSchedule));
      }),
      catchError((e: HttpErrorResponse) =>
        ctx.dispatch(
          new ExceptionError(`There was an error while creating schedule`)
        )
      ),
      finalize(() => {
        ctx.setState(
          produce((draft) => {
            draft.loaded = true;
          })
        );
      })
    );
  }

  @Action(UpdatePOSSchedule)
  updatePOSService(
    ctx: StateContext<PosScheduleStateModel>,
    { id, schedule }: UpdatePOSSchedule
  ) {
    ctx.patchState({ loaded: false });
    return this.posSchedulesService.updateSchedule(id, schedule).pipe(
      tap((updatedProduct) => {
        ctx.setState(
          produce((draft: PosScheduleStateModel) => {
            const index = _.findIndex(draft.schedules, (x) => x.id === id);
            if (index > -1) {
              draft.schedules[index] = updatedProduct;
              draft.selectedSchedule = updatedProduct;
              draft.exceptions =
                updatedProduct.service_calendar_exceptions.map((exception) => ({
                  ...exception,
                  start_time: this.parseTime(exception.start_time),
                  end_time: this.parseTime(exception.end_time)
                })) || [];
            }
          })
        );

        ctx.dispatch(new UpdatePOSScheduleSuccess(updatedProduct));
      }),
      catchError((e: HttpErrorResponse) =>
        ctx.dispatch(
          new ExceptionError(
            e.error[0] || `There was an error while updating schedule id ${id}`
          )
        )
      ),
      finalize(() => {
        ctx.setState(
          produce((draft) => {
            draft.loaded = true;
          })
        );
      })
    );
  }

  @Action(DeletePOSSchedule)
  deletePOSService(
    ctx: StateContext<PosScheduleStateModel>,
    { id }: DeletePOSSchedule
  ) {
    ctx.patchState({ isDeleting: true });
    return this.posSchedulesService.deleteSchedule(id).pipe(
      tap((success) => {
        if (success) {
          ctx.setState(
            produce((draft: PosScheduleStateModel) => {
              draft.schedules = draft.schedules.filter(
                (item) => item.id !== id
              );
            })
          );

          ctx.dispatch(new DeletePOSScheduleSuccess());
        }
      }),
      catchError((e) => {
        ctx.dispatch(
          new ExceptionError(
            `There was an error while deleting schedule id ${id}`
          )
        );
        throw e;
      }),
      finalize(() => {
        ctx.setState(
          produce((draft) => {
            draft.isDeleting = false;
          })
        );
      })
    );
  }

  @Action(SelectException)
  selectException(
    ctx: StateContext<PosScheduleStateModel>,
    { exception }: SelectException
  ) {
    ctx.setState(
      produce((draft) => {
        draft.selectedException = exception;
      })
    );
  }

  @Action(CreatePOSServiceCalendarException)
  createException(
    ctx: StateContext<PosScheduleStateModel>,
    { exception }: CreatePOSServiceCalendarException
  ) {
    ctx.setState(
      produce((draft: PosScheduleStateModel) => {
        draft.exceptions.push(exception);
      })
    );
    return ctx.dispatch(
      new CreatePOSServiceCalendarExceptionSuccess(exception)
    );
  }

  @Action(UpdatePOSServiceCalendarException)
  updatePOSServiceCalendarException(
    ctx: StateContext<PosScheduleStateModel>,
    { index, exception }: UpdatePOSServiceCalendarException
  ) {
    ctx.setState(
      produce((draft: PosScheduleStateModel) => {
        if (draft.exceptions[index]) {
          if (draft.exceptions[index].id) {
            draft.exceptions[index] = {
              ...exception,
              id: draft.exceptions[index].id
            };
          } else {
            draft.exceptions[index] = exception;
          }
        }
      })
    );

    return ctx.dispatch(
      new UpdatePOSServiceCalendarExceptionSuccess(exception)
    );
  }

  @Action(DeletePOSServiceCalendarException)
  deleteException(
    ctx: StateContext<PosScheduleStateModel>,
    { index }: DeletePOSServiceCalendarException
  ) {
    ctx.setState(
      produce((draft: PosScheduleStateModel) => {
        const exception = draft.exceptions[index];
        if (exception) {
          if (exception.id) {
            draft.exceptions[index] = {
              ...exception,
              _destroy: true
            };
          } else {
            draft.exceptions = draft.exceptions.filter(
              (_item, i) => i !== index
            );
          }
        }
      })
    );

    return ctx.dispatch(new DeletePOSServiceCalendarExceptionSuccess());
  }

  // parse BE datetime format to hh:mm
  private parseTime(time: string) {
    return time
      ? moment(time, CommonConstant.MS_DATE_TIME.TIME_FORMAT_24h).format(
          CommonConstant.MS_DATE_TIME.TIME_FORMAT_24h
        )
      : null;
  }
}
