export class MacroGridConstant {
  static readonly MACRO_GRID_TYPE = {
    FOOD: 'food',
    MODIFIER: 'modifier'
  };

  static readonly MACRO_GRID_ITEM_TYPE = {
    CATEGORY: 'category',
    SUBPAGE: 'subpage',
    PRODUCT: 'product',
    MODIFIER: 'modifier'
  };
}
