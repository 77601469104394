import { Dictionary, keyBy } from 'lodash';
import moment, { parseZone } from 'moment';

import { MealConstant } from '../constants';
import { Meal, MealEndTimes, MealName, MealTime, SnackName } from '../models';

export class MealHelper {
  // Meal Names
  private static readonly ALL_MEAL_NAMES: Array<MealName | SnackName> = [
    MealName.Breakfast,
    SnackName.Snack_AM,
    MealName.Lunch,
    SnackName.Snack_PM,
    MealName.Diner,
    SnackName.Snack_HS
  ];

  // Meal Short Name Map
  private static readonly MEAL_SHORT_NAME_MAP = {
    am: 'Brk',
    pm: 'Lun',
    hs: 'Din'
  };

  // Generate definitions
  private static readonly _meals: Meal[] = MealHelper.ALL_MEAL_NAMES.map<Meal>(
    (name, inx) => {
      const MealID = inx + 1;
      const keyName = name.toLowerCase();
      const [type] = MealID % 2 === 0 ? ['snack', -2] : ['meal', -1];
      const time = MealID > 2 ? (MealID > 4 ? 'hs' : 'pm') : 'am';
      const shortName = MealHelper.MEAL_SHORT_NAME_MAP[time];
      const bigTime = time.toUpperCase();
      const suffix = bigTime + shortName;

      return {
        name,
        keyName,
        type,
        time,
        suffix,
        shortName,
        id: MealID
      };
    }
  );

  static readonly ALL_MEALS_BY_ID: Dictionary<Meal> = keyBy(
    MealHelper._meals,
    'id'
  );
  static readonly MEALS: Meal[] = MealHelper._meals.filter(
    (i) => i.type === 'meal'
  );

  static getMealIdByTime(meal_end_times: MealEndTimes): MealTime {
    const today = new Date().setHours(0, 0, 0, 0);
    const default_breakfast_end = parseZone(
      moment(today).add(10, 'hours').add(15, 'minutes')
    );
    const default_lunch_end = parseZone(
      moment(today).add(14, 'hours').add(30, 'minutes')
    );
    const default_diner_end = parseZone(moment(today).add(23, 'hours'));
    const breakfast_end = meal_end_times?.breakfast
      ? MealHelper.convertToNowTime(meal_end_times.breakfast)
      : default_breakfast_end;
    const lunch_end = meal_end_times?.lunch
      ? MealHelper.convertToNowTime(meal_end_times.lunch)
      : default_lunch_end;
    const dinner_end = meal_end_times?.dinner
      ? MealHelper.convertToNowTime(meal_end_times.dinner)
      : default_diner_end;

    const currentTime = parseZone(moment());
    if (breakfast_end && currentTime.isBefore(breakfast_end)) {
      return MealConstant.BREAKFAST_ID;
    } else if (lunch_end && currentTime.isBefore(lunch_end)) {
      return MealConstant.LUNCH_ID;
    } else if (dinner_end && currentTime.isBefore(dinner_end)) {
      return MealConstant.DINNER_ID;
    }
    return MealConstant.BREAKFAST_ID;
  }

  static convertToNowTime(date: string) {
    if (date) {
      const [hour, minute] = date.split(':');
      const value = moment(new Date())
        .set('hour', parseInt(hour, 10))
        .set('minute', parseInt(minute, 10))
        .set('second', 0);
      return moment.parseZone(value);
    }
    return null;
  }
}
