import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GetMacroGridDetail, GetMacroGrids } from '../store/menu/menu.action';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';
import { LocationType } from 'src/app/models';

@Injectable()
export class LoginResolver implements Resolve<Observable<boolean> | boolean> {
  constructor(private readonly store: Store) {}

  resolve(): Observable<boolean> | boolean {
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isServiceLocation: boolean = locationType === LocationType.Services;
    const isSelfServiceLocation: boolean =
      locationType === LocationType.SelfServe;
    const actions = [];
    if (isServiceLocation) {
      actions.push(new GetMacroGridDetail());
    } else if (!isSelfServiceLocation) {
      /**
       * The GetMacroGrids action is called within the Initialize action specifically for the SSK location.
       */
      actions.push(new GetMacroGrids());
    }

    return !!actions.length
      ? this.store.dispatch(actions).pipe(
          catchError(() => of(true)),
          map(() => true)
        )
      : true;
  }
}
