import { NormalizedSchema, schema } from 'normalizr';
import {
  FloorplanStatus,
  NormalizedEntities,
  Operator,
  Seat,
  Table,
  TicketStatus
} from '..';
import { ticketsSchema } from './normalized-ticket.model';

const processStrategy: schema.StrategyFunction<Seat> = (
  value,
  parent: Table,
  key
) => ({
  ...value,
  tableId: parent.id
});

export const seatsSchema = new schema.Entity<Seat>(
  'seats',
  {
    ticket: ticketsSchema
  },
  {
    processStrategy
  }
);
export const seatsSchemaArray = new schema.Array(seatsSchema);

export interface NormalizedSeat {
  id: number;
  number?: number;
  diner_id?: number;
  align?: string;
  focus?: string;
  is_temporary_seat?: boolean;
  ticket?: string;
  device_ticket_uuid?: string;
  ticket_locked?: boolean;
  ticket_locked_by_operator_id?: number;
  ticket_status?: TicketStatus;
  floorplan_status?: FloorplanStatus;
  tableId: number;
  ticket_created_at?: string;
  staff?: Operator;
  isSelected?: boolean;
  first_name?: string;
  last_name?: string;
  diner_name?: string;
  // local_attributes?: {
  //   display_name?: string;
  //   selected?: boolean;
  // };
}

export type NormalizedSeatData = NormalizedSchema<
  Pick<NormalizedEntities, 'seats'>,
  number[]
>;
