import _ from 'lodash';

import {
  CardTerminal,
  LocationType,
  PaymentLocation,
  Printer
} from 'src/app/models';
import { environment } from 'src/environments/environment';

export class AppStateHelper {
  static getDefaultPrinter(
    defaultPrinter: number,
    printers: Printer[],
    payment_location_default_printer_id: number
  ): number {
    if (defaultPrinter) {
      return defaultPrinter;
    }

    if (!printers?.length) {
      return null;
    }

    return (
      payment_location_default_printer_id
        ? _.find(printers, (x) => x.id === payment_location_default_printer_id)
        : _.first(printers)
    )?.id;
  }

  static getDefaultCardTerminal(
    card_terminals: CardTerminal[],
    default_payment_terminal_id: number,
    defaultCardTerminal: CardTerminal,
    locationType: LocationType
  ): CardTerminal {
    const cardTerminals = card_terminals || [];
    if (!cardTerminals.length) {
      return null;
    }
    const hasOneCardTerminal: boolean = cardTerminals.length === 1;
    const defaultCardTerminalId = default_payment_terminal_id;
    const defaultTerminal =
      defaultCardTerminal ||
      cardTerminals.find((i) => i.id === defaultCardTerminalId) ||
      null;

    switch (locationType) {
      case LocationType.SelfServe:
        return defaultTerminal || (hasOneCardTerminal && cardTerminals[0]);
      default:
        return defaultTerminal || cardTerminals[0];
    }
  }

  static getApiUrl = (baseUrl: string) =>
    baseUrl + `/api/pos/v${environment.MSPOS_API_VERSION}/`;

  static getPosManagerApiUrl = (baseUrl: string) =>
    baseUrl + `/api/manager/v${environment.MSPOS_MANAGER_API_VERSION}/`;

  static paymentLocationId(
    payment_location: PaymentLocation,
    default_printer: number,
    printers: Printer[],
    payment_locations: PaymentLocation[],
    fromTools = false
  ): number {
    let paymentLocationId: number = payment_location.id;

    if (!fromTools) {
      return paymentLocationId;
    }

    const defaultPrinter = AppStateHelper.getDefaultPrinter(
      default_printer,
      printers,
      payment_location.default_printer_id
    );

    paymentLocationId =
      payment_locations.find((p) => p.default_printer_id === defaultPrinter)
        ?.id || paymentLocationId;

    return paymentLocationId;
  }
}
