import { EventStore, EventUi } from '@fullcalendar/angular';
import _ from 'lodash';

import { ExceptionType } from 'src/app/models/pos-manager.model';

export interface ServiceCalendarProduct {
  id: number;
  pos_product_id: number;
  name: string;
  service_duration_hours: number;
  service_duration_minutes: number;
}

export interface ServiceCalendar {
  id: number;
  name: string;
  color: string;
  pos_location_id: number;
  end_date: string | null;
  deleted: boolean;
  service_calendar_services: ServiceCalendarProduct[];
}

export interface ServiceCalendarSchedule {
  id: number;
  day: number;
  enable: boolean;
  start_time: string;
  end_time: string;
  sort_order: number;
}

export interface ServiceCalendarException {
  id: number;
  date: string | null;
  all_day: boolean;
  start_time: string;
  end_time: string;
  start_date: string;
  end_date: string | null;
  exception_name: string;
  exception_type: ExceptionType;
}

export interface ServiceCalendarDetail {
  id: number;
  name: string;
  color: string;
  pos_location_id: number | null;
  end_date: string | null;
  deleted: boolean;
  service_calendar_schedules: ServiceCalendarSchedule[];
  service_calendar_services: ServiceCalendarProduct[];
  service_calendar_exceptions: ServiceCalendarException[];
}

export enum ServiceBookingStatus {
  Scheduled = 'scheduled',
  InProgress = 'in_progress',
  AwaitingCheckout = 'awaiting_checkout',
  Closed = 'closed',
  Cancelled = 'cancelled',
  NoShow = 'no_show',
  Voided = 'voided'
}

export enum PaymentStatus {
  Unpaid = 'unpaid',
  Paid = 'paid',
  PartialPaid = 'partial_paid'
}

export interface ServiceBooking {
  custom_occurrence_id?: number;
  id: number;
  diner_id: number | null;
  diner_name: string | null;
  pos_product_id: number;
  pos_product_name: string | null;
  service_calendar_id: number;
  product_calendar_color: string | null;
  start_time: string;
  end_time: string;
  status: ServiceBookingStatus;
  pos_ticket_id: string | null;
  notes: string;
  service_date: string;
  device_ticket_uuid: string | null;
  outstanding_balance: string | number | null;
  price: number;
  payment_status?: PaymentStatus;
  payment_date?: string;
  recurrence_rule_id: number;
  recurrence_type: RecurrenceType;
}

export interface CalendarEvent {
  id?: string;
  start?: string;
  startTime?: string; //eg: 11:00:00
  endTime?: string; //eg: 11:00:00
  end?: string;
  title?: string;
  resourceId: string;
  classNames?: string[];
  extendedProps?: { [key: string]: any };
  daysOfWeek?: string[];
  display?: 'background' | 'inverse-background';
  startRecur?: string;
  endRecur?: string | null;
}

export interface Resource {
  id: string;
  parentId: string;
  title: string;
  businessHours: EventStore | null;
  ui: EventUi;
  extendedProps: {
    [extendedProp: string]: any;
  };
}

export enum CustomOccurrenceChangeType {
  Rescheduled = 'rescheduled',
  Cancelled = 'cancelled'
}

export interface CustomOccurrenceDTO {
  original_date: string;
  booking_date: string;
  start_time: string;
  end_time: string;
  change_type: CustomOccurrenceChangeType;
  notes?: string;
}

export interface CustomOccurrence {
  id: number;
  service_booking_id: number;
  booking_date: string;
  original_date: string;
  start_time: string;
  end_time: string;
  change_type: CustomOccurrenceChangeType;
  created_at: string;
  updated_at: string;
  notes: string;
}

export type ResourceSourceInput = _.Dictionary<any>[];

export enum RecurrenceType {
  NO_REPEAT = 'no_repeat',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly'
}

export interface RecurrenceRuleAttributes {
  recurrence_type: RecurrenceType;
  repeats_every?: number;
  repeats_weekly_on?: (
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday'
  )[];
  repeats_monthly_on?: number;
  repeats_yearly_on?: {
    day: number;
    month: number;
    day_of_month: number;
  };
  ends_on?: string;
  ends_after?: number;
}

export interface CreateAppointmentBookingDTO {
  diner_id: number | null;
  notes: string;
  start_time: string;
  end_time: string;
  status: ServiceBookingStatus;
  service_calendar_id: number;
  service_date: string;
  pos_product_id: number;
  recurrence_rule_attributes: RecurrenceRuleAttributes;
}

export type UpdateAppointmentBookingDTO = Partial<ServiceBooking> &
  Required<Pick<ServiceBooking, 'id'>> & {
    local_attr_old_service_date?: string;
  };

export type LoadServiceBookingsFilter = Partial<{
  diner_id: number;
  diner_name: string;
  pos_product_ids: string;
  service_calendar_ids: string;
  date_from: string;
  date_to: string;
  status: ServiceBookingStatus;
  payment_statuses: string;
  upcoming_count?: number;
  past_count?: number;
}>;

export interface ConflictTime {
  date: string;
  time: string;
}

export enum ScheduleAdvancedDay {
  MON = 'mon',
  TUE = 'tue',
  WED = 'wed',
  THU = 'thu',
  FRI = 'fri',
  SAT = 'sat',
  SUN = 'sun'
}

export interface ScheduleAdvancedDayVariable {
  name: ScheduleAdvancedDay;
  abbr: string;
}

export interface ScheduleAdvancedForm {
  enabled: boolean;
  isAllowTwoRange: boolean;
  first_range: {
    id?: number;
    start_time: string;
    end_time: string;
  };
  second_range: {
    id?: number;
    start_time: string;
    end_time: string;
  };
}
