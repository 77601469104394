import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import {
  HardwareDeviceStateModel,
  HARDWARE_DEVICE_STATE_TOKEN
} from './hardware-device.model';
import { LoadHardwareDetailsSuccess } from 'src/app/store/app/app.action';
import { NotifyOldWebViewDevice } from './hardware-device.action';
import { CommonConstant } from 'src/app/constants';
import { HardwareDeviceService } from 'src/app/store/hardware-device/hardware-device.service';

@State<HardwareDeviceStateModel>({
  name: HARDWARE_DEVICE_STATE_TOKEN
})
@Injectable()
export class HardwareDeviceState {
  constructor(private readonly _deviceService: HardwareDeviceService) {}

  @Action(LoadHardwareDetailsSuccess)
  loadHardwareDetailsSuccess(
    ctx: StateContext<HardwareDeviceStateModel>,
    { posHardwareDetails }: LoadHardwareDetailsSuccess
  ) {
    ctx.patchState(posHardwareDetails);
  }

  @Action(NotifyOldWebViewDevice)
  notifyOldWebViewDevice({ getState }: StateContext<HardwareDeviceStateModel>) {
    return this._deviceService.supportAlerts({
      alert_type: 'webview_outdated',
      alert_message:
        'Webview version is ' +
        getState().deviceInfo.webViewVersion +
        ' minimum should be ' +
        CommonConstant.MIN_WEBVIEW_VERSION +
        '.0.0'
    });
  }
}
