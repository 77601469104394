export class ScaleConstant {
  static readonly OneGramMadeOf: { [Unit: string]: number } = {
    g: 1,
    gram: 1,
    oz: 0.035274,
    lb: 0.0022046,
    kg: 0.001
  };

  static readonly OneOzMadeOf: { [Unit: string]: number } = {
    g: 28.35,
    gram: 28.35,
    oz: 1,
    lb: 0.0625,
    kg: 0.02835
  };

  static readonly OneLbMadeOf: { [Unit: string]: number } = {
    g: 453.59,
    gram: 453.59,
    oz: 16,
    lb: 1,
    kg: 0.45359
  };

  static readonly OneKgMadeOf: { [Unit: string]: number } = {
    g: 1000,
    gram: 1000,
    oz: 35.274,
    lb: 2.2046,
    kg: 1
  };

  static readonly WeightUnitValue = {
    OnegramMadeOf: ScaleConstant.OneGramMadeOf,
    OnegMadeOf: ScaleConstant.OneGramMadeOf,
    OneozMadeOf: ScaleConstant.OneOzMadeOf,
    OneounceMadeOf: ScaleConstant.OneOzMadeOf,
    OnelbMadeOf: ScaleConstant.OneLbMadeOf,
    OnepoundMadeOf: ScaleConstant.OneLbMadeOf,
    OnekgMadeOf: ScaleConstant.OneKgMadeOf,
    OnekilogramMadeOf: ScaleConstant.OneKgMadeOf
  };
}
