import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';

@Injectable()
export abstract class BaseService {
  constructor(protected store: Store) {}

  getLocationUrl(url?: string, locationId?: number) {
    const defaultLocationId =
      this.store.selectSnapshot(APP_STATE_TOKEN).device?.pos_location_id;
    const locationUrl = `locations/${locationId || defaultLocationId}`;
    if (!url) {
      return locationUrl;
    }
    return `${locationUrl}/${url}`;
  }
}
