import {
  faHomeAlt,
  faCreditCard,
  faGiftCard,
  faMoneyCheck,
  faHandHoldingUsd
} from '@fortawesome/pro-light-svg-icons';
import _ from 'lodash';

import {
  PaymentMethodOption,
  PaymentRequestType,
  PaymentType
} from 'src/app/models';

export class PaymentConstant {
  static readonly PAYMENT_METHODS_MAP: _.Dictionary<PaymentMethodOption> = {
    [PaymentType.Cash]: {
      id: PaymentType.Cash,
      name: 'Cash',
      key: 'cash',
      faIcon: faMoneyCheck,
      self_service_readable_name: 'Cash'
    },
    [PaymentType.Credit]: {
      id: PaymentType.Credit,
      key: 'credit_card',
      name: 'Credit/Debit',
      faIcon: faCreditCard,
      self_service_readable_name: 'Credit Card'
    },
    [PaymentType.GiftCard]: {
      id: PaymentType.GiftCard,
      key: 'gift_card',
      name: 'Gift Card',
      faIcon: faGiftCard,
      self_service_readable_name: 'Gift Card'
    },
    [PaymentType.BillToRoom]: {
      id: PaymentType.BillToRoom,
      key: 'bill_to_room',
      name: 'Bill To Room',
      faIcon: faHomeAlt,
      self_service_readable_name: 'Charge To My Room'
    },
    [PaymentType.ChargeAccount]: {
      id: PaymentType.ChargeAccount,
      key: 'diner_charge_account',
      name: 'Charge Account',
      faIcon: faMoneyCheck,
      self_service_readable_name: 'Charge Account'
    },
    [PaymentType.ChargeToDepartment]: {
      id: PaymentType.ChargeToDepartment,
      name: 'Charge To Department',
      key: 'department_charge_account',
      faIcon: faMoneyCheck,
      self_service_readable_name: 'Charge To Department'
    },
    ['pay_on_pickup']: {
      id: 'pay_on_pickup',
      name: 'Pay at Pickup',
      faIcon: faHandHoldingUsd,
      key: null,
      self_service_readable_name: null
    }
  };

  static readonly PAYMENT_TYPE_MAP = {
    [PaymentType.Cash]: PaymentRequestType.CASH,
    [PaymentType.BillToRoom]: PaymentRequestType.BILL_TO_ROOM,
    [PaymentType.GiftCard]: PaymentRequestType.GIFT_CARD,
    [PaymentType.Credit]: PaymentRequestType.CREDIT_CARD,
    [PaymentType.CreditCardTrancloud]: PaymentRequestType.CREDIT_CARD_TRANCLOUD,
    [PaymentType.CreditCardDcDirect]: PaymentRequestType.CREDIT_CARD_DC_DIRECT,
    [PaymentType.ChargeAccount]: PaymentRequestType.CHARGE_ACCOUNT,
    [PaymentType.ChargeToDepartment]: PaymentRequestType.CHARGE_ACCOUNT
  };

  static readonly PAYMENT_REQUEST_TYPE_MAP: {
    [key in PaymentRequestType]: string;
  } = {
    [PaymentRequestType.CASH]: PaymentType.Cash,
    [PaymentRequestType.BILL_TO_ROOM]: PaymentType.BillToRoom,
    [PaymentRequestType.GIFT_CARD]: PaymentType.GiftCard,
    [PaymentRequestType.CREDIT_CARD]: PaymentType.Credit,
    [PaymentRequestType.CREDIT_CARD_TRANCLOUD]: PaymentType.Credit,
    [PaymentRequestType.CREDIT_CARD_DC_DIRECT]: PaymentType.Credit,
    [PaymentRequestType.CREDIT_CARD_STRIPE]: PaymentType.Credit,
    [PaymentRequestType.CHARGE_ACCOUNT]: PaymentType.ChargeAccount,
    [PaymentRequestType.ZERO_VALUE]: 'Zero Value'
  };

  static readonly SELF_SERVICE_PAYMENT_REQUEST_TYPE_TO_PAYMENT_METHOD_MAP: _.Dictionary<PaymentMethodOption> =
    {
      [PaymentRequestType.CASH]:
        PaymentConstant.PAYMENT_METHODS_MAP[PaymentType.Cash],
      [PaymentRequestType.BILL_TO_ROOM]:
        PaymentConstant.PAYMENT_METHODS_MAP[PaymentType.BillToRoom],
      [PaymentRequestType.GIFT_CARD]:
        PaymentConstant.PAYMENT_METHODS_MAP[PaymentType.GiftCard],
      [PaymentRequestType.CREDIT_CARD]:
        PaymentConstant.PAYMENT_METHODS_MAP[PaymentType.Credit],
      [PaymentRequestType.CREDIT_CARD_TRANCLOUD]:
        PaymentConstant.PAYMENT_METHODS_MAP[PaymentType.Credit],
      [PaymentRequestType.CREDIT_CARD_DC_DIRECT]:
        PaymentConstant.PAYMENT_METHODS_MAP[PaymentType.Credit],
      [PaymentRequestType.CREDIT_CARD_STRIPE]:
        PaymentConstant.PAYMENT_METHODS_MAP[PaymentType.Credit],
      [PaymentRequestType.CHARGE_ACCOUNT]:
        PaymentConstant.PAYMENT_METHODS_MAP[PaymentType.ChargeAccount]
    };
}
