import { BarcodeReaderEventType, BarcodeResult } from '../models';
import {
  Observable,
  Subscription,
  debounceTime,
  filter,
  fromEvent,
  of,
  scan,
  switchMap,
  tap,
  withLatestFrom,
  timeInterval
} from 'rxjs';
import { Injectable } from '@angular/core';

const KEY_EVENTS = [
  'backspace',
  'unidentified',
  'delete',
  'arrowleft',
  'arrowright',
  'shift',
  'shiftleft',
  'shiftright'
];

// this array has order. The longer start with string must placed before the short one
const SPECIAL_READER_REGEX = [
  {
    // Mag stripe reader regex
    startWith: '%B',
    regexString: /(?![%B]|[\^\^]|[\?;E\?])([\w\-]+)/g
  },
  {
    // DEF-2885 - Check specific client cards with additional % symbol at the beginning and ? at the end
    startWith: '%',
    regexString: /(?![%]|[\^\^]|[\?])([\w\-]+)/g
  },
  {
    //POSV3-1011 support pattern start with ; and ? at the end
    startWith: ';',
    regexString: /(?![;]|[\^\^]|[\?])([\w\-]+)/g
  }
];

@Injectable({
  providedIn: 'root'
})
export class BarcodeProvider {
  scanned: Observable<BarcodeResult>;
  activeBarcodeEventTypes: BarcodeReaderEventType[] = [];

  constructor() {}

  startBarcodeReader() {
    const down = fromEvent<KeyboardEvent>(document, 'keydown');
    const up = fromEvent<KeyboardEvent>(document, 'keyup');
    const scannerKeys = up.pipe(
      filter(
        (e: KeyboardEvent) =>
          !KEY_EVENTS.includes(e.key.toLocaleLowerCase()) &&
          e.code !== 'Space' &&
          e.code !== 'Backspace'
      ),
      withLatestFrom(down, (u: KeyboardEvent, d: KeyboardEvent) => ({
        key: `${d.key}`,
        pressTime: u.timeStamp - d.timeStamp,
        e: u
      }))
    );
    this.activeBarcodeEventTypes = [];

    this.scanned = scannerKeys.pipe(
      timeInterval(), //This tracks time interval between each key press
      scan((acc, { value, interval }) => {
        //If key is not enter and key press time is less than 30ms then it is a valid key
        if (value.key !== 'Enter' && value.pressTime < 30) {
          //If interval between the last key and current key is greater than 600ms. It's a new barcode. Otherwise add it to the existing barcode string
          if (interval > 600) {
            return value.key;
          } else {
            return acc + value.key;
          }
        }
        return '';
      }, ''),
      filter((barcode) => barcode !== '' && barcode?.length >= 2),
      debounceTime(50),
      switchMap((barcode) => {
        //Mag Stripe Scanner reading failed OR success
        if (barcode === '%E?;E?') {
          barcode = null;
        } else {
          let hasAnyStartWithMatch = false;
          let hasRegexMatch = false;
          SPECIAL_READER_REGEX.every((regexPattern) => {
            if (barcode.startsWith(regexPattern.startWith)) {
              hasAnyStartWithMatch = true;
              const matches = barcode.match(regexPattern.regexString);
              if (matches && matches.length) {
                hasRegexMatch = true;
                console.log(
                  '[Barcode Provider] Barcode Match pattern: ',
                  regexPattern.regexString
                );
                console.log(
                  `Original barcode ${barcode} is replaced by ${matches[0]}`
                );
                barcode = matches[0];
                return false;
              }
            }
            return true;
          });
          if (hasAnyStartWithMatch && !hasRegexMatch) {
            // incase the barcode has any start with match but doesn't have regex match, then it should be null
            barcode = null;
          }
        }

        return of({
          barcode,
          type:
            this.activeBarcodeEventTypes && this.activeBarcodeEventTypes.length
              ? this.activeBarcodeEventTypes[
                  this.activeBarcodeEventTypes.length - 1
                ]
              : BarcodeReaderEventType.PRODUCT
        });
      })
    );
  }

  subscribe(
    barcodeEventType: BarcodeReaderEventType,
    fn: (barcode: BarcodeResult) => void
  ) {
    this.activeBarcodeEventTypes.push(barcodeEventType);
    return this.barcodeHandler(barcodeEventType, fn);
  }

  private barcodeHandler(barcodeEventType: BarcodeReaderEventType, fn) {
    return this.scanned
      .pipe(
        filter(
          (response) =>
            response.type === barcodeEventType && response.barcode !== null
        ),
        tap((barcode) =>
          console.log('[Barcode Provider] Barcode Scanned: ', barcode)
        )
      )
      .subscribe(fn);
  }

  unsubscribe(
    subscription: Subscription,
    barcodeEventType?: BarcodeReaderEventType
  ) {
    if (barcodeEventType) {
      this.activeBarcodeEventTypes = this.activeBarcodeEventTypes.filter(
        (type) => type !== barcodeEventType
      );
    } else {
      this.activeBarcodeEventTypes.pop();
    }
    subscription.unsubscribe();
  }

  handleRfidReaderLogEvent(event) {
    console.log('Handle Rfid Reader Log Event: ', event.data);
  }
}
