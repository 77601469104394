import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

export interface SskButton {
  text: string;
  fill?: 'clear' | 'default' | 'outline' | 'solid';
  color?: string;
}

@Component({
  selector: 'pos-ssk-confirm-modal',
  templateUrl: './ssk-confirm.modal.html',
  styleUrls: ['./ssk-confirm.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SskConfirmModal {
  @Input() description?: string;
  @Input() title?: string;
  @Input() yesBtn: SskButton = { text: 'Yes' };
  @Input() noBtn: SskButton = { text: 'No' };

  constructor(public readonly modalCtrl: ModalController) {}
}
