import {
  Action,
  State,
  StateContext,
  StateToken,
  createSelector
} from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { produce } from 'immer';
import { HttpErrorResponse } from '@angular/common/http';

import {
  CreatePOSService,
  CreatePOSServiceSuccess,
  DeletePOSService,
  DeletePOSServiceError,
  DeletePOSServiceSuccess,
  GetServices,
  SelectServiceDetail,
  SetPOSServiceLoaded,
  UpdatePOSService,
  UpdatePOSServiceSuccess
} from './pos-services.action';
import {
  ExceptionError,
  HttpRequestError
} from 'src/app/store/error/error.action';
import { PosManagerServiceService } from 'src/app/services/pos-manager/pos-services-manager/pos-services.service';
import { POSManagerService } from 'src/app/models/pos-manager.model';
import { StateObject } from 'src/app/models';
import {
  insertItem,
  patch,
  removeItem,
  updateItem
} from '@ngxs/store/operators';

export interface PosManagerServiceStateModel {
  services: StateObject<POSManagerService[]> & { isDeleting: boolean };
  selectedServiceId: number;
}

export const POS_MANAGER_SERVICE_STATE_TOKEN =
  new StateToken<PosManagerServiceStateModel>('pos_manager_service');

@State({
  name: POS_MANAGER_SERVICE_STATE_TOKEN,
  defaults: {
    services: { data: [], isLoaded: false, isDeleting: false },
    selectedServiceId: null
  }
})
@Injectable()
export class PosManagerServiceState {
  constructor(
    private readonly posManagerServiceService: PosManagerServiceService
  ) {}

  @Action(GetServices)
  getServices(ctx: StateContext<PosManagerServiceStateModel>) {
    ctx.setState(
      patch({
        services: patch({
          isLoaded: false
        })
      })
    );
    return this.posManagerServiceService.getServices().pipe(
      tap((services) =>
        ctx.setState(
          patch({
            selectedServiceId: null,
            services: patch({
              data: services.sort((a, b) => a.name.localeCompare(b.name))
            })
          })
        )
      ),
      catchError((e) => {
        ctx.dispatch(
          new HttpRequestError(
            'There was an error while fetching POS Manager Services. Please exit POS Manager and re-enter. If the problem persists, please call support.'
          )
        );
        throw e;
      }),
      finalize(() => {
        ctx.setState(
          patch({
            services: patch({
              isLoaded: true
            })
          })
        );
      })
    );
  }

  @Action(SelectServiceDetail)
  selectService(
    ctx: StateContext<PosManagerServiceStateModel>,
    { serviceId }: SelectServiceDetail
  ) {
    ctx.setState(
      produce((draft: PosManagerServiceStateModel) => {
        draft.selectedServiceId = serviceId;
      })
    );
  }

  @Action(SetPOSServiceLoaded)
  setIsLoaded(
    ctx: StateContext<PosManagerServiceStateModel>,
    { isLoaded }: SetPOSServiceLoaded
  ) {
    ctx.setState(
      patch({
        services: patch({
          isLoaded
        })
      })
    );
  }

  @Action(CreatePOSService)
  createPOSService(
    ctx: StateContext<PosManagerServiceStateModel>,
    { service }: CreatePOSService
  ) {
    ctx.setState(
      patch({
        services: patch({ isLoaded: false })
      })
    );
    return this.posManagerServiceService.createService(service).pipe(
      tap((newService) => {
        ctx.setState(
          patch({
            services: patch({
              data: insertItem(newService)
            })
          })
        );

        ctx.dispatch(new CreatePOSServiceSuccess(newService));
      }),
      catchError((e) => {
        ctx.dispatch(
          new ExceptionError(`There was an error while creating service`)
        );
        throw e;
      }),
      finalize(() => {
        ctx.setState(
          patch({
            services: patch({
              isLoaded: true
            })
          })
        );
      })
    );
  }

  @Action(UpdatePOSService)
  updatePOSService(
    ctx: StateContext<PosManagerServiceStateModel>,
    { id, service }: UpdatePOSService
  ) {
    ctx.setState(
      patch({
        services: patch({ isLoaded: false })
      })
    );
    return this.posManagerServiceService.updateService(id, service).pipe(
      tap(async (updatedProduct) => {
        ctx.setState(
          patch({
            services: patch({
              data: updateItem((i) => i.id === id, updatedProduct)
            })
          })
        );
        ctx.dispatch(new UpdatePOSServiceSuccess());
      }),
      catchError((e) => {
        ctx.dispatch(
          new ExceptionError(
            `There was an error while updating service id ${id}`
          )
        );
        throw e;
      }),
      finalize(() => {
        ctx.setState(
          patch({
            services: patch({ isLoaded: true })
          })
        );
      })
    );
  }

  @Action(DeletePOSService)
  deletePOSService(
    ctx: StateContext<PosManagerServiceStateModel>,
    { id }: DeletePOSService
  ) {
    ctx.setState(
      patch({
        services: patch({ isDeleting: true })
      })
    );
    return this.posManagerServiceService.deleteService(id).pipe(
      tap(async (success) => {
        if (success) {
          ctx.setState(
            patch({
              services: patch({
                data: removeItem((i) => i.id === id)
              })
            })
          );

          ctx.dispatch(new DeletePOSServiceSuccess());
        }
      }),
      catchError((error: HttpErrorResponse) =>
        ctx.dispatch(new DeletePOSServiceError(error))
      ),
      finalize(() =>
        ctx.setState(
          patch({
            services: patch({ isDeleting: false })
          })
        )
      )
    );
  }

  @Action(DeletePOSServiceError)
  deletePOSServiceError(
    ctx: StateContext<PosManagerServiceStateModel>,
    { error }: DeletePOSServiceError
  ) {
    return ctx.dispatch(new HttpRequestError(error.error.error));
  }
}
