import { createSelector } from '@ngxs/store';

import { SeatsStateModel, SeatsState } from './seats.state';

export class SeatsStateSelectors {
  private static selectedSeatId = createSelector(
    [SeatsState],
    (state: SeatsStateModel) => state.selectedSeat
  );

  static seatsById = createSelector(
    [SeatsState],
    (state: SeatsStateModel) => state.seats.byId
  );

  static getSelectedSeatId = createSelector(
    [SeatsStateSelectors.selectedSeatId],
    (seatId) => seatId
  );

  static getSeatById = createSelector(
    [SeatsStateSelectors.seatsById],
    (seatsById) => (seatId: number) => seatsById[seatId]
  );

  static isSeatSelected = createSelector(
    [SeatsStateSelectors.getSelectedSeatId],
    (selectedSeatId) => !!selectedSeatId
  );
}
