import { PosTime } from './time.model';

export enum TimeSlotDisplayMode {
  RANGE_START = 'range_start',
  HOUR_START = 'hour_start',
  DEFAULT = 'default'
}

export enum TimeSlotRangeType {
  BREAKFAST = 'breakfast',
  LUNCH = 'lunch',
  DINER = 'diner',
  DEFAULT = 'default_hour'
}

export interface ReservationTimeSlotRange {
  startTime: string;
  endTime: string;
  max_capacity_total?: number;
  total_number_of_seats?: number;
  default_reservation?: number;
}

export interface ReservationTimeSlotConfig extends ReservationTimeSlotRange {
  breakfast?: ReservationTimeSlotRange;
  lunch?: ReservationTimeSlotRange;
  dinner?: ReservationTimeSlotRange;
}

export interface ReservationTimeSlot {
  time: PosTime;
  date: string;
  displayMode: TimeSlotDisplayMode;
  rangeType: TimeSlotRangeType;
  max_capacity_total: number;
  max_seatings: number;
  seats_reserved: number;
  number_available_tables: number;
}
