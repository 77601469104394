<ion-button shape="round"
            #btn
            [fill]="fill"
            [color]="color"
            [expand]="expand"
            [class.border-radius-0]="isNotRound"
            [class.fixed-btn]="fixedButton"
            [disabled]="disabled"
            debounce-click
            [debounceTime]="0"
            (debounceClick)="onClick.emit(btn)">
  <div class="button-inner">
    <ion-icon *ngIf="!!iconName" [name]="iconName" color="dark-blue"></ion-icon>
    <fa-icon *ngIf="!!faIcon" [icon]="faIcon" style="color: #61666A;"></fa-icon>
    <span class="ion-text-capitalize" [innerHTML]="btnLabel"></span>
  </div>
</ion-button>