import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfirmModal } from './confirm.modal';
import { IonicModule } from '@ionic/angular';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [ConfirmModal],
  imports: [
    CommonModule,
    IonicModule,
    DebounceClickDirectiveModule,
    FontAwesomeModule
  ],
  exports: [ConfirmModal]
})
export class ConfirmModalModule {}
