import { Injectable } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { Action, State, StateContext, Store } from '@ngxs/store';
import {
  POSMenu,
  POSModifierPromptAssignment,
  POSProduct,
  POSProductEditDto,
  PosQuantityRestriction,
  PriceBookItemType,
  TaxAttributes
} from 'src/app/models/pos-manager.model';
import {
  ModalService,
  PosManagerService,
  PosMenuService
} from 'src/app/services';
import { produce } from 'immer';
import _ from 'lodash';
import {
  CreatePOSProduct,
  CreatePOSProductError,
  CreatePOSProductSuccess,
  CreateProductQuantityRestriction,
  PatchProductQuantityRestrictionsSuccess,
  DeletePOSProduct,
  DeleteProductQuantityRestriction,
  GetPOSMenus,
  GetPOSMenusSuccess,
  SelectMenuItem,
  SelectPOSProduct,
  SetPriceBookForItem,
  UpdateMenuItem,
  UpdateMenuItemSuccess,
  UpdatePOSProduct,
  UpdatePOSProductSuccess,
  UpdateProductQuantityRestriction,
  CreateProductQuantityRestrictionSuccess,
  UpdateProductQuantityRestrictionSuccess,
  DeleteProductQuantityRestrictionSuccess
} from './pos-menu.action';
import {
  catchError,
  concat,
  finalize,
  forkJoin,
  lastValueFrom,
  map,
  Observable,
  switchMap,
  tap,
  throwError
} from 'rxjs';
import { ExceptionError, HttpRequestError } from '../../error/error.action';
import { PosProductService } from 'src/app/services/pos-manager/pos-item/pos-product.service';
import { PageConstant } from 'src/app/constants';
import { PosModifierPromptAssignmentService } from 'src/app/services/pos-manager/pos-item/pos-modifier-prompt-assignment.service';
import { LocationSelectors } from 'src/app/store/location/location.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { StateObject } from 'src/app/models';
import { POS_MANAGER_STATE_TOKEN } from 'src/app/store/pos-manager';

export interface PosManagerMenuStateModel {
  fullMenu: {
    loaded: boolean;
    menuItems: POSMenu[];
    aaMenuItems: POSMenu[];
    products: POSProduct[];
    selectedMenuItemId: number;
    selectedProductId: number;
  };
  quantity_restrictions: StateObject<PosQuantityRestriction[]>;
}

@State<PosManagerMenuStateModel>({
  name: 'posMenu',
  defaults: {
    fullMenu: {
      loaded: false,
      menuItems: [],
      aaMenuItems: [],
      products: [],
      selectedMenuItemId: null,
      selectedProductId: null
    },
    quantity_restrictions: {
      data: [],
      isLoaded: false,
      isProcessing: false
    }
  }
})
@Injectable({ providedIn: 'root' })
export class PosManagerMenuState {
  constructor(
    private posMenuService: PosMenuService,
    private posProductService: PosProductService,
    private posModifierPromptAssignmentService: PosModifierPromptAssignmentService,
    private navController: NavController,
    private toastController: ToastController,
    private modalService: ModalService,
    private readonly posManagerService: PosManagerService,
    private readonly store: Store
  ) {}

  @Action(GetPOSMenus)
  getPOSMenus(
    ctx: StateContext<PosManagerMenuStateModel>,
    { request }: GetPOSMenus
  ) {
    const state = ctx.getState();
    //Set back to not loaded if loading again
    if (state.fullMenu.loaded) {
      ctx.setState(
        produce((draft: PosManagerMenuStateModel) => {
          draft.fullMenu.menuItems = [];
          draft.fullMenu.aaMenuItems = [];
          draft.fullMenu.products = [];
          draft.fullMenu.selectedMenuItemId = null;
          draft.fullMenu.selectedProductId = null;
          draft.fullMenu.loaded = false;
        })
      );
    }

    return forkJoin([
      this.posMenuService.getMenus(request),
      this.posManagerService.getQuantityRestrictions(request.date)
    ]).pipe(
      switchMap(([fullMenu, qtyRestrictions]) =>
        ctx.dispatch(new GetPOSMenusSuccess(fullMenu, qtyRestrictions))
      ),
      catchError(() =>
        ctx.dispatch(
          new HttpRequestError(
            'There was an error while fetching Menus. Please try again or contact support.'
          )
        )
      )
    );
  }

  @Action(GetPOSMenusSuccess)
  getPOSMenusSuccess(
    ctx: StateContext<PosManagerMenuStateModel>,
    { menu, qtyRestrictions }: GetPOSMenusSuccess
  ) {
    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        draft.fullMenu.loaded = true;
        draft.fullMenu.menuItems = menu?.menu_items || [];
        draft.fullMenu.aaMenuItems = menu?.also_available_menu_items || [];
        draft.fullMenu.products = menu?.products || [];
        draft.quantity_restrictions.data = qtyRestrictions;
      })
    );
  }

  @Action(SelectMenuItem)
  selectMenuItem(
    ctx: StateContext<PosManagerMenuStateModel>,
    { menuItemId }: SelectMenuItem
  ) {
    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        draft.fullMenu.selectedMenuItemId = menuItemId;
      })
    );
  }

  @Action(SelectPOSProduct)
  selectPOSProduct(
    ctx: StateContext<PosManagerMenuStateModel>,
    { id }: SelectPOSProduct
  ) {
    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        draft.fullMenu.selectedProductId = id;
      })
    );
  }

  @Action(CreatePOSProduct)
  async createPOSProduct(
    ctx: StateContext<PosManagerMenuStateModel>,
    { product, locationId }: CreatePOSProduct
  ) {
    await this.modalService.showLoadingModal('Creating...');
    if (product.taxes) {
      product.tax_settings_attributes = product.taxes.map(
        (taxId) =>
          ({
            pos_tax_setting_id: taxId
          } as TaxAttributes)
      );
      product.taxes = [];
    }
    return this.posProductService
      .createProduct(_.omit(_.cloneDeep(product), ['price_type', 'price']))
      .pipe(
        switchMap((newProduct) => {
          const locations = this.store.selectSnapshot(POS_MANAGER_STATE_TOKEN)
            .sharedObjects.pos_locations;

          return ctx
            .dispatch(
              new SetPriceBookForItem({
                itemId: newProduct.id,
                priceBookId:
                  locations.find((item) => item.id === locationId)
                    ?.price_book_id ||
                  this.store.selectSnapshot(LocationSelectors.getPriceBookId),
                price_type: product.price_type,
                price: product.price,
                itemType: PriceBookItemType.Product
              })
            )
            .pipe(
              map(() => ({
                ...newProduct,
                price_type: product.price_type,
                price: product.price
              }))
            );
        }),
        tap((newProduct) =>
          ctx.dispatch(new CreatePOSProductSuccess(newProduct))
        ),
        catchError((error: HttpErrorResponse) =>
          ctx.dispatch(new CreatePOSProductError(String(error.error)))
        ),
        finalize(() => this.modalService.closeLoadingModal())
      );
  }

  @Action(SetPriceBookForItem)
  setPriceBookForProduct(
    _ctx: StateContext<PosManagerMenuStateModel>,
    { payload }: SetPriceBookForItem
  ) {
    return this.posManagerService.setPriceBook(payload);
  }

  @Action(CreatePOSProductSuccess)
  async createPOSProductSuccess(
    ctx: StateContext<PosManagerMenuStateModel>,
    { newProduct }: CreatePOSProductSuccess
  ) {
    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        draft.fullMenu.products.push(newProduct);
      })
    );
    await this.navController.navigateRoot(
      PageConstant.POS_MANAGER_POS_ITEMS_MANAGEMENT_PAGE +
        '/product/' +
        newProduct.id
    );
    this.toastController
      .create({
        color: 'primary',
        duration: 2000,
        message: 'Product saved successfully.',
        position: 'top'
      })
      .then((toast) => toast.present());
  }

  @Action(UpdatePOSProduct)
  async updatePOSProduct(
    ctx: StateContext<PosManagerMenuStateModel>,
    {
      id,
      locationId,
      product,
      assignments,
      deletedAssignmentIds,
      initialTaxState
    }: UpdatePOSProduct
  ) {
    await this.modalService.showLoadingModal('Saving...');

    const selectedProduct = ctx
      .getState()
      .fullMenu.products.find((i) => i.id === id);
    const isPriceUpdated =
      selectedProduct.price_type !== product.price_type ||
      selectedProduct.price !== product.price;
    if (deletedAssignmentIds?.length) {
      for (const assignmentId of deletedAssignmentIds) {
        await lastValueFrom(
          this.posModifierPromptAssignmentService.deleteAssignment(assignmentId)
        );
      }
    }
    if (assignments?.length) {
      const updateAssignments = _.sortBy(assignments, 'sort_order');

      if (updateAssignments?.length) {
        for (const assignment of updateAssignments) {
          if (assignment.id) {
            await lastValueFrom(
              this.posModifierPromptAssignmentService.updateAssignment(
                assignment.id,
                assignment
              )
            );
          } else {
            await lastValueFrom(
              this.posModifierPromptAssignmentService.createAssignment(
                assignment
              )
            );
          }
        }
      }
    }

    const locations = this.store.selectSnapshot(POS_MANAGER_STATE_TOKEN)
      .sharedObjects.pos_locations;

    product.initialTaxState = initialTaxState;

    return ctx
      .dispatch(
        new SetPriceBookForItem({
          priceBookId:
            locations.find((item) => item.id === locationId)?.price_book_id ||
            this.store.selectSnapshot(LocationSelectors.getPriceBookId),
          itemId: id,
          price_type: product.price_type,
          price: product.price,
          itemType: PriceBookItemType.Product
        })
      )
      .pipe(
        switchMap(() =>
          this.posProductService.updateProduct(
            id,
            this.taxDataPreProcessing(product)
          )
        ),
        tap((updatedProduct) => {
          ctx.dispatch(
            new UpdatePOSProductSuccess(
              {
                ...updatedProduct,
                price_type: product.price_type,
                price: product.price
              },
              isPriceUpdated
            )
          );
        }),
        catchError(() => {
          this.modalService.closeLoadingModal();
          return ctx.dispatch(
            new ExceptionError(
              `There was an error while updating product id ${id}`
            )
          );
        })
      );
  }

  private taxDataPreProcessing(
    data: Partial<POSProductEditDto>
  ): Partial<POSProductEditDto> {
    const { initialTaxState, taxes } = data;
    const initialTaxes = initialTaxState.map((x) => x.pos_tax_setting_id);
    const removeTaxes = _.difference(initialTaxes, taxes || []).map(
      (taxId) => ({
        id: initialTaxState.find((x) => x.pos_tax_setting_id === taxId).id,
        pos_tax_setting_id: taxId,
        _destroy: true
      })
    );
    const addedTaxes = _.difference(taxes || [], initialTaxes).map((taxId) => ({
      pos_tax_setting_id: taxId
    }));
    data.tax_settings_attributes = [...removeTaxes, ...addedTaxes];
    delete data.initialTaxState;
    return data;
  }

  @Action(UpdatePOSProductSuccess)
  async updatePOSProductSuccess(
    ctx: StateContext<PosManagerMenuStateModel>,
    { updatedProduct, isPriceUpdated }: UpdatePOSProductSuccess
  ) {
    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        const index = _.findIndex(
          draft.fullMenu.products,
          (x) => x.id === updatedProduct.id
        );
        if (index > -1) {
          draft.fullMenu.products[index] = {
            ...draft.fullMenu.products[index],
            ...updatedProduct
          };
        }
      })
    );

    await this.modalService.closeLoadingModal();

    const toast = await this.toastController.create({
      color: 'primary',
      duration: 2000,
      message:
        'Product saved successfully.' +
        (isPriceUpdated
          ? ' Please re-login for the price to update on the Menu.'
          : ''),
      position: 'top'
    });

    await toast.present();
  }

  @Action(DeletePOSProduct)
  deletePOSProduct(
    ctx: StateContext<PosManagerMenuStateModel>,
    { id }: DeletePOSProduct
  ) {
    return this.posProductService.deleteProduct(id).pipe(
      tap(async (success) => {
        if (success) {
          ctx.setState(
            produce((draft: PosManagerMenuStateModel) => {
              draft.fullMenu.products = draft.fullMenu.products.filter(
                (p) => p.id !== id
              );
            })
          );

          await this.navController.navigateForward(
            PageConstant.POS_MANAGER_POS_ITEMS_MANAGEMENT_PAGE
          );
        }
      }),
      catchError(() =>
        ctx.dispatch(
          new ExceptionError(
            `There was an error while deleting product id ${id}`
          )
        )
      )
    );
  }

  @Action(UpdateMenuItem)
  updateMenuItem(
    ctx: StateContext<PosManagerMenuStateModel>,
    action: UpdateMenuItem
  ) {
    const results: POSModifierPromptAssignment[] = [];
    const deletedAssignmentIds = action.payload.deletedAssignmentIds;
    const payload = action.payload;
    const assignments = action.payload.assignments;
    const state = ctx.getState();
    const fullMenu = state.fullMenu;
    const prevSelectedMenuItem = _.concat(
      fullMenu.menuItems || [],
      fullMenu.aaMenuItems || []
    ).find(
      (menuItem) => menuItem.menu_item_id === state.fullMenu.selectedMenuItemId
    );

    const isPriceUpdated: boolean =
      prevSelectedMenuItem.price_type !== payload.priceType ||
      prevSelectedMenuItem.price !== payload.price;

    this.modalService.showLoadingModal('Saving...');

    const deletedActions$: Observable<boolean>[] = [];
    const createOrUpdateActions$: Observable<POSModifierPromptAssignment>[] =
      [];

    const locations = this.store.selectSnapshot(POS_MANAGER_STATE_TOKEN)
      .sharedObjects.pos_locations;

    const updateAssignments = _.sortBy(assignments, 'sort_order');

    if (deletedAssignmentIds?.length) {
      for (const assignmentId of deletedAssignmentIds) {
        deletedActions$.push(
          this.posModifierPromptAssignmentService.deleteAssignment(assignmentId)
        );
      }
    }

    if (updateAssignments?.length) {
      for (const assignment of updateAssignments) {
        createOrUpdateActions$.push(
          assignment.id
            ? this.posModifierPromptAssignmentService.updateAssignment(
                assignment.id,
                assignment
              )
            : this.posModifierPromptAssignmentService.createAssignment(
                assignment
              )
        );
      }
    }

    return concat(
      ...deletedActions$,
      ...createOrUpdateActions$.map((actions$) =>
        actions$.pipe(tap((res) => results.push(res)))
      )
    ).pipe(
      finalize(() =>
        ctx
          .dispatch(
            new SetPriceBookForItem({
              priceBookId:
                locations.find((item) => item.id === action.payload.locationId)
                  ?.price_book_id ||
                this.store.selectSnapshot(LocationSelectors.getPriceBookId),
              itemId: action.payload.menuItemId,
              price_type: action.payload.priceType,
              price: action.payload.price,
              itemType: action.payload.itemType
            })
          )
          .subscribe(() =>
            ctx.dispatch(
              new UpdateMenuItemSuccess(isPriceUpdated, action, results)
            )
          )
      )
    );
  }

  @Action(UpdateMenuItemSuccess)
  updateMenuItemSuccess(
    ctx: StateContext<PosManagerMenuStateModel>,
    { isPriceUpdated, data, results }: UpdateMenuItemSuccess
  ) {
    const payload = data.payload;
    const menuItemId = ctx.getState().fullMenu.selectedMenuItemId;

    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        const index = _.findIndex(
          draft.fullMenu.menuItems,
          (x) => x.menu_item_id === menuItemId
        );

        const aaindex = _.findIndex(
          draft.fullMenu.aaMenuItems,
          (x) => x.menu_item_id === menuItemId
        );

        if (index > -1) {
          draft.fullMenu.menuItems[index].price = payload.price;
          draft.fullMenu.menuItems[index].price_type = payload.priceType;

          // keep the modifier_prompt_assignments for category
          const categoryModifierPrompts = draft.fullMenu.menuItems[
            index
          ].modifier_prompt_assignments?.filter((x) => x.category_id);
          draft.fullMenu.menuItems[index].modifier_prompt_assignments = [
            ...categoryModifierPrompts,
            ...results
          ];
        }

        if (aaindex > -1) {
          draft.fullMenu.aaMenuItems[aaindex].price = payload.price;
          draft.fullMenu.aaMenuItems[aaindex].price_type = payload.priceType;

          const categoryModifierPrompts = draft.fullMenu.aaMenuItems[
            aaindex
          ].modifier_prompt_assignments?.filter((x) => x.category_id);
          draft.fullMenu.aaMenuItems[aaindex].modifier_prompt_assignments = [
            ...categoryModifierPrompts,
            ...results
          ];
        }
      })
    );

    this.modalService.closeLoadingModal().then(() => {
      this.toastController
        .create({
          color: 'primary',
          duration: 2000,
          message:
            'Menu item saved successfully.' +
            (isPriceUpdated
              ? ' Please re-login for the price to update on the Menu.'
              : ''),
          position: 'top'
        })
        .then((toast) => toast.present());
    });
  }

  @Action(CreateProductQuantityRestriction)
  createProductQuantityRestrictions(
    ctx: StateContext<PosManagerMenuStateModel>,
    { restrictions }: CreateProductQuantityRestriction
  ) {
    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        draft.quantity_restrictions.isProcessing = true;
      })
    );
    return this.posManagerService.createQuantityRestriction(restrictions).pipe(
      switchMap((restriction) =>
        ctx.dispatch(new CreateProductQuantityRestrictionSuccess(restriction))
      ),
      catchError(() =>
        ctx.dispatch(
          new ExceptionError(
            'There was an error while creating quantity restrictions'
          )
        )
      )
    );
  }

  @Action(CreateProductQuantityRestrictionSuccess)
  createProductQuantityRestrictionSuccess(
    ctx: StateContext<PosManagerMenuStateModel>,
    action: CreateProductQuantityRestrictionSuccess
  ) {
    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        draft.quantity_restrictions.data = [
          ...draft.quantity_restrictions.data,
          action.restriction
        ];
      })
    );
    ctx.dispatch(new PatchProductQuantityRestrictionsSuccess());
  }

  @Action(UpdateProductQuantityRestriction)
  updateProductQuantityRestrictions(
    ctx: StateContext<PosManagerMenuStateModel>,
    { restrictions }: UpdateProductQuantityRestriction
  ) {
    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        draft.quantity_restrictions.isProcessing = true;
      })
    );

    return this.posManagerService.updateQuantityRestriction(restrictions).pipe(
      switchMap((restriction) =>
        ctx.dispatch(new UpdateProductQuantityRestrictionSuccess(restriction))
      ),
      catchError(() =>
        ctx.dispatch(
          new ExceptionError(
            'There was an error while updating quantity restrictions'
          )
        )
      )
    );
  }

  @Action(UpdateProductQuantityRestrictionSuccess)
  updateProductQuantityRestrictionSuccess(
    ctx: StateContext<PosManagerMenuStateModel>,
    action: UpdateProductQuantityRestrictionSuccess
  ) {
    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        const index = _.findIndex(
          draft.quantity_restrictions.data,
          (x) => x.id === action.restriction.id
        );
        if (index > -1) {
          draft.quantity_restrictions.data[index] = action.restriction;
        }
      })
    );
    ctx.dispatch(new PatchProductQuantityRestrictionsSuccess());
  }

  @Action(DeleteProductQuantityRestriction)
  deleteProductQuantityRestrictions(
    ctx: StateContext<PosManagerMenuStateModel>,
    { restrictionId }: DeleteProductQuantityRestriction
  ) {
    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        draft.quantity_restrictions.isProcessing = true;
      })
    );
    return this.posManagerService.deleteQuantityRestriction(restrictionId).pipe(
      switchMap((success) =>
        success
          ? ctx.dispatch(
              new DeleteProductQuantityRestrictionSuccess(restrictionId)
            )
          : throwError(() => new Error('Failed to delete quantity restriction'))
      ),
      catchError(() =>
        ctx.dispatch(
          new ExceptionError(
            'There was an error while deleting quantity restrictions'
          )
        )
      )
    );
  }

  @Action(DeleteProductQuantityRestrictionSuccess)
  deleteProductQuantityRestrictionSuccess(
    ctx: StateContext<PosManagerMenuStateModel>,
    action: DeleteProductQuantityRestrictionSuccess
  ) {
    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        draft.quantity_restrictions.data =
          draft.quantity_restrictions.data.filter(
            (x) => x.id !== action.restrictionId
          );
      })
    );
    ctx.dispatch(new PatchProductQuantityRestrictionsSuccess());
  }

  @Action(PatchProductQuantityRestrictionsSuccess)
  patchProductQuantityRestrictionsSuccess(
    ctx: StateContext<PosManagerMenuStateModel>
  ) {
    ctx.setState(
      produce((draft: PosManagerMenuStateModel) => {
        draft.quantity_restrictions.isProcessing = false;
      })
    );
  }
}
