<div class="pos-ssk-warning-modal">
    <div class="pos-ssk-warning-modal-wrapper">
        <h1 *ngIf="title" class="pos-ssk-warning-modal-header">
            {{title}}
        </h1>

        <div class="pos-manager-confirm-modal-content">
            <p class="ion-text-center" *ngIf="description">
                {{description}}
            </p>
        </div>

        <div class="custom-flex-row flex-gap-px-10 pos-ssk-warning-modal-wrapper__button-container">
            <pos-ssk-round-button [btnLabel]="yesBtn.text"
                                  [color]="yesBtn.color"
                                  [fill]="yesBtn.fill"
                                  expand="block" [fixedButton]="false"
                                  (onClick)="modalCtrl.dismiss(true)"></pos-ssk-round-button>
        </div>
    </div>
</div>