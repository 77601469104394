import { Dictionary } from 'lodash';
import { TakeoutOrderPaymentStatus, TakeoutOrderStatus } from '../models';

export type OrderMessage = {
  paid: string;
  unpaid: string;
};

export class TakeoutDeliveryConstant {
  static readonly ViewMenuItemMessage: Record<
    | TakeoutOrderStatus.SCHEDULED
    | TakeoutOrderStatus.IN_PRODUCTION
    | TakeoutOrderStatus.PRODUCTION_COMPLETE,
    OrderMessage
  > = {
    [TakeoutOrderStatus.SCHEDULED]: {
      paid: 'Order has been scheduled and paid for.',
      unpaid: 'Order has been scheduled.'
    },
    [TakeoutOrderStatus.IN_PRODUCTION]: {
      paid: 'Order is In Production.',
      unpaid: 'Order is In Production.'
    },
    [TakeoutOrderStatus.PRODUCTION_COMPLETE]: {
      paid: 'Order is complete and paid for.',
      unpaid: 'Order is complete. Needs payment.'
    }
  };
}
