import { format, parse } from 'date-fns';

export class DateHelper {
  static format(
    date: number | Date,
    formatString: string = 'yyyy-MM-dd'
  ): string {
    return format(date, formatString);
  }

  static parse(dateString: string, formatString: string = 'yyyy-MM-dd'): Date {
    return parse(dateString, formatString, new Date());
  }
}
