import {
  ModifierPrompt,
  ModifierPromptOption
} from 'src/app/models/pos-manager.model';

export class GetSharedObjects {
  static readonly type = '[POS Manager] Get Shared Objects';
}
export class GetModifierPrompts {
  static readonly type = '[POS Manager] Get Modifier Prompts';
}

export class GetModifierPromptsSuccess {
  static readonly type = '[POS Manager] Get Modifier Prompts Success';
  constructor(public modifierPrompts: ModifierPrompt[]) {}
}

export class GetEmbeddedDashboardUrl {
  static readonly type = '[POS Manager] Get Embedded Dashboard Url';
}

export class SelectModifierPrompt {
  static readonly type = '[POS Manager] Select Modifier Prompt';
  constructor(public id: number) {}
}

export class ClearSelectedModifierPrompt {
  static readonly type = '[POS Manager] Clear Selected Modifier Prompt';
  constructor() {}
}

export class SelectModifierPromptError {
  static readonly type = '[POS Manager] Select Modifier Prompt Error';
}

export class CreateModifierPrompt {
  static readonly type = '[POS Manager] Create Modifier Prompt';
  constructor(
    public name: string,
    public modifierPromptOptions: ModifierPromptOption[]
  ) {}
}

export class CreateModifierPromptSuccess {
  static readonly type = '[POS Manager] Create Modifier Prompt Success';
}

export class UpdateModifierPrompt {
  static readonly type = '[POS Manager] Update Modifier Prompt';
  constructor(
    public id: number,
    public name: string,
    public modifierPromptOptions: ModifierPromptOption[]
  ) {}
}

export class UpdateModifierPromptSuccess {
  static readonly type = '[POS Manager] Update Modifier Prompt Success';
}

export class DeleteModifierPrompt {
  static readonly type = '[POS Manager] Delete Modifier Prompt';
  constructor(public id: number) {}
}

export class GetProducts {
  static readonly type = '[POS Manager] Get Products';
}

export class PosManagerBackToPos {
  static readonly type = '[POS Manager] POS Manager Back To POS';
}
