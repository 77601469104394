<ion-header>
  <ion-toolbar mode="md"
               color="dark">
    <ion-title>Your session will timeout in</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen>
  <div class="h-full flex ion-align-items-center ion-justify-content-center">
    <p><strong>{{countdown}} second{{(countdown > 1) ? 's' : ''}}</strong></p>
  </div>
</ion-content>
