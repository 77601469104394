import { HttpErrorResponse } from '@angular/common/http';

import { POSManagerService } from 'src/app/models/pos-manager.model';

export class GetServices {
  static readonly type = '[POS Manager Service] Get Services';
}

export class SelectServiceDetail {
  static readonly type = '[POS Manager Service] Select Service Detail';
  constructor(public serviceId: number) {}
}

export class CreatePOSService {
  static readonly type = '[POS Manager Service] Create POS Service';
  constructor(public service: POSManagerService) {}
}

export class CreatePOSServiceSuccess {
  static readonly type = '[POS Manager Service] Create POS Service Success';
  constructor(public service: POSManagerService) {}
}

export class UpdatePOSService {
  static readonly type = '[POS Manager Service] Update POS Service';
  constructor(
    public readonly id: number,
    public readonly service: POSManagerService
  ) {}
}

export class UpdatePOSServiceSuccess {
  static readonly type = '[POS Manager Service] Update POS Service Success';
  constructor() {}
}

export class DeletePOSService {
  static readonly type = '[POS Manager Service] Delete POS Service';
  constructor(public id: number) {}
}

export class DeletePOSServiceSuccess {
  static readonly type = '[POS Manager Service] Delete POS Service Success';
  constructor() {}
}

export class DeletePOSServiceError {
  static readonly type = '[POS Manager Service] Delete POS Service Error';
  constructor(public readonly error: HttpErrorResponse) {}
}

export class SetPOSServiceLoaded {
  static readonly type = '[POS Manager Service] Set POS Service Loaded';
  constructor(public isLoaded: boolean) {}
}
