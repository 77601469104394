import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpenTextModifierModal } from './open-text-modifier.modal';
import { IonInputNumberBehaviorDirectiveModule } from 'src/app/directives/controls/ion-input-number-behavior-directive.module';
import { IonicModule } from '@ionic/angular';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [OpenTextModifierModal],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    DebounceClickDirectiveModule,
    IonInputNumberBehaviorDirectiveModule
  ],
  exports: [OpenTextModifierModal]
})
export class OpenTextModifierModalModule {}
