<div class="ion-page">
  <ion-header>
    <ion-toolbar mode="md"
                 color="dark">
      <ion-title>Manager Menu</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon name="close-outline" size="large"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-item class="large-padding-item" button detail *ngFor="let item of filterNavigationItems$ | async" (click)="onGoTo(item.routerLink)">
        <ion-icon [src]="item.icon" slot="start" size="large"></ion-icon>
        <ion-label>{{item.name}}</ion-label>
      </ion-item>

      <ion-item class="large-padding-item" button detail [routerLink]="pageConstant.SUPPORT_PAGE">
        <ion-icon name="cog" slot="start" size="large"></ion-icon>
        <ion-label>Support</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
  <ion-footer>
    <ion-toolbar mode="md"
                 color="light">
      <ion-buttons slot="start">
        <ion-button color="orange" fill="solid" mode="md" size="small" (click)="backToPos()">
          <ion-icon name="chevron-back-outline"></ion-icon>
          Back to POS
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</div>
