import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { lastValueFrom, map, Observable, Subject } from 'rxjs';

import { WebSocketProvider } from '.';
import { CommonConstant } from '../constants';
import { DeviceEventType, DeviceMessage } from '../models';
import { ResetApplication, UpdatedDevice } from '../store/app/app.action';
import { ConfigProvider } from './config.provider';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';

@Injectable({
  providedIn: 'root'
})
export class DeviceProvider {
  makingHttpRequest$ = new Subject();

  private deviceActivityChannelSubscription: Observable<any>;

  constructor(
    private readonly store: Store,
    private readonly webSocketProvider: WebSocketProvider,
    private readonly configProvider: ConfigProvider
  ) {}

  initDeviceActivityChannelSubscription() {
    if (this.deviceActivityChannelSubscription) {
      return;
    }

    this.deviceActivityChannelSubscription =
      this.subscribeActivityChannelForReport();
    this.getDataFromActivityChannel();
  }

  resetDeviceActivityChannelSubscription() {
    this.deviceActivityChannelSubscription = null;
    this.webSocketProvider.unsubscribe(
      CommonConstant.WEBSOCKET_CHANNELS.DEVICE_CHANNEL
    );
  }

  private subscribeActivityChannelForReport() {
    const device = this.store.selectSnapshot(APP_STATE_TOKEN).device;

    if (!device || !device.id) {
      return null;
    }

    if (this.deviceActivityChannelSubscription) {
      this.resetDeviceActivityChannelSubscription();
    }

    return this.webSocketProvider.subscribeTo(
      CommonConstant.WEBSOCKET_CHANNELS.DEVICE_CHANNEL,
      { room: device.id }
    );
  }

  private getDataFromActivityChannel() {
    if (!this.deviceActivityChannelSubscription) {
      return;
    }

    return lastValueFrom(
      this.deviceActivityChannelSubscription.pipe(
        map((response) => this.getWsMessageActions(response))
      )
    );
  }

  private getWsMessageActions(message: DeviceMessage) {
    if (message.event_type === DeviceEventType.ACTIVATED) {
      if (message.data.tenant !== CommonConstant.TENANT_TYPE.SYNERGY) {
        this.configProvider.configHost(message.data.tenant);
      }
      this.store.dispatch(new UpdatedDevice(message.data));
      return;
    } else if (
      message.event_type === DeviceEventType.MOVED ||
      message.event_type === DeviceEventType.DELETED
    ) {
      this.resetDeviceActivityChannelSubscription();
      this.store.dispatch(new ResetApplication());
    }
  }

  // private get isDeviceActive(): boolean {
  //   const deviceStatus = this.store.selectSnapshot(APP_STATE_TOKEN).device.status;
  //   return deviceStatus === DeviceStatus.Active
  // }
}
