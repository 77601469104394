import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { PosLoadingModal } from './component';
import {
  delayWhen,
  from,
  interval,
  map,
  Observable,
  of,
  switchMap
} from 'rxjs';
import { MenuConstant } from 'src/app/constants/menu.constant';

@Injectable({
  providedIn: 'root'
})
export class PosLoadingModalService {
  private _modal: HTMLIonModalElement = null;

  constructor(private readonly modalCtrl: ModalController) {}

  private async createModal(message$: Observable<string>) {
    this._modal = await this.modalCtrl.create({
      component: PosLoadingModal,
      componentProps: {
        content$: message$
      },
      cssClass: [
        'dynamic-height-modal',
        'dynamic-width-modal',
        'loading-modal'
      ],
      backdropDismiss: false
    });

    return this._modal;
  }

  presentLoadingModal(...messages: string[]) {
    const message$ = of(...messages).pipe(
      delayWhen((_val, idx) => (idx === 1 ? interval(5000) : of(null)))
    );

    const loading$ = from(this.createModal(message$));
    return loading$.pipe(
      switchMap((loading) => from(loading.present()).pipe(map(() => loading)))
    );
  }

  dismiss() {
    if (this._modal) {
      this._modal.dismiss();
      this._modal = null;
    }
  }
}
