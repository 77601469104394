import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * Inherit this component base class for all components having reactive subscriptions during their lifetime
 */
@Injectable()
export abstract class ReactiveComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit() {
    const subs = this.onSubscribe();
    for (const sub of subs) {
      this.subscription.add(sub);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Called after ngOnInit, any Subscriptions returned by this method will be unsubscribed in ngOnDestroy.
   * Use the generator syntax to setup your subscriptions, eg:
   * ```typescript
   * *onSubscribe() {
   *  yield this.someObservable.subscribe(...);
   * }
   * ```
   */
  abstract onSubscribe(): Iterable<Subscription>;

  protected addSubscription(sub: Subscription) {
    this.subscription.add(sub);
  }
}
