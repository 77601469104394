import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
  HttpStatusCode
} from '@angular/common/http';
import { Store } from '@ngxs/store';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import QueryString from 'qs';

import { ResetError } from '../store/error/error.action';
import { BaseProvider, DeviceProvider } from '../providers';
import { ModalService } from '../services';
import { ErrorState } from '../store/error/error.state';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private deviceProvider: DeviceProvider,
    private baseProvider: BaseProvider,
    private modalService: ModalService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    //Copy original headers to keep any values set beforehand
    let headers: HttpHeaders = request.headers;
    headers = headers.set('Accept', '*/*');

    if (!request.headers.has('Content-Type')) {
      headers = headers.set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      );
    }

    if (!request.headers.has('Authorization')) {
      if (request.url.includes('api/manager/')) {
        const token: string =
          this.store.selectSnapshot(APP_STATE_TOKEN).operator
            .manager_access_jwt;
        headers = headers.set('Authorization', token);
      } else {
        const token: string =
          this.store.selectSnapshot(APP_STATE_TOKEN).device.token;
        headers = headers.set('Authorization', token);
      }
    }

    let body = {};
    if (request.body) {
      body = QueryString.stringify(request.body);
    }

    let params = request.params;
    const hasLoading = params.has('loadingText');
    const loadingText: string = hasLoading ? params.get('loadingText') : null;
    if (hasLoading) {
      params = params.delete('loadingText');
    }
    const dupReq = request.clone({ headers, body, params });
    const handleRequest$ = next.handle(dupReq).pipe(
      takeUntil(this.deviceProvider.makingHttpRequest$),
      filter((event) => event instanceof HttpResponse),
      switchMap((response: HttpResponse<any>) => {
        const error = response.body?.error;
        if (error) {
          // Return Error Response for manually error handling
          return throwError(
            () =>
              new HttpErrorResponse({
                error: response.body.error,
                status: HttpStatusCode.NoContent
              })
          );
        }
        const errorMessage = this.store.selectSnapshot(
          ErrorState.getErrorMessage
        );
        if (errorMessage) {
          this.store.dispatch(ResetError);
        }
        return of(response);
      })
    );

    return hasLoading
      ? from(this.modalService.showLoadingModal(loadingText)).pipe(
          switchMap(() => handleRequest$),
          switchMap((response) =>
            from(this.modalService.closeLoadingModal()).pipe(
              map(() => response)
            )
          ),
          catchError((error: HttpErrorResponse) =>
            from(this.modalService.closeLoadingModal()).pipe(
              switchMap(() => throwError(() => error))
            )
          )
        )
      : handleRequest$;
  }
}
