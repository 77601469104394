export class MenuConstant {
  static readonly menuIds = {};

  static readonly ITEM_MANAGER_TYPES = ['menu_items', 'product_items'];

  static readonly ITEM_MANAGER_COLUMNS = [
    'category',
    'production_area',
    'qty_limit',
    'unavailable',
    'price',
    'tax'
  ];

  static readonly LOADING_MODAL_MSG = {
    MENU_TALLY_LOADING: 'Loading menu, please wait...',
    LOADING_ITEMS: 'Loading items, please wait...',
    MENU_TALLY_LOADING_LONGER:
      'Loading is taking longer than usual due to network conditions. Please wait.'
  };
}
