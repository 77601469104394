import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TableConstant } from 'src/app/constants';
import { Authentication, Operator } from 'src/app/models';
import { ApiType } from 'src/app/pos.config';
import { BaseService } from 'src/app/services/base.service';
import { ErrorService } from 'src/app/services/error/error.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  constructor(
    store: Store,
    private readonly http: HttpClient,
    private readonly errorService: ErrorService
  ) {
    super(store);
  }

  getAuthInfo(): Observable<Authentication> {
    return this.http.get<Authentication>('authenticate').pipe(
      this.errorService.retryPipe(ApiType.Query),
      map((response) => this.formatAuthenticationData(response))
    );
  }

  private formatAuthenticationData(data: Authentication) {
    let operators = (data && data.facility_details.operators) || [];
    let index = 0;
    operators =
      sortBy(operators, (staff) => staff.id).map((staff) => {
        index++;
        if (index > 17) {
          index = 1;
        }
        return {
          ...staff,
          name: staff.first_name + ' ' + staff.last_name,
          display_name:
            (staff.first_name ? staff.first_name : staff.last_name || '') +
            ' ' +
            (staff.first_name && staff.last_name
              ? staff.last_name.charAt(0) + '.'
              : ''),
          color: TableConstant.BACKGROUND_TEXT_COLORS[index]
        };
      }) || [];
    data.facility_details.operators = operators;

    return data;
  }

  updatePinCode(operatorId, pin_code) {
    const url = this.getLocationUrl(`operators/${operatorId}`);
    return this.http.put<{ operator: Operator }>(url, { pin_code }).pipe(
      this.errorService.retryPipe(ApiType.Mutate),
      map((response) => response.operator)
    );
  }
}
