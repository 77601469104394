import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { AppSelectors } from 'src/app/store/app/app.selectors';
import { HardwareDeviceSelectors } from 'src/app/store/hardware-device/hardware-device.selector';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[debounce-click]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 500;
  @Input() isOnlyClick = false;
  @Output() debounceClick = new EventEmitter();
  @SelectSnapshot(HardwareDeviceSelectors.platform) private readonly platform:
    | 'web'
    | 'android'
    | 'ios';

  private clicks = new Subject();
  private subscription: Subscription;

  constructor(private readonly store: Store) {}

  ngOnInit() {
    this.subscription = this.clicks
      .pipe(debounceTime(this.debounceTime))
      .subscribe((e) => this.debounceClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  @HostListener('click', ['$event'])
  @HostListener('touchend', ['$event'])
  clickTouchendEvent(event: Event) {
    const isHandleEvent = this.isOnlyClick
        ? event.type === 'click'
        : this.platform === 'web' || event.type === 'touchend',
      isInputFocused = this.store.selectSnapshot(
        AppSelectors.getIsInputFocused
      );

    if (isHandleEvent && !isInputFocused) {
      event.preventDefault();
      event.stopPropagation();
      this.clicks.next(event);
    }
  }
}
