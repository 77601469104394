import { Injectable } from '@angular/core';
import {
  AlertController,
  AlertOptions,
  LoadingController,
  ModalController,
  ModalOptions,
  PopoverController
} from '@ionic/angular';
import { ComponentProps, OverlayEventDetail } from '@ionic/core';
import { Actions, ofActionCompleted } from '@ngxs/store';
import _ from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  take,
  takeUntil
} from 'rxjs';
import { MenuConstant } from 'src/app/constants/menu.constant';
import { GetMenuTally } from 'src/app/store/menu/menu.action';
import {
  ModifierPromptOption,
  POSModifierPromptAssignment
} from '../../models';

const DEFAULT_MODAL_OPTS: Partial<ModalOptions> = {
  cssClass: 'pos-modal',
  backdropDismiss: false
};

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modals: HTMLIonModalElement[] = [];
  private readonly _isLoading$ = new BehaviorSubject(false);
  readonly isLoading$ = this._isLoading$.asObservable();

  readonly MODAL_DESTROYED_PROPERTY = 'modalDestroyed';

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly popoverCtrl: PopoverController,
    private readonly alertCtrl: AlertController,
    private readonly loadingCtrl: LoadingController,
    private readonly actions$: Actions
  ) {}

  private set isLoading(value: boolean) {
    this._isLoading$.next(value);
  }

  private get isLoading(): boolean {
    return this._isLoading$.value;
  }

  showModal$<T = ComponentProps<any>>(
    component: any,
    opts: Omit<ModalOptions, 'component'>,
    componentProps: T
  ): Observable<HTMLIonModalElement> {
    const modalOpts: ModalOptions = {
      ...DEFAULT_MODAL_OPTS,
      component,
      componentProps,
      id: component.name,
      ...opts
    };

    return from(this.modalCtrl.create(modalOpts)).pipe(
      switchMap((modal) => forkJoin([from(modal.present()), of(modal)])),
      map(([, modal]) => modal)
    );
  }

  async showModal<T = any, K = _.Dictionary<any>>(
    modalComponent: any,
    data?: K,
    opts?: Partial<ModalOptions>,
    dismissHandler?: (data: { data?: T; role?: string }) => void,
    blockDuplicate = false
  ) {
    if (!opts) {
      opts = { backdropDismiss: false };
    }
    const modalOpts: ModalOptions = {
      component: modalComponent,
      componentProps: data,
      cssClass: 'pos-modal',
      id: modalComponent.name,
      animated: false,
      ...opts
    };

    if (blockDuplicate) {
      const existingModal = this.modals.find(
        (item) => item.id === modalOpts.id
      );

      if (!!existingModal) {
        return existingModal;
      }
    }

    const modal = await this.modalCtrl.create(modalOpts);

    modal.onWillDismiss().then((v) => {
      const modalIdx = this.modals.findIndex((item) => item.id === modal.id);
      if (modalIdx > -1) {
        this.modals.splice(modalIdx, 1);
      }

      if (dismissHandler) {
        dismissHandler(v);
      }
    });

    modal.present().then(() => {
      if (
        opts.hasOwnProperty(this.MODAL_DESTROYED_PROPERTY) &&
        opts[this.MODAL_DESTROYED_PROPERTY]
      ) {
        modal.dismiss().catch(() => {});
      }
    });
    this.modals.push(modal);
    return modal;
  }

  async showLoadingModal(message = 'Loading data...') {
    if (this.isLoading) {
      await this.loadingCtrl.dismiss().catch(() => {});
    }

    this.isLoading = true;
    const loading = await this.loadingCtrl.create({ message });
    const loadingId = loading.id;
    await loading.present().then(() => {
      if (!this.isLoading) {
        this.closeLoadingModal();
      } else {
        setTimeout(() => {
          this.loadingCtrl.getTop().then((loadingModal) => {
            if (!loadingModal || loadingModal.id !== loadingId) {
              return;
            }

            this.closeLoadingModal();
          });
        }, 60000);
      }
    });
  }

  async closeLoadingModal() {
    this.isLoading = false;
    await this.loadingCtrl.dismiss().catch(() => {});
  }

  async alert(
    opts?: AlertOptions,
    willDismissHandler?: (data: OverlayEventDetail<any>) => void
  ) {
    const alert = await this.alertCtrl.create({
      ...opts
    });
    await alert.present();
    if (willDismissHandler) {
      alert.onWillDismiss().then(willDismissHandler);
    }
  }

  async closeAllModals() {
    for (const modal of this.modals) {
      await modal.dismiss().catch(() => {});
    }
    // try to close the modal which are opened by modal controller instead of modal service
    await this.modalCtrl.dismiss().catch(() => {});
    await this.popoverCtrl.dismiss().catch(() => {});
    await this.alertCtrl.dismiss().catch(() => {});
    await this.loadingCtrl.dismiss().catch(() => {});
    this.modals = [];
  }

  async isSomeModalOpen(): Promise<boolean> {
    return !_.isEmpty(
      _.compact([
        await this.modalCtrl.getTop(),
        await this.popoverCtrl.getTop(),
        await this.alertCtrl.getTop(),
        await this.loadingCtrl.getTop()
      ])
    );
  }

  alertErrorMessage(message: string) {
    this.alertCtrl
      .create({
        message,
        header: 'Error',
        backdropDismiss: false,
        cssClass: 'alert-message',
        buttons: [
          {
            text: 'OK',
            role: 'ok'
          }
        ]
      })
      .then((alert) => alert.present());
  }

  showMenuTallyLoadingModal() {
    this.showLoadingModal(MenuConstant.LOADING_MODAL_MSG.MENU_TALLY_LOADING);

    const TIMEOUT_MS = 5000,
      destroy$ = new Subject<void>(),
      timeout = setTimeout(async () => {
        destroy$.next();
        await this.closeLoadingModal();
        this.showLoadingModal(
          MenuConstant.LOADING_MODAL_MSG.MENU_TALLY_LOADING_LONGER
        );
      }, TIMEOUT_MS);

    this.actions$
      .pipe(ofActionCompleted(GetMenuTally), takeUntil(destroy$), take(1))
      .subscribe(() => {
        clearTimeout(timeout);
        this.closeLoadingModal();
      });
  }

  isValidPrompt(prompt: POSModifierPromptAssignment): boolean {
    const selectedCount = prompt.selectedOptions?.length || 0;
    if (!prompt.min && !prompt.max) {
      return true; // no min - no max => always valid
    }

    const min = prompt.min || 0;
    const max = prompt.max || 999999;

    if (min > selectedCount) {
      return false;
    }
    if (max < selectedCount) {
      return false;
    }
    return true;
  }

  onSelect(
    selectedPrompts: POSModifierPromptAssignment[],
    prompt: POSModifierPromptAssignment,
    option: ModifierPromptOption
  ) {
    const existedPrompt = selectedPrompts.find((x) => x.id === prompt.id);
    existedPrompt.isTouched = true;
    const existedOption = existedPrompt.selectedOptions?.find(
      (x) => x.id === option.id
    );
    if (existedPrompt.max === 1 && existedPrompt.min === 1) {
      // should remove everything in this list as it's radio button
      existedPrompt.selectedOptions = [_.cloneDeep(option)];
    } else {
      if (existedOption) {
        existedPrompt.selectedOptions = existedPrompt.selectedOptions.filter(
          (x) => x.id !== option.id
        );
      } else {
        existedPrompt.selectedOptions = existedPrompt.selectedOptions || [];
        existedPrompt.selectedOptions.push(_.cloneDeep(option));
      }
    }
    existedPrompt.isValid = this.isValidPrompt(existedPrompt);
  }

  checkIsFormValid(selectedPrompts: POSModifierPromptAssignment[]) {
    return selectedPrompts.every((x) => x.isValid);
  }
}
