import { DateHelper } from '../helpers';
import {
  AppointmentRecurringEndControlValue,
  AppointmentRecurringEndType,
  FrequencyControlValue,
  RecurringControlValue
} from '../models';
import { RecurrenceType } from '../models/service-calendar.model';

export class RecurringAppointmentConstant {
  static readonly INITIAL_FREQUENCY_CONTROL_VALUE: FrequencyControlValue = {
    value: 1,
    type: RecurrenceType.DAILY
  };

  static readonly INITIAL_APPOINTMENT_RECURRING_END_VALUE: AppointmentRecurringEndControlValue =
    {
      type: AppointmentRecurringEndType.END_BY,
      end_by: DateHelper.format(new Date()),
      end_after: 1
    };

  static readonly INITIAL_RECURRING_CONTROL_VALUE: RecurringControlValue = {
    isAllow: 'false',
    frequency: RecurringAppointmentConstant.INITIAL_FREQUENCY_CONTROL_VALUE,
    recurring_end:
      RecurringAppointmentConstant.INITIAL_APPOINTMENT_RECURRING_END_VALUE
  };
}
