import {
  BaseDiner,
  Diner,
  NormalizedTicket,
  Ticket,
  TicketTransaction
} from 'src/app/models';

export class GetDiners {
  static readonly type = '[Diners] Get Diners';
  constructor(
    public name: string,
    public patients_only: boolean,
    public visitingFacilityId: number = null
  ) {}
}
export class GetDinerByBarcode {
  static readonly type = '[Diners] Get Diner By Barcode';
  constructor(
    public barcode: string,
    public searchType: 'hostMode' | 'ticketAssignment' | 'reservation'
  ) {}
}

export class GetDinerByBarcodeFailed {
  static readonly type = '[Diners] Get Diner By Barcode Failed';
  constructor(public barcode: string) {}
}

export class GetDinerDetail {
  static readonly type = '[Diners] Get Diner Detail';
  constructor(
    public ticket: Ticket | NormalizedTicket,
    public addMealPlan: boolean = false,
    public forceApplyMealPlan = false
  ) {}
}

export class GetDinerDetailSuccess {
  static readonly type = '[Diners] Get Diner Details Success';
  constructor(public readonly diner: Diner) {}
}

export class GetAssignedResidentsSuccess {
  static readonly type = '[Diners] Get Assigned Residents Success';
  constructor(public residents: BaseDiner[]) {}
}

export class ClearSearchDinersResult {
  static readonly type = '[Diners] Clear Search Diners';
}

export class SelectDiner {
  static readonly type = '[Diners] Select Diner';
  constructor(
    public ticket: Ticket | NormalizedTicket,
    public addMealPlan: boolean = false,
    public getDinerDetail: boolean = false,
    public forceApplyMealPlan = false
  ) {}
}

export class AssignMealPlan {
  static readonly type = '[Diners] Assign Meal Plan';
  constructor(
    public diner: Diner,
    public device_ticket_uuid: string,
    public forceApplyMealPlan = false
  ) {}
}

export class ShowMealPlanPrompt {
  static readonly type = '[Diners] Show Meal Plan Prompt';
}

export class UpdateChargeAccountBalance {
  static readonly type = '[Diners] Update Charge Account Balance';
  constructor(public dinerId: number, public transaction: TicketTransaction) {}
}

export class GetSearchingDinersSuccess {
  static readonly type = '[Diners] Get Searching Diners Success';
  constructor(public readonly diners: Diner[]) {}
}

export class MultipleDinersFound {
  static readonly type = '[Diners] Multiple Diners Found';
  constructor(
    public readonly diners: Diner[],
    public readonly barcode: string
  ) {}
}
