import { compact, sortBy } from 'lodash';

import { MacroGridConstant } from '../constants';
import {
  CategoryDetail,
  MacroGrid,
  MacroGridItem,
  MacroGridItemType,
  PriceType
} from '../models';

export class MacroHelper {
  static filterMacroGridItems(
    macroGrid: MacroGrid,
    macroGridItems: MacroGridItem[]
  ): (MacroGridItem | MacroGrid)[] {
    if (!macroGridItems) {
      return [];
    }

    const values: (MacroGridItem | MacroGrid)[] = compact(
      macroGridItems.map(
        (macroGridItem) =>
          Boolean(
            macroGridItem.name ||
              macroGridItem.category_details ||
              macroGridItem.product_details ||
              macroGridItem.modifier_details ||
              macroGridItem.child_items
          ) && setGridItemLabel(macroGridItem)
      )
    );

    const result: (MacroGridItem | MacroGrid)[] = [];
    sortBy(values, 'sort').forEach((item, index) => {
      if (
        'product_details' in item &&
        item.product_details &&
        !item.product_details.price_type
      ) {
        item.product_details.price_type = PriceType.FIXED;
      }

      const nextItem = values[index + 1];
      if (nextItem && nextItem.sort > 16) {
        return;
      }

      if (item.sort !== 1 && index === 0) {
        result.push({ id: 0, name: null, type: MacroGridItemType.EMPTY });
      }

      if (
        item.type === MacroGridConstant.MACRO_GRID_ITEM_TYPE.SUBPAGE &&
        'child_items' in item
      ) {
        const macro: MacroGrid = {
          id: item.id,
          name: item.name,
          type: macroGrid.type,
          sort: item.sort,
          color: item.color,
          image: item.image
        };
        macro.macro_grid_items = MacroHelper.filterMacroGridItems(
          macro,
          item.child_items
        ) as MacroGridItem[];
        item = macro;
      }
      result.push(item);
      if (nextItem && item.sort !== nextItem.sort - 1) {
        const length = nextItem.sort - item.sort;
        for (let i = 1; i < length; i++) {
          result.push({ id: 0, name: null, type: MacroGridItemType.EMPTY });
        }
      }
    });

    return result;
  }

  static getCategoriesFromMacroGridItems(
    items: MacroGridItem[]
  ): CategoryDetail[] {
    const categories: CategoryDetail[] = [];

    items?.forEach((item) => {
      if (item.category_details) {
        if (
          !categories.find(
            (category) =>
              category.category_id === item.category_details.category_id
          )
        ) {
          categories.push(item.category_details);
        }
      } else {
        categories.push(
          ...MacroHelper.getCategoriesFromMacroGridItems(
            item.child_items || (item as any).macro_grid_items
          ).filter(
            (childCategory) =>
              !categories.find(
                (category) => category.category_id === childCategory.category_id
              )
          )
        );
      }
    });

    return categories;
  }
}

/** Set the label for the given macro grid item */
function setGridItemLabel(macroGridItem: MacroGridItem): MacroGridItem {
  let { name } = macroGridItem;
  if (!name) {
    if (macroGridItem.category_details) {
      name = macroGridItem.category_details.name;
    } else if (macroGridItem.product_details) {
      name = macroGridItem.product_details.name;
    }
  }
  macroGridItem.name = name;
  return macroGridItem;
}
