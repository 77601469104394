import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GiftCardItemModal } from './gift-card-item.modal';
import { IonicModule } from '@ionic/angular';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { NumericKeyboardComponentModule } from 'src/app/components/shared/numeric-keyboard/numeric-keyboard-component.module';
import { FormsModule } from '@angular/forms';
import { InputFocusDirectiveModule } from 'src/app/directives/input-focus-directive/input-focus-directive.module';
import { BlurButtonDirectiveModule } from 'src/app/directives/blur-button/blur-button-directive.module';

@NgModule({
  declarations: [GiftCardItemModal],
  imports: [
    CommonModule,
    IonicModule,
    DebounceClickDirectiveModule,
    NumericKeyboardComponentModule,
    FormsModule,
    InputFocusDirectiveModule,
    BlurButtonDirectiveModule
  ],
  exports: [GiftCardItemModal]
})
export class GiftCardItemModalModule {}
