import { Injectable } from '@angular/core';

import { PosMenuController } from 'src/app/components/services-appointment/menu';
import { ManagerMenuComponent } from './manager-menu.component';

@Injectable()
export class PosManagerMenuService {
  constructor(private readonly _posMenuController: PosMenuController) {}

  async openMenu() {
    const result = await this._posMenuController.open(ManagerMenuComponent, {
      maxWidth: '400px'
    });

    return result;
  }
}
