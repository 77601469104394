import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ToastController } from '@ionic/angular';
import moment from 'moment';
import _ from 'lodash';

import { TableConstant } from '../constants';
import {
  FloorplanStatus,
  ResponseTable,
  Seat,
  Table,
  TableListingStatus,
  Ticket,
  TicketStatus
} from '../models';
import { LOCATION_STATE_TOKEN } from '../store/location/location.state';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { TableHelper } from 'src/app/helpers';
import { LocationStateHelper } from 'src/app/store/location/location.state.helper';
@Injectable({
  providedIn: 'root'
})
export class TableProvider {
  constructor(
    private readonly store: Store,
    private readonly toastCtrl: ToastController
  ) {}

  addTableLocalAttributes(responseTable: ResponseTable | Table): Table {
    const statusName = this.getTableStatusName(responseTable);
    const table = _.cloneDeep(responseTable) as Table;
    table.local_attributes = {
      status_name: statusName,
      status_class: (statusName || '').toLowerCase().replace(/ /g, '-'),
      time_seated: this.getTimeSeated(responseTable, statusName)
    };
    return table;
  }

  getSeatName(seat: Seat) {
    if (!seat || !seat.ticket) {
      return null;
    }

    return this.getDinerName(seat.ticket);
  }

  getDinerName(ticket: Ticket) {
    if (ticket && !ticket.diner_id && !ticket.diner_name) {
      return 'Guest';
    }

    let [firstName, lastName] = (ticket.diner_name || '').split(' ');
    if (ticket.diner instanceof Object) {
      firstName = ticket.diner.first_name;
      lastName = ticket.diner.last_name;
    }
    return `${firstName} ${(lastName || ' ').charAt(0)}.`;
  }

  isEmptyTicket(status: TicketStatus): boolean {
    return !status || TableConstant.EMPTY_TICKET_STATUS.includes(status);
  }

  showTicketLockedMsg(operatorId: number) {
    const operators =
      this.store.selectSnapshot(APP_STATE_TOKEN).facility_details.operators;
    const { first_name, last_name } = _.find(
      operators,
      (o) => o.id === operatorId
    );
    const message = `Seat is currently being edited by ${first_name} ${last_name}.`;

    this.toastCtrl
      .create({
        color: 'danger',
        duration: 3000,
        message
      })
      .then((t) => t.present());
  }

  getTableStatus: (table: Table) => TableListingStatus = (table: Table) =>
    this.getTableListingStatus(
      table,
      !!TableHelper.getCurrentReservationOfTable(
        // table.reservations || [],
        [],
        this.store.selectSnapshot(LOCATION_STATE_TOKEN).location
          .reservation_meal_configurations
      )
    );

  private getTableStatusName(table: ResponseTable | Table): string {
    const seats = table?.seats;
    if (!seats) {
      return 'N/A';
    }

    if (
      seats.every(
        (s) =>
          !s?.floorplan_status ||
          this.isEmptyTicket(s.ticket_status) ||
          !s.device_ticket_uuid
      )
    ) {
      return TableConstant.TABLE_STATUS.EMPTY_TABLE;
    }

    if (
      seats.every(
        (s) =>
          !s?.floorplan_status ||
          s.floorplan_status === FloorplanStatus.EmptyTicket
      )
    ) {
      return TableConstant.TABLE_STATUS.EMPTY_COURSES;
    }

    const location = this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
    const isCoursingEnabled: boolean =
      LocationStateHelper.getIsCoursingEnabled(location);

    if (
      seats.some((s) => s?.floorplan_status === FloorplanStatus.PendingItems)
    ) {
      return isCoursingEnabled
        ? TableConstant.TABLE_STATUS.PENDING_COURSES
        : TableConstant.TABLE_STATUS.PENDING_ITEM;
    }

    return isCoursingEnabled
      ? TableConstant.TABLE_STATUS.COMPLETED_COURSES
      : TableConstant.TABLE_STATUS.COMPLETED_ITEMS;
  }

  private getTimeSeated(
    table: ResponseTable | Table,
    tableStatusName: string
  ): string {
    if (tableStatusName === TableConstant.TABLE_STATUS.EMPTY_TABLE) {
      return 'N/A';
    }

    const tickets = Array.from(_.get(table, 'seats', []))
      .map((s: Seat) => s?.ticket_created_at)
      .filter((i) => !!i)
      .sort();
    const firstTicket = tickets?.length && tickets[0];
    if (firstTicket) {
      const { days, hours, minutes } = this.getTimeLeft(firstTicket);
      return `${days > 0 ? days + 'day' : ''} ${hours}h ${minutes}m`;
    }
    return 'N/A';
  }

  private getTimeLeft(firstTime) {
    const value = moment(new Date(firstTime)).toString();
    const t = Date.parse(new Date().toString()) - Date.parse(value);
    const minutes = Math.floor((t / 1000 / 60) % 60);
    const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    const days = Math.floor(t / (1000 * 60 * 60 * 24));
    return { days, hours, minutes };
  }

  private getTableListingStatus(
    table: Table,
    isReservedNow: boolean
  ): TableListingStatus {
    const ticketSeats = table.seats.filter((s) => !!s.device_ticket_uuid);

    if (!ticketSeats.length) {
      return isReservedNow
        ? TableListingStatus.Reserved
        : TableListingStatus.Available;
    }

    if (
      _.some(
        ticketSeats,
        (seat) => seat.floorplan_status === FloorplanStatus.EmptyTicket
      )
    ) {
      return TableListingStatus.NoOrdersTaken;
    }

    if (
      _.some(
        ticketSeats,
        (seat) => seat.floorplan_status === FloorplanStatus.PendingItems
      )
    ) {
      return TableListingStatus.Pending;
    }

    return TableListingStatus.Fired;
  }
}
