import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrinterByIdPipe } from './printer-by-id.pipe';

@NgModule({
  declarations: [PrinterByIdPipe],
  imports: [CommonModule],
  exports: [PrinterByIdPipe]
})
export class PrinterByIdPipeModule {}
