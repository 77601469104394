import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AppState } from './app/app.state';
import { DinersState } from './diners/diners.state';
import { ErrorState } from './error/error.state';
import { LocationState } from './location/location.state';
import { MenuState } from './menu/menu.state';
import { PaymentState } from './payment/payment.state';
import { PosManagerMenuState } from './pos-manager/pos-menu/pos-menu.state';
import { PosManagerOperatorState } from './pos-manager/pos-operator/pos-operator.state';
import { PosManagerServiceState } from './pos-manager/pos-services-manager/pos-services.state';
import { PosManagerState } from './pos-manager/pos-manager.state';
import { PosScheduleState } from './pos-manager/pos-schedules/pos-schedules.state';
import { TablesState } from 'src/app/store/tables/tables.state';
import { TakeOutDeliveryState } from './takeout-delivery/takeout-delivery.state';
import { TicketItemsState } from './ticket-items/ticket-items.state';
import { TicketsState } from './tickets/tickets.state';
import { TransactionLookupState } from './transaction-lookup/transaction-lookup.state';
import { TransactionsState } from './transactions/transactions.state';
import { SeatsState } from 'src/app/store/seats/seats.state';
import { ngxsConfig } from './ngxs.config';
import { TableReservationsState } from 'src/app/store/tables/reservations/table-reservation.state';
import { HardwareDeviceState } from 'src/app/store/hardware-device/hardware-device.state';
import { TakeoutDeliveryService } from 'src/app/services/takeout-delivery.service';

const STORE_STATES = [
  HardwareDeviceState,
  AppState,
  DinersState,
  ErrorState,
  LocationState,
  MenuState,
  PaymentState,
  TablesState,
  SeatsState,
  TicketsState,
  TicketItemsState,
  TransactionsState,
  TransactionLookupState,
  PosManagerState,
  PosManagerMenuState,
  PosManagerOperatorState,
  TakeOutDeliveryState,
  PosManagerServiceState,
  PosScheduleState,
  TableReservationsState
];

@NgModule({
  providers: [TakeoutDeliveryService],
  imports: [NgxsModule.forRoot(STORE_STATES, ngxsConfig)]
})
export class StoreModule {}
