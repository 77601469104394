import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { ManagerMenuComponent } from './manager-menu.component';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { DatePickerModule } from 'src/app/components/date-picker/date-picker.module';
import { SortStrPipeModule } from 'src/app/pipes/sort-str-pipe/sort-str-pipe.module';
import { PosManagerMenuService } from './pos-manager-menu.service';

@NgModule({
  declarations: [ManagerMenuComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DatePickerModule,
    SortStrPipeModule
  ],
  exports: [ManagerMenuComponent],
  providers: [PosManagerMenuService]
})
export class PosManagerMenuModule {}
