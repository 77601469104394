import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DatePickerComponent } from './date-picker.component';
import { DatePickerInputDirective } from './date-picker-input.directive';

@NgModule({
  declarations: [DatePickerComponent, DatePickerInputDirective],
  imports: [CommonModule, IonicModule],
  exports: [DatePickerComponent, DatePickerInputDirective]
})
export class DatePickerModule {}
