import Decimal from 'decimal.js';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Draft } from 'immer';
import _ from 'lodash';
import { ComponentFixture } from '@angular/core/testing';
import { By } from '@angular/platform-browser';
import { DebugElement } from '@angular/core';

export class GeneralHelper {
  /** Return the given number as a decimal to number decimal places */
  static asDecimal(num: number | string, decimalNumber = 2): number {
    num = formatCorrectNumber(num);
    const decimal = new Decimal(num || 0);
    return +decimal.toDP(decimalNumber);
  }

  static subtractFrom(minuend: number, subtrahend: number) {
    const difference = Decimal.sub(minuend, subtrahend);
    return +difference.toDP(2);
  }

  /** Sort alpha numerically by property */
  static alphaSortByProperty(property: string, a: any, b: any) {
    return a[property]
      .toString()
      .trim()
      .localeCompare(b[property].toString().trim(), 'en', { numeric: true });
  }

  static generateUuid() {
    return cloneDeep(uuidv4());
  }

  static updateObject<T>(
    draft: Draft<T>,
    key: string,
    updateKey: string,
    updateValue: Partial<any>
  ): void {
    if (!_.has(draft, key)) {
      draft[key] = {
        [updateKey]: updateValue
      };
    } else if (draft[key][updateKey]) {
      draft[key][updateKey] = updateValue;
    } else {
      (draft as any)[key] = {
        ...draft[key],
        [updateKey]: updateValue
      };
    }
  }
}

function formatCorrectNumber(num: number | string) {
  if (
    !!num &&
    typeof num === 'string' &&
    num.length > 0 &&
    num.charAt(0) === '.'
  ) {
    num = '0' + num;
  }

  return num;
}

export function queryEl<T>(
  fixture: ComponentFixture<T>,
  selector: string
): DebugElement {
  return fixture.debugElement.query(By.css(selector));
}
