import { Injectable } from '@angular/core';
import _ from 'lodash';
import {
  Seat,
  SeatAttributes,
  SeatEditorData,
  Table,
  TableUpdateData
} from '../models/floorplan.model';
import { ObjectData } from 'gojs';

@Injectable({
  providedIn: 'root'
})
export class SeatMapProvider {
  private readonly seatKeys = ['id', 'align', 'focus'];
  private readonly tableKeys = [
    'id',
    'location:location',
    'location:newPosition',
    'seats_attributes:seats'
  ];

  focusMap = {
    top: '0.5 1',
    right: '0 0.5',
    bottom: '0.5 0',
    left: '1 0.5'
  };
  alignMap = {
    top: { y: 0 },
    right: { x: 1 },
    bottom: { y: 1 },
    left: { x: 0 }
  };
  precision = 5;

  /**
   * Parse the seat node data for a table
   */
  parseSeatEditorData(
    seats: Seat[],
    editorData: SeatEditorData,
    isCircle: boolean
  ): Seat[] {
    if (isCircle) {
      return this.circleSeatAPIData(seats, editorData);
    }

    return this.rectSeatAPIData(seats, editorData);
  }

  /**
   * Get the width of the gap between seats on the side of a quadrilateral table
   * @param  seats The number of seats on side of the table
   */
  gapWidth(seats: number): number {
    return this.round(1 / (1 + 3 * seats));
  }

  /**
   * Should the numbers increase from left to right
   * @param pos Position on the table
   */
  lToR(pos: string): boolean {
    return pos === 'top' || pos === 'right';
  }

  /**
   * Map seats information to node data
   * @param data Data to map
   */
  rectSeatAPIData = (seats: Seat[], editorData: SeatEditorData): Seat[] => {
    let number = 1;
    const data: Seat[] = [];
    for (const [key, val] of Object.entries(editorData)) {
      const gap = this.gapWidth(val);
      const reverse = !this.lToR(key);
      for (
        let i = reverse ? val - 1 : 0, inx = 0;
        inx < val;
        reverse ? i-- : i++, number++, inx++
      ) {
        const alignment = this.rectAlignment(gap, i);
        const x =
          typeof this.alignMap[key].x !== 'undefined'
            ? this.alignMap[key].x
            : alignment;
        const y =
          typeof this.alignMap[key].y !== 'undefined'
            ? this.alignMap[key].y
            : alignment;
        const align = `${x} ${y}`;
        const focus = this.focusMap[key];
        const node = seats[number - 1];
        data.push({ ...node, number, align, focus });
      }
    }
    return data;
  };

  /**
   * Parse the seat node data for a circular table
   */
  circleSeatAPIData = (seats: Seat[], editorData: SeatEditorData): Seat[] => {
    const data: Seat[] = [];
    if (editorData.top) {
      const angle = (Math.PI * 2) / editorData.top;
      for (let number = 0; number < editorData.top; number++) {
        const { x, y } = this.circleAlignment(angle * (number + 1));
        const align = `${x} ${y}`;
        const focus = `${this.round(1 - x)} ${this.round(1 - y)}`;
        const node = seats[number];
        data.push({ ...node, number: number + 1, align, focus });
      }
    }
    return data;
  };

  parseTableUpdate(prevTables: Table[], table: ObjectData): TableUpdateData {
    const update: TableUpdateData = {};
    for (const tKey of this.tableKeys) {
      // eslint-disable-next-line prefer-const
      let [outputKey, sourceKey] = tKey.split(':');
      sourceKey = sourceKey || outputKey;
      update[outputKey] =
        sourceKey === 'seats'
          ? this.parseSeatAttributes(prevTables, table, sourceKey)
          : table[sourceKey] || table[outputKey];
    }
    return update;
  }

  private parseSeatAttributes(
    prevTables: Table[],
    table: ObjectData,
    sourceKey: string
  ): SeatAttributes[] {
    const parsedSeatAttrs: SeatAttributes[] =
      table[sourceKey].map(
        (seatData: any) => <SeatAttributes>_.pick(seatData, this.seatKeys)
      ) || [];

    // get the original table and seats retrieved from api
    const prevTable = _.find(prevTables, { id: table.id });
    const prevSeats = prevTable.seats || [];

    // ensure all parsed seats have an id
    parsedSeatAttrs.forEach((seat, index) => {
      const id = prevSeats[index] && prevSeats[index].id;
      seat.id = seat.id || id;
    });
    // Add removed seats
    if (parsedSeatAttrs.length < prevSeats.length) {
      const diff = prevSeats.slice(parsedSeatAttrs.length);
      for (const seat of diff) {
        if (seat) {
          parsedSeatAttrs.push({ id: seat.id, align: null, focus: null });
        }
      }
    }
    // send off value
    return parsedSeatAttrs;
  }

  /**
   * Convert seat API data to editor data
   */
  parseSeatAPIData(seats: Seat[], isCircle: boolean): SeatEditorData {
    const editorData: SeatEditorData = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      seats: []
    };
    if (seats) {
      for (const data of seats) {
        if (isCircle || (data && data.focus)) {
          // removeAlignFocusError(data);
          const pos = isCircle ? 'top' : _.invert(this.focusMap)[data.focus];
          editorData[pos] = ++editorData[pos];
        }
        editorData.seats.push(data);
      }
    }
    return editorData;
  }

  /**
   * Get the alignment of the mid-position of a seat on a rectangular table
   * @param gap The width of a gap
   * @param index The zero-based index of the seat
   */
  private rectAlignment(gap: number, index: number) {
    return gap * (2 + 3 * index);
  }

  /**
   * Calculate the alignment for seats around a circular table
   */
  private circleAlignment(angle: number) {
    const align = { x: 0, y: 0 };
    for (const key of Object.keys(align)) {
      let mult, func;
      key === 'x'
        ? ((mult = 1), (func = Math.sin))
        : ((mult = -1), (func = Math.cos));
      const frac = mult * 0.5 * func(angle);
      align[key] = this.round(frac + 0.5);
    }
    return align;
  }

  /**
   * Round the number to the current precision
   */
  private round(dec: number): number {
    return +dec.toFixed(this.precision);
  }
}
