import { schema } from 'normalizr';
import { ModifierPromptOption } from '../../pos-manager.model';

export const modifierPromptOptionsSchema =
  new schema.Entity<ModifierPromptOption>('modifierPromptOptions');
export const modifierPromptOptionsSchemaArray = new schema.Array(
  modifierPromptOptionsSchema
);

export interface NormalizedModifierPromptOption {
  id: number;
  option_type: number;
  text_label: string;
  added_price: string;
  pos_product: {
    id: number;
    name: string;
  };
  category: {
    id: number;
    name: string;
  };
}
