import { schema } from 'normalizr';
import { ModifierPromptOption } from '../../pos-manager.model';
import { modifierPromptOptionsSchema } from './normalized-modifier-prompt-option.model';

export const modifierPromptsSchema = new schema.Entity<ModifierPromptOption>(
  'modifierPrompts',
  {
    modifier_prompt_options: [modifierPromptOptionsSchema]
  }
);
export const modifierPromptsSchemaArray = new schema.Array(
  modifierPromptsSchema
);

export interface NormalizedModifierPrompt {
  id: number;
  question_label: string;
  modifier_prompt_options: number[];
}
