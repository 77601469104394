import {
  BaseData,
  Department,
  GiftCard,
  Operator,
  Printer,
  VisitingFacility
} from 'src/app/models';
import { Observable, map } from 'rxjs';

import { ApiType } from 'src/app/pos.config';
import { BaseService } from 'src/app/services/base.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class GlobalService extends BaseService {
  constructor(
    store: Store,
    private readonly http: HttpClient,
    private readonly errorService: ErrorService
  ) {
    super(store);
  }

  getPrinters() {
    return this.http.get<{ printers: Printer[] }>('printers').pipe(
      this.errorService.retryPipe(ApiType.Query),
      map((response) => response.printers || [])
    );
  }

  getCashoutReport(
    printer_id: number,
    payment_location_id: number,
    pos_operator_id: number
  ) {
    return this.http
      .post<{ success: boolean }>(this.getLocationUrl('cashout_report'), {
        printer_id,
        payment_location_id,
        pos_operator_id
      })
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.success)
      );
  }

  openCashDrawer(payment_location_id: number, pos_operator_id: number) {
    return this.http
      .post<{ success: boolean }>(this.getLocationUrl('open_cash_drawer'), {
        pos_operator_id,
        payment_location_id
      })
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.success)
      );
  }

  // async getGiftCard(identification_number: string) {
  //   const { gift_card } = await lastValueFrom(this.http.get<{ gift_card: GiftCard }>(this.getLocationUrl('gift_cards/' + identification_number)));
  //   return gift_card;
  // }

  getGiftCard(identification_number: string) {
    return this.http
      .get<{ gift_card: GiftCard }>(
        this.getLocationUrl('gift_cards/' + identification_number)
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.gift_card)
      );
  }

  getBusinessLines() {
    return this.http
      .get<{ business_lines: BaseData[] }>(
        this.getLocationUrl('business_lines')
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.business_lines || [])
      );
  }

  getDepartments(search_text?: string, business_line_id?: number) {
    const params: any = {};
    if (search_text) {
      params.search_text = search_text;
    }
    if (business_line_id) {
      params.business_line_id = business_line_id;
    }
    return this.http
      .get<{ departments: Department[] }>(this.getLocationUrl('departments'), {
        params
      })
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map(
          (response) =>
            response.departments.filter((i) => i.charge_accounts?.length) || []
        )
      );
  }

  getOperators() {
    return this.http
      .get<{ operators: Operator[] }>(this.getLocationUrl('operators'))
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.operators || [])
      );
  }

  getVisitingFacilities() {
    return this.http
      .get<{ permitted_facilities: VisitingFacility[] }>('visiting_facilities')
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.permitted_facilities || [])
      );
  }

  // Since this action is solely meant to inform the backend that the operator has logged out,
  // leading to the closure of all paid tickets from BE side (it's not a proactive action).
  // So don't need to retry that API in case it fails.
  closeFullyPaidTickets(pos_operator_id: number): Observable<boolean> {
    return this.http
      .put<{ success: true; message: string }>(
        this.getLocationUrl('close_fully_paid_tickets'),
        {
          pos_operator_id
        }
      )
      .pipe(map((response) => response.success));
  }
}
