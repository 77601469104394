import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ResetGridView } from 'src/app/store/menu/menu.action';

@Injectable({
  providedIn: 'root'
})
export class SelfServiceOrderingResolver implements Resolve<boolean> {
  constructor(private store: Store) {}

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.dispatch(new ResetGridView());
  }
}
