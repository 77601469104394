import { Injectable } from '@angular/core';
// import { datadogRum } from '@datadog/browser-rum';
import { Dictionary } from 'lodash';
import LogRocket from 'logrocket';
import moment from 'moment';

import { environment } from 'src/environments/environment';

import { CommonConstant } from '../constants';

export const LOG_ROCKET_CUSTOM_EVENTS = {
  Ticket: {
    Created: 'TicketCreated',
    Voided: 'TicketVoided',
    Cancelled: 'TicketCancelled',
    Closed: 'TicketClosed'
  },
  TicketItem: {
    Created: 'TicketItemCreated',
    Voided: 'TicketItemVoided'
  },
  Transaction: {
    Complete: 'TransactionComplete',
    Declined: 'TransactionDeclined',
    InProgress: 'TransactionInProgress'
  },
  Diner: {
    Assigned: 'DinerAssigned',
    Unassigned: 'DinerUnassigned',
    OptForMealPlan: 'DinerOptForMealPlan'
  }
};

@Injectable({
  providedIn: 'root'
})
export class LogRocketProvider {
  isInitiated = false;

  /** Login to LogRocket */
  private init() {
    let env = '';
    if (environment.MSPOS_ENVIRONMENT === 'dev') {
      env = `${CommonConstant.ENVIRONMENT.DEV}.`;
    } else if (environment.MSPOS_ENVIRONMENT === 'staging') {
      env = `${CommonConstant.ENVIRONMENT.STAGING}.`;
    }

    LogRocket.init('apeduw/mealsuite-pos-v3', {
      release: environment.MSPOS_APP_VERSION,
      dom: {
        baseHref: `https://${env}pos.mealsuite.com`
      }
    });

    this.isInitiated = true;
  }

  startNewSession() {
    // Check if LogRocket is initialized
    if (!this.isInitiated) {
      this.init();
    }
    LogRocket.startNewSession();
  }

  identifyUser(uid: string, userVars: Dictionary<any>) {
    LogRocket.identify(uid, userVars);
  }

  log(name: string, data?: any) {
    const dateTime = moment().format(
      CommonConstant.MS_DATE_TIME.DATE_TIME_FORMAT
    );
    if (environment.MSPOS_IS_DEPLOYED_BUILD) {
      LogRocket.log(name, dateTime, ' - Data: ', data);
    } else {
      console.log(name, dateTime, ' - Data: ', data);
    }
  }

  error(name: string, data?: any) {
    const dateTime = moment().format(
      CommonConstant.MS_DATE_TIME.DATE_TIME_FORMAT
    );
    if (environment.MSPOS_IS_DEPLOYED_BUILD) {
      LogRocket.error(name, dateTime, data ? { ...data } : '');
    }
  }

  //Data attribute should be an object
  track(event: string, data: any) {
    LogRocket.track(event, data);
  }
}
