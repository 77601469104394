import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { IonButton } from '@ionic/angular';

@Component({
  selector: 'pos-ssk-round-button',
  templateUrl: './ssk-round-button.component.html',
  styleUrls: ['./ssk-round-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SskRoundButtonComponent implements OnInit {
  @Input() btnLabel: string;
  @Input() iconName?: string;
  @Input() expand?: 'block' | 'full';
  @Input() isNotRound = false;
  @Input() fixedButton = true;
  @Input() faIcon?: IconDefinition;
  @Input() color?: string;
  @Input() disabled?: boolean;
  @Input()
  set fill(val: 'clear' | 'default' | 'outline' | 'solid' | undefined) {
    if (val) {
      this._fill = val;
    }
  }
  get fill() {
    return this._fill;
  }

  private _fill: 'clear' | 'default' | 'outline' | 'solid' = 'outline';

  @Output() onClick = new EventEmitter<IonButton>();

  constructor() {}

  ngOnInit(): void {}
}
