import {
  RefundTransactionRequest,
  TicketItem,
  TransactionFilter
} from 'src/app/models';

export class GetTransactions {
  static readonly type = '[Transaction Lookup] Get Transactions';
  constructor(public readonly filters: Partial<TransactionFilter> = {}) {}
}

export class ClearTransactions {
  static readonly type = '[Transaction Lookup] Clear Transactions';
}

export class SelectTransaction {
  static readonly type = '[Transaction Lookup] Select Transaction';
  constructor(public readonly transactionId: number) {}
}

export class RefundTicket {
  static readonly type = '[Transaction Lookup] Refund Ticket';
  constructor(
    public readonly ticketItems: TicketItem[],
    public readonly operatorId: number
  ) {}
}

export class RefundTransactionSuccess {
  static readonly type = '[Transaction Lookup] Refund Transaction Success';
}

export class StartManualRefund {
  static readonly type = '[Transaction Lookup] Start Manual Refund';
}

export class RefundError {
  static readonly type = '[Transaction Lookup] Refund Error';
  constructor(public readonly errorMessage: string) {}
}

export class RefundComplete {
  static readonly type = '[Transaction Lookup] Refund Complete';
}

export class ClearRefundState {
  static readonly type = '[Transaction Lookup] Clear Refund State';
}

export class AddRefundTransaction {
  static readonly type = '[Transaction Lookup] Add Refund Transaction';
  constructor(public refundTransactionRequest: RefundTransactionRequest) {}
}

export class AddRefundTransactionSuccess {
  static readonly type = '[Transaction Lookup] Add Refund Transaction Success';
  constructor(public refundTransactionRequest: RefundTransactionRequest) {}
}

export class DeleteRefundTicket {
  static readonly type = '[Ticket] Delete Refund Ticket';
  constructor(
    public device_ticket_uuid: string,
    public refundParams: {
      ticketItems: TicketItem[];
      operatorId: number;
    } = null
  ) {}
}
