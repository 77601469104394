<ion-header>
  <ion-toolbar mode="md"
               color="dark">
    <ion-title> Edit Table
    </ion-title>
    <ion-buttons slot="end">
      <ion-button mode="md"
                  debounce-click
                  [debounceTime]="0"
                  (debounceClick)="onCloseModal()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="table-information">
    <ion-item lines="none">
      <ion-label>Name:</ion-label>
      <ion-label slot="end">{{table.name}}</ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label>Number of Seats:</ion-label>
      <ion-label slot="end">{{table.max_number_of_seats}}</ion-label>
    </ion-item>

    <ion-item lines="none">
      <ion-label>Table Shape:</ion-label>
      <ion-label slot="end">{{table.tableshape | titlecase}}</ion-label>
    </ion-item>
  </div>

  <ng-container *ngIf="table.tableshape">
    <ion-item-divider>
      No. of seats {{ table.tableshape !== TABLE_SHAPE.CIRCLE ? 'on each side of' : 'around'}} table
    </ion-item-divider>
    <ion-grid class="chairs-grid ion-margin-vertical">
      <!-- Above -->
      <ion-row>
        <ion-col class="seat ion-no-padding"
                 size="4"
                 offset="4">
          <ion-input class="ion-margin"
                     min="0"
                     type="number"
                     [(ngModel)]="editorData.top"></ion-input>
        </ion-col>
      </ion-row>
      <!-- Beside -->
      <ion-row class="display-flex ion-justify-content-center">
        <ion-col class="seat ion-no-padding"
                 *ngIf="table.tableshape !== TABLE_SHAPE.CIRCLE">
          <ion-input class="ion-margin"
                     min="0"
                     type="number"
                     [(ngModel)]="editorData.left"></ion-input>
        </ion-col>
        <ion-col class="ion-no-padding">
          <div class="shape ion-text-center">
            <svg [style.width.px]="table.tableshape ===  TABLE_SHAPE.RECTANGLE ? 200 : 100"
                 [style.height.px]="100"
                 [ngSwitch]="table.tableshape">
              <rect *ngSwitchDefault
                    [attr.width]='table.tableshape ===  TABLE_SHAPE.RECTANGLE ? 200 : 100'
                    [attr.height]='"100px"'
                    fill="#F19520" />
              <circle *ngSwitchCase="TABLE_SHAPE.CIRCLE"
                      [attr.cx]='50'
                      [attr.cy]='50'
                      [attr.r]='50'
                      fill="#F19520" />
            </svg>
          </div>

        </ion-col>
        <ion-col class="seat ion-no-padding"
                 *ngIf="table.tableshape !== TABLE_SHAPE.CIRCLE">
          <ion-input class="ion-margin"
                     min="0"
                     type="number"
                     [(ngModel)]="editorData.right"></ion-input>
        </ion-col>
      </ion-row>
      <!-- Below -->
      <ion-row *ngIf="table.tableshape !== TABLE_SHAPE.CIRCLE">
        <ion-col class="seat"
                 size="4"
                 offset="4">
          <ion-input class="ion-margin"
                     min="0"
                     type="number"
                     [(ngModel)]="editorData.bottom"></ion-input>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
</ion-content>

<ion-footer>
  <ion-toolbar mode="md"
               color="light">
    <ion-buttons slot="end">
      <ion-button mode="md"
                  type="primary"
                  color="orange"
                  fill="solid"
                  [disabled]="!isEqualMaxSeats()"
                  debounce-click
                  [debounceTime]="0"
                  (debounceClick)="saveTable()">Save</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
