import {
  POSSchedule,
  POSScheduleRequest
} from 'src/app/models/pos-manager.model';

import { POSServiceCalendarException } from 'src/app/models/pos-manager.model';

export class GetSchedules {
  static readonly type = '[POS Manager Schedule] Get Schedules';
}

export class GetSchedulesSuccess {
  static readonly type = '[POS Manager Schedule] Get Schedules Success';
  constructor(public readonly schedules: POSSchedule[]) {}
}

export class SelectScheduleDetail {
  static readonly type = '[POS Manager Schedule] Select Schedule Detail';
  constructor(public readonly scheduleId: number) {}
}

export class CreatePOSSchedule {
  static readonly type = '[POS Manager Schedule] Create POS Schedule';
  constructor(public schedule: POSScheduleRequest) {}
}

export class CreatePOSScheduleSuccess {
  static readonly type = '[POS Manager Schedule] Create POS Schedule Success';
  constructor(public schedule: POSSchedule) {}
}

export class UpdatePOSSchedule {
  static readonly type = '[POS Manager Schedule] Update POS Schedule';
  constructor(
    public readonly id: number,
    public readonly schedule: POSScheduleRequest
  ) {}
}

export class UpdatePOSScheduleSuccess {
  static readonly type = '[POS Manager Schedule] Update POS Schedule Success';
  constructor(public schedule: POSSchedule) {}
}

export class DeletePOSSchedule {
  static readonly type = '[POS Manager Schedule] Delete POS Schedule';
  constructor(public id: number) {}
}

export class DeletePOSScheduleSuccess {
  static readonly type = '[POS Manager Schedule] Delete POS Schedule Success';
  constructor() {}
}

export class SetPOSScheduleLoaded {
  static readonly type = '[POS Manager Schedule] Set POS Schedule Loaded';
  constructor(public isLoaded: boolean) {}
}

export class CreatePOSServiceCalendarException {
  static readonly type =
    '[POS Manager Schedule] Create POS Service Calendar Exception';
  constructor(public exception: POSServiceCalendarException) {}
}

export class CreatePOSServiceCalendarExceptionSuccess {
  static readonly type =
    '[POS Manager Schedule] Create POS Service Calendar Exception Success';
  constructor(public exception: POSServiceCalendarException) {}
}

export class UpdatePOSServiceCalendarException {
  static readonly type =
    '[POS Manager Schedule] Update POS Service Calendar Exception';
  constructor(
    public readonly index: number,
    public readonly exception: POSServiceCalendarException
  ) {}
}

export class UpdatePOSServiceCalendarExceptionSuccess {
  static readonly type =
    '[POS Manager Schedule] Update POS Service Calendar Exception Success';
  constructor(public exception: POSServiceCalendarException) {}
}

export class DeletePOSServiceCalendarException {
  static readonly type =
    '[POS Manager Schedule] Delete POS Service Calendar Exception';
  constructor(public readonly index: number) {}
}

export class DeletePOSServiceCalendarExceptionSuccess {
  static readonly type =
    '[POS Manager Schedule] Delete POS Service Calendar Exception Success';
  constructor() {}
}

export class SelectException {
  static readonly type = '[POS Manager Schedule] Select Exception';
  constructor(public readonly exception: POSServiceCalendarException | null) {}
}
