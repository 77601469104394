import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { IonMenu } from '@ionic/angular';
import { Observable } from 'rxjs';

import { PosMenuController } from './pos-menu.controller';

@Component({
  selector: 'pos-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
  @Input() set menuId(id: string) {
    this._controller.setMenuId(id);
  }

  get menuId$(): Observable<string> {
    return this._controller.menuId$;
  }

  // @ViewChild('menu', { static: false }) ionMenu: IonMenu;

  readonly portal$ = this._controller.portal$;
  readonly menuAttrs$ = this._controller.menuAttrs$;

  constructor(private readonly _controller: PosMenuController) {}

  onMenuClose() {
    this._controller.clearView();
  }
}
