import { FireOrderData } from 'src/app/models';

export class FireCourseNotificationEvent {
  static readonly type = '[Websocket] Fire Course Notification Event';
  constructor(public readonly data: FireOrderData) {}
}

export class FireCourseSeatNotificationEvent {
  static readonly type = '[Websocket] Fire Course Seat Notification Event';
  constructor(public readonly data: FireOrderData) {}
}

export class FireTicketNotificationEvent {
  static readonly type = '[Websocket] Fire Ticket Notification Event';
  constructor(public readonly data: FireOrderData) {}
}

export class FireTicketItemNotificationEvent {
  static readonly type = '[Websocket] Fire Ticket Item Notification Event';
  constructor(public readonly data: FireOrderData) {}
}

export class FireTableNotificationEvent {
  static readonly type = '[Websocket] Fire Table Notification Event';
  constructor(public readonly data: FireOrderData) {}
}
