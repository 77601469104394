import { BaseData } from './base.model';
import { Product } from './menu.model';
import { PosQuantityRestriction } from './pos-manager.model';

export enum MacroGridType {
  FOOD = 'food',
  PRODUCTS = 'products',
  MODIFIER = 'modifiers'
}

export enum MacroGridItemType {
  CATEGORY = 'category',
  SUBPAGE = 'subpage',
  PRODUCT = 'product',
  MODIFIER = 'modifier',
  EMPTY = 'empty'
}

export enum MacroGridView {
  MAIN = 'main',
  CATEGORY = 'category',
  ITEMS = 'items'
}

export interface MacroGridList extends BaseData {
  type: MacroGridType;
}

export interface MacroGrid extends BaseData {
  type: MacroGridType;
  image: string;
  macro_grid_items?: MacroGridItem[];
  sort?: number;
  color?: string;
}

export interface MacroGridItem extends BaseData {
  type: MacroGridItemType;
  color?: string;
  image?: string;
  sort?: number;
  child_items?: MacroGridItem[];
  category_details?: CategoryDetail;
  modifier_details?: ModifierDetail;
  product_details?: Product;
  quantity_restriction?: PosQuantityRestriction;
}

export interface CategoryDetail extends BaseData {
  category_id: number;
  show_also_available_menu: boolean;
  show_non_food_products: boolean;
  show_standard_menu_items: boolean;
}

export interface ModifierDetail {
  open_text_modifier: boolean;
  price_increase: number;
}
