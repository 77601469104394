import { CommonConstant } from 'src/app/constants';

export class DeviceHelper {
  static getWebViewVersionNumber(webViewVersion: string): number {
    return Number(webViewVersion.split('.')[0]);
  }

  static isOldWebViewVersion(webViewVersion: string): boolean {
    return (
      DeviceHelper.getWebViewVersionNumber(webViewVersion) <
      CommonConstant.MIN_WEBVIEW_VERSION
    );
  }

  static isEloI3 = (deviceModel: string): boolean => {
    const model = deviceModel.toLowerCase();
    return ['elo', 'i3'].every((i) => model.includes(i));
  };
}
