import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { StripeTransactionModal } from './stripe-transaction.modal';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';

@NgModule({
  declarations: [StripeTransactionModal],
  imports: [CommonModule, IonicModule, DebounceClickDirectiveModule],
  exports: [StripeTransactionModal]
})
export class StripeTransactionModalModule {}
