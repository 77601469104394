import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Idle } from '@ng-idle/core';

import { ReactiveComponent } from 'src/app/components/shared/reactive-component';

@Component({
  templateUrl: './timeout.modal.html',
  styleUrls: ['./timeout.modal.scss']
})
export class TimeoutModal extends ReactiveComponent {
  @Input() countdown: number;
  @Input() idle: Idle;

  dismiss = () => this.viewCtrl.dismiss().catch(() => {});

  constructor(private viewCtrl: ModalController) {
    super();
  }

  *onSubscribe() {
    // Subscribe to the seconds before timeout in seconds for display
    yield this.idle.onTimeoutWarning.subscribe(
      (countdown: string | number) => (this.countdown = +countdown)
    );

    // Close modal when idle ends or idle times out
    yield this.idle.onIdleEnd.subscribe(this.dismiss);
    yield this.idle.onTimeout.subscribe(this.dismiss);
  }
}
