import { PaymentLocation, PosLocation } from 'src/app/models';

export class GetLocation {
  static readonly type = '[Location] Get Location';
  constructor(public readonly locationId: number) {}
}

export class GetLocationSuccess {
  static readonly type = '[Location] Get Location Success';
  constructor(public readonly location: PosLocation) {}
}

export class GetLocations {
  static readonly type = '[Location] Get Locations';
}

export class UpdateSelectedMealId {
  static readonly type = '[Location] Update Selected Meal Id';
  constructor(
    public readonly meal_id: number = null,
    public readonly date: string
  ) {}
}

export class SetCurrentMealId {
  static readonly type = '[Location] Set Current Meal Id';
  constructor(public readonly mealId: number) {}
}

export class UpdatePaymentLocations {
  static readonly type = '[Location] Update Payment Locations';
  constructor(public readonly paymentLocations: PaymentLocation[]) {}
}

export class CheckValidPaymentLocations {
  static readonly type = '[Location] Check Valid Payment Locations';
}

export class CheckValidPaymentLocationsSuccess {
  static readonly type = '[Location] Check Valid Payment Locations Success';
  constructor(public readonly paymentLocations: PaymentLocation[]) {}
}
