import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelfServiceInputSecureCodeModal } from './self-service-input-secure-code.modal';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SelfServiceInputSecureCodeModal],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    DebounceClickDirectiveModule
  ],
  exports: [SelfServiceInputSecureCodeModal]
})
export class SelfServiceInputSecureCodeModalModule {}
