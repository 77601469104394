import { PendingAssignment, TableReservation } from './../floorplan.model';
import { schema } from 'normalizr';
import { Table, TableDetailPendingSeatAssignment, TableShape } from '..';
import { seatsSchema } from './normalized-seat.model';

export const tableSchema = new schema.Entity<Table>('tables', {
  seats: [seatsSchema]
});
export const tablesArraySchema = new schema.Array(tableSchema);

export enum PosTableStatus {
  Available = 'available',
  Reserved = 'reserved',
  NoOrderTaken = 'no_order_taken',
  PendingItems = 'pending_items',
  AllItemsFired = 'all_items_fired'
}

export interface NormalizedTable {
  id: number;
  name: string;
  sort: number;
  status: PosTableStatus;
  tableshape: TableShape;
  location: string;
  assigned_staff_id: number;
  assigned_staff_name: string;
  seats: number[];
  pending_seat_assignments: (
    | TableDetailPendingSeatAssignment
    | PendingAssignment
  )[];
  category?: string;
  occupied?: boolean;
  max_number_of_seats?: number;
  extraPropTableStatusName?: string;
  extraPropTableStatusClass?: string;
  extraPropTimeSeated?: string;
  isSelected?: boolean;
  local_attributes?: {
    status_name: string;
    status_class: string;
    time_seated: string;
  };
  reservations: TableReservation[];
}
