import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';

import { MealHelper } from 'src/app/helpers';
import { PosLocation, LocationList, LocationType } from 'src/app/models';
import { LocationService, ModalService } from 'src/app/services';

import {
  CheckValidPaymentLocations,
  CheckValidPaymentLocationsSuccess,
  GetLocation,
  GetLocationSuccess,
  GetLocations,
  SetCurrentMealId,
  UpdatePaymentLocations,
  UpdateSelectedMealId
} from './location.action';
import {
  GetMacroGridDetail,
  GetMenu,
  SetSelectedMealId
} from '../menu/menu.action';
import { LoginSelfService, SelectPaymentLocation } from '../app/app.action';
import { NavController } from '@ionic/angular';
import { PageConstant } from 'src/app/constants';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { patch } from '@ngxs/store/operators';
import {
  Subject,
  concat,
  forkJoin,
  from,
  interval,
  retry,
  switchMap,
  takeUntil,
  tap,
  throwError
} from 'rxjs';
import { PaymentLocationsWarningModal } from 'src/app/modals/payment-locations/pos-payment-locations-warning.modal';
import _ from 'lodash';

export interface LocationStateModel {
  location: PosLocation;
  locations: LocationList[];
  currentMealId: number | null;
}

export const LOCATION_STATE_TOKEN = new StateToken<LocationStateModel>(
  'location'
);

@State({
  name: LOCATION_STATE_TOKEN,
  defaults: {
    location: null,
    locations: [],
    currentMealId: null
  }
})
@Injectable()
export class LocationState {
  constructor(
    private readonly navController: NavController,
    private readonly locationService: LocationService,
    private readonly store: Store,
    private readonly modalService: ModalService
  ) {}

  //#region Actions

  @Action(GetLocation)
  getLocation(
    ctx: StateContext<LocationStateModel>,
    { locationId }: GetLocation
  ) {
    return this.locationService
      .getLocationById(locationId)
      .pipe(tap((location) => ctx.dispatch(new GetLocationSuccess(location))));
  }

  @Action(GetLocationSuccess)
  getLocationSuccess(
    ctx: StateContext<LocationStateModel>,
    { location }: GetLocationSuccess
  ) {
    const meal_id = MealHelper.getMealIdByTime(location.meal_end_times);
    const actions: any[] = [];
    const preDispatchedActions: any[] = [];
    const isSelfService = location.type === LocationType.SelfServe;
    const route = isSelfService
      ? PageConstant.SELF_SERVICE_SETUP_PAGE
      : PageConstant.LOGIN_PAGE;
    const paymentLocations = location?.payment_locations;

    ctx.patchState({
      location,
      currentMealId: meal_id
    });

    if (paymentLocations.length) {
      const default_payment_location_id =
        this.store.selectSnapshot(APP_STATE_TOKEN).device
          .default_payment_location_id;
      const firstPaymentLocationId = _.first(paymentLocations).id;

      actions.push(
        new SelectPaymentLocation(
          default_payment_location_id
            ? _.find(
                paymentLocations,
                (x) => x.id === default_payment_location_id
              )?.id || firstPaymentLocationId
            : firstPaymentLocationId
        )
      );
    }

    if (isSelfService) {
      preDispatchedActions.push(
        new LoginSelfService(location.self_serve_settings.default_operator_id)
      );
    }

    return concat([
      forkJoin([
        from(
          this.navController.navigateRoot(route, {
            animationDirection: 'forward'
          })
        ),
        ctx.dispatch(preDispatchedActions)
      ]),
      ctx.dispatch(actions)
    ]);
  }

  @Action(GetLocations)
  async getLocations({ patchState }: StateContext<LocationStateModel>) {
    const locations = await this.locationService.getLocations();
    patchState({ locations });
  }

  @Action(UpdateSelectedMealId, { cancelUncompleted: true })
  updateSelectedMealId(
    { dispatch }: StateContext<LocationStateModel>,
    { meal_id, date }: UpdateSelectedMealId
  ) {
    const actions: any[] = [
      new GetMenu(meal_id, date),
      new GetMacroGridDetail(meal_id)
    ];

    if (meal_id) {
      actions.push(new SetSelectedMealId(meal_id));
    }

    return dispatch(actions);
  }

  @Action(SetCurrentMealId)
  setCurrentMealId(
    { patchState }: StateContext<LocationStateModel>,
    { mealId: currentMealId }: SetCurrentMealId
  ) {
    patchState({ currentMealId });
  }

  @Action(UpdatePaymentLocations)
  updatePaymentLocations(
    { dispatch, setState }: StateContext<LocationStateModel>,
    { paymentLocations }: UpdatePaymentLocations
  ) {
    if (paymentLocations.length) {
      const default_payment_location_id =
        this.store.selectSnapshot(APP_STATE_TOKEN).device
          .default_payment_location_id;
      const paymentLocation = !!default_payment_location_id
        ? paymentLocations.find((i) => i.id === default_payment_location_id)
        : paymentLocations[0];

      dispatch(new SelectPaymentLocation(paymentLocation.id));
    }
    setState(
      patch({
        location: patch({
          payment_locations: paymentLocations
        })
      })
    );
  }

  @Action(CheckValidPaymentLocations)
  checkValidPaymentLocations(ctx: StateContext<LocationStateModel>) {
    const location = ctx.getState().location;
    const isSSK = location.type === LocationType.SelfServe;

    //Reset this flag
    const cancelObservable = new Subject<void>();

    this.modalService.showModal<boolean>(
      PaymentLocationsWarningModal,
      {
        title: 'No payment locations found',
        description:
          'At least one payment location must be setup for this point of sale location.'
      },
      {
        backdropDismiss: false,
        cssClass: isSSK
          ? ['payment-locations-warning-modal', 'dynamic-height-modal']
          : 'dynamic-height-modal'
      },
      () => {
        cancelObservable.next();
        cancelObservable.complete();
      }
    );

    const locationId = location.id;

    return this.locationService.getLocation(locationId).pipe(
      switchMap(({ payment_locations }) =>
        payment_locations.length
          ? ctx.dispatch(
              new CheckValidPaymentLocationsSuccess(payment_locations)
            )
          : throwError(
              () =>
                new Error('Invalid payment locations, retry after 5 seconds')
            )
      ),
      retry({
        delay: () => interval(5000).pipe(takeUntil(cancelObservable))
      })
    );
  }

  @Action(CheckValidPaymentLocationsSuccess)
  checkValidPaymentLocationsSuccess(
    { dispatch }: StateContext<LocationStateModel>,
    payload: CheckValidPaymentLocationsSuccess
  ) {
    this.modalService.closeAllModals();
    return dispatch(new UpdatePaymentLocations(payload.paymentLocations));
  }
  //#endregion
}
