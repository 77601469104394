import { Diagram, ObjectData } from 'gojs';

import {
  PendingAssignment,
  PendingAssignmentStatusChangeType,
  PosTable,
  PosTableStatus,
  Seat,
  Table,
  TableReservation,
  TicketEventData
} from 'src/app/models';

export class GetTables {
  static readonly type = '[Table] Get Tables';
}

export class GetTable {
  static readonly type = '[Table] Get Table';
  constructor(public readonly tableId: number) {}
}

export class GetTablesSuccess {
  static readonly type = '[Table] Get Tables Success';
  constructor(public tables: Table[]) {}
}

export class GetTableSuccess {
  static readonly type = '[Table] Get Table Success';
  constructor(
    public readonly table: PosTable,
    public readonly tables: PosTable[]
  ) {}
}

export class SelectTable {
  static readonly type = '[Table] Select a Table';
  constructor(public readonly tableId?: number) {}
}

export class AddTemporarySeat {
  static readonly type = '[Table] Add a Temporary Seat';
}

export class AddTemporarySeatSuccess {
  static readonly type = '[Table] Add a Temporary Seat Success';
  constructor(public seat: Seat) {}
}

export class RemoveTemporarySeat {
  static readonly type = '[Table] Remove a Temporary Seat';
  constructor(public tableId: number, public seatId: number) {}
}

export class UpdateTableStatus {
  static readonly type = '[Table] Update Table Status';
  constructor(public tableId: number) {}
}

export class UpdateTableAssignment {
  static readonly type = '[Table Assignment] Update Table Assignment';
  constructor(public table_ids: number[], public operator_id?: number) {}
}

export class UpdateDataAfterAssignment {
  static readonly type =
    '[Table Assignment] Update Table Data After Assignment';
  constructor(public table_ids: number[], public operator_id?: number) {}
}

export class GetTableManagementFloorPlan {
  static readonly type = '[Table Management] Get floor plan';
  constructor(public locationId: number) {}
}

export class UpdateSelectedTableEditorData {
  static readonly type = '[Table Management] Update selected table editor data';
  constructor(public readonly table: Table) {}
}

export class EditSelectedTableSeat {
  static readonly type = '[Table Management] Edit selected table seat';
  constructor(public table: Table, public diagram: Diagram) {}
}

export class SaveFloorPlanTables {
  static readonly type = '[Table Management] Save floor plan tables';
  constructor(public tables: ObjectData[]) {}
}

export class UpdateFloorPlanEditingStatus {
  static readonly type = '[Table Management] Update floor plan edit status';
  constructor(public isEditing: boolean) {}
}

export class FireTable {
  static readonly type = '[Table] Fire a Table';
  constructor(
    public readonly tableId: number,
    public readonly course?: number
  ) {}
}

export class ToggleTablesListVisibility {
  static readonly type = '[Table] Toggle Tables List Visibility';
}

export class UpdateLocalTables {
  static readonly type = '[Table] Update Local Tables';
  constructor(public tables: PosTable[]) {}
}

export class UpdatePendingSeatAssignment {
  static readonly type = '[Table] Update Pending Seat Assignment';
  constructor(
    public type: PendingAssignmentStatusChangeType,
    public data: {
      createdItems?: PendingAssignment[];
      deletedId?: number;
      tableId?: number;
    }
  ) {}
}

export class SelectASeat {
  static readonly type = '[Table Page] Select A Seat';
  constructor(public readonly seatId: number) {}
}

export class SelectASeatSuccess {
  static readonly type = '[Table Page] Select A Seat Success';
}

export class DeselectASeat {
  static readonly type = '[Table Page] Deselect A Seat';
}

export class SelectNewTable {
  static readonly type = '[Table] Select New Table';
  constructor(public readonly tableId: number) {}
}

export class TableStatusChangedWs {
  static readonly type = '[Table] Table Status Changed Websocket';
  constructor(
    public readonly tableId: number,
    public readonly status: PosTableStatus
  ) {}
}

export class FloorplanOrderUpdatedWS {
  static readonly type = '[Table] Floor Plan Order Updated Websocket';
  constructor(public readonly data: TicketEventData) {}
}

export class PendingSeatAssignmentSeatedWsEvent {
  static readonly type =
    '[Websocket Event] Pending Seat Assignment Seated Ws Event';
  constructor(public readonly id: number, public readonly table_id: number) {}
}

export class TicketCreatedWsEvent {
  static readonly type = '[Websocket Event] Ticket Created Ws Event';
  constructor(public readonly data: TicketEventData) {}
}

export class ReservationCreatedWsEvent {
  static readonly type = '[Websocket Event] Reservation Created Ws Event';
  constructor(public readonly wsData: TableReservation) {}
}

export class UnSelectTable {
  static readonly type = '[Table] Unselect Table';
}
