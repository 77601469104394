import { schema } from 'normalizr';
import {
  BaseData,
  ChargeAccount,
  Diet,
  Fluid,
  MealPlan,
  Texture
} from './base.model';
import { PendingAssignment, SeatedDiner } from 'src/app/models/floorplan.model';
import { HostModeDiner } from 'src/app/models/host-mode.model';

export enum DinerType {
  Resident = 'resident',
  Diner = 'diner',
  Staff = 'staff',
  Guest = 'guest'
}

export interface BaseDiner {
  id: number;
  first_name: string;
  last_name: string;
  type?: DinerType;
  live_location?: string;
  client_id?: number;
  ehr_internal_id?: number;
  ehr_external_id?: number;
  badge_id?: number;
  mealsuite_id?: number;
  photo?: string;
  diner_category_id?: number;
  diner_category_name?: string;
}

export interface SimpleDiner {
  id: number;
  name?: string;
  first_name?: string;
  last_name?: string;
}

export interface Diner extends BaseDiner {
  allergens?: string;
  breakfast_note?: string;
  lunch_note?: string;
  dinner_note?: string;
  daily_note?: string;
  misc_note?: string;
  diets?: Diet[];
  textures?: Texture[];
  fluid?: Fluid;
  charge_accounts?: ChargeAccount[];
  meal_plan?: MealPlan;
  diet_interventions?: DietIntervention[];
  local_attributes?: {
    dietOrderName?: string;
    dietInterventions?: string;
    dietTypes?: string;
  };
}

export interface DietIntervention extends BaseData {
  intervention_id: number;
}

export const GUEST_DINER: Diner = {
  id: 0,
  mealsuite_id: null,
  first_name: 'Guest',
  last_name: ''
};

export const dinerSchema = new schema.Entity('diners');

export type PosDiner = HostModeDiner | PendingAssignment | SeatedDiner;
