import { Dictionary } from 'lodash';
import { TableShape, TableStatusName, TicketStatus } from '../models';

export class TableConstant {
  static readonly BACKGROUND_TEXT_COLORS = [
    { background: '#CBCDD0', text: '#2C333E' }, // default
    { background: '#3A94C6', text: '#FFFFFF' }, // light blue / white => default color for the table assigned when the starting table assignments
    { background: '#F3E0BE', text: '#2C333E' }, // vanilla custard / black
    { background: '#85AD3A', text: '#FFFFFF' }, // green / white
    { background: '#AB2918', text: '#FFFFFF' }, // red / white
    { background: '#EF7D10', text: '#FFFFFF' }, // orange / white
    { background: '#00A170', text: '#FFFFFF' }, // mint / white
    { background: '#5B5A30', text: '#FFFFFF' }, // rust / white
    { background: '#E9897E', text: '#FFFFFF' }, // burnt corald / white
    { background: '#926AA6', text: '#FFFFFF' }, // amethyst orchid / white
    { background: '#00758F', text: '#FFFFFF' }, // mosaic blue / white
    { background: '#D2386C', text: '#FFFFFF' }, // raspberry sorbet / white
    { background: '#83C08A', text: '#2C333E' }, // light green 2 / black
    { background: '#EDD59E', text: '#2C333E' }, // sunlight / black
    { background: '#4B5335', text: '#FFFFFF' }, // chive / white
    { background: '#F5BF24', text: '#2C333E' }, // yellow / black
    { background: '#800080', text: '#FFFFFF' } // purple / white
  ];

  static readonly TABLE_STATUS = {
    EMPTY_TABLE: TableStatusName.Available,
    EMPTY_COURSES: TableStatusName.NoOrdersTaken,
    PENDING_COURSES: TableStatusName.PendingCourse,
    PENDING_ITEM: TableStatusName.PendingItem,
    COMPLETED_COURSES: TableStatusName.AllCoursesFired,
    COMPLETED_ITEMS: TableStatusName.AllItemsFired
  };

  static readonly SEAT_STATUS: Dictionary<string> = {
    EMPTY_COURSES: 'No orders taken',
    PENDING_COURSES: 'Pending course',
    FIRED: 'Fired'
  };

  static readonly EMPTY_TICKET_STATUS = [
    // TicketStatus.EMPTY: this status is mapping with pending items
    TicketStatus.CLOSED,
    TicketStatus.CANCELLED,
    TicketStatus.PAID,
    TicketStatus.VOIDED
  ];

  static readonly DEFAULT_FILL = '#CBCDD0';

  static readonly BLACK_COLOR = '#2C333E';
  static readonly TABLE_FOCUS_COLOR = '#F9F9F9';
  static readonly WHITE_COLOR = '#FFFFFF';
  static readonly RESERVATION_COLOR = '#C3CED6';
  static readonly SEAT_PENDING_COURSE_FILL = '#F5BF24';
  static readonly SEAT_EMPTY_COURSE_FILL = '#AB2918';
  static readonly SEAT_COMPLETE_COURSE_FILL = '#85AD3A';

  static readonly SELECTED_BACKGROUND = '#C6EAFF';
  static readonly SELECTED_HIGHLIGHT = '#1E83C3';

  static readonly TEXT_PROPERTY = 'text';
  static readonly BACKGROUND_PROPERTY = 'background';

  static readonly TABLE_SHAPE = {
    SQUARE: 'square',
    RECTANGLE: 'rectangle',
    CIRCLE: 'circle'
  };

  static readonly TABLE_STYLES = {
    [TableShape.Circle]: {
      width: 100,
      figure: 'Ellipse'
    },
    [TableShape.Rectangle]: {
      width: 200,
      figure: 'Rectangle'
    },
    [TableShape.Square]: {
      width: 100,
      figure: 'Rectangle'
    }
  };

  static readonly GRAY_COLOR = '#EDEDED';
  static readonly BORDER_COLOR = '#CCCCCC';
}
