import { RecurrenceType } from './service-calendar.model';

export interface FrequencyControlValue {
  value: number;
  type:
    | RecurrenceType.DAILY
    | RecurrenceType.WEEKLY
    | RecurrenceType.MONTHLY
    | RecurrenceType.YEARLY;
}

export enum AppointmentRecurringEndType {
  END_BY = 'end_by',
  END_AFTER = 'end_after',
  NO_END_DATE = 'no_end_date'
}

export interface AppointmentRecurringEndControlValue {
  type: AppointmentRecurringEndType;
  end_by: string;
  end_after: number;
}

export interface RecurringControlValue {
  isAllow: 'false' | 'true';
  frequency: FrequencyControlValue;
  recurring_end: AppointmentRecurringEndControlValue;
}
