import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';

import { POSManagerOperator } from 'src/app/models/pos-manager.model';
import { ApiType } from 'src/app/pos.config';
import { ErrorService } from 'src/app/services/error/error.service';
import { AppStateHelper } from 'src/app/store/app/app.state.helper';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';

@Injectable({ providedIn: 'root' })
export class PosOperatorService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private readonly errorService: ErrorService
  ) {}
  private getBaseUrl() {
    const baseUrl = this.store.selectSnapshot(APP_STATE_TOKEN).apiBaseUrl;
    const posManagerApiUrl = AppStateHelper.getPosManagerApiUrl(baseUrl);

    return `${posManagerApiUrl}pos_operators`;
  }

  getOperators(): Observable<POSManagerOperator[]> {
    return this.http
      .get<{ pos_operators: POSManagerOperator[] }>(`${this.getBaseUrl()}`)
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.pos_operators)
      );
  }

  createOperator(operator: POSManagerOperator): Observable<POSManagerOperator> {
    return this.http
      .post<POSManagerOperator>(`${this.getBaseUrl()}`, operator)
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  updateOperator(operator: POSManagerOperator): Observable<POSManagerOperator> {
    return this.http
      .put<POSManagerOperator>(`${this.getBaseUrl()}/${operator.id}`, operator)
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  deleteOperator(id: number): Observable<boolean> {
    return this.http
      .delete<{ success: boolean }>(`${this.getBaseUrl()}/${id}`)
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.success)
      );
  }
}
