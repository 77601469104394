import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BaseProvider {
  constructor(private alertCtrl: AlertController) {}

  async popupAlert(title, message, handler = () => null) {
    const alert = await this.alertCtrl.create({
      header: title,
      message,
      cssClass: 'alert-message',
      backdropDismiss: true,
      buttons: [
        {
          text: 'OK',
          role: 'ok',
          handler: () => {
            if (handler) {
              handler();
            }
          }
        }
      ]
    });
    alert.present();
  }

  async popupConfirm(title, message, yesHandler, noHandler?) {
    const confirm = await this.alertCtrl.create({
      header: title,
      message,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            if (noHandler) {
              noHandler();
            }
          }
        },
        {
          text: 'Yes',
          role: 'ok',
          handler: () => {
            if (yesHandler) {
              yesHandler();
            }
          }
        }
      ]
    });
    confirm.present();
  }
}
