import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Ticket } from '../models';
import { ErrorService } from 'src/app/services/error/error.service';
import { ApiType } from 'src/app/pos.config';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseService {
  constructor(
    private http: HttpClient,
    store: Store,
    private readonly errorService: ErrorService
  ) {
    super(store);
  }

  //Endpoint used to delete a Gateway Stripe Transaction Only - does NOT support Datacap
  cancelGatewayTransaction(
    ticketUuid: string,
    gatewayTransactionId: number
  ): Observable<{
    success: boolean;
    message?: string;
    payment_received?: boolean;
    error_message?: string;
  }> {
    return this.http
      .delete<{
        success: boolean;
        message?: string;
        payment_received?: boolean;
        error_message?: string;
      }>(
        this.getLocationUrl(
          `tickets/${ticketUuid}/payment_gateway_transactions/${gatewayTransactionId}`
        )
      )
      .pipe(map((response) => response));
  }

  payOnPickup(deviceTicketUuid: string) {
    return this.http
      .post<{ success: boolean; ticket: Ticket }>(
        this.getLocationUrl(`tickets/${deviceTicketUuid}/pay_on_pickup`),
        {}
      )
      .pipe(map((response) => response.ticket));
  }

  finalizeMealPlanTicket(
    deviceTicketUuid: string
  ): Observable<{ success: boolean; ticket: Ticket }> {
    return this.http
      .post<{ success: boolean; ticket: Ticket }>(
        this.getLocationUrl(
          `tickets/${deviceTicketUuid}/finalize_mealplan_order`
        ),
        {},
        { params: { loadingText: 'Closing ticket...' } }
      )
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }
}
