import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TimePickerComponent } from './time-picker.component';
import { IonicModule } from '@ionic/angular';
import { MealDeliveryTimingPipe } from 'src/app/pipes/meal-delivery-timing-pipe/meal-delivery-timing.pipe';

@NgModule({
  declarations: [TimePickerComponent, MealDeliveryTimingPipe],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IonicModule],
  exports: [TimePickerComponent]
})
export class TimePickerComponentModule {}
