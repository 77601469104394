import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { GatewayTransactionModal } from './gateway-transaction.modal';

@NgModule({
  declarations: [GatewayTransactionModal],
  imports: [CommonModule, IonicModule, DebounceClickDirectiveModule],
  exports: [GatewayTransactionModal]
})
export class GatewayTransactionModalModule {}
