import { Observable, map } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { POSManagerService } from '../../../models/pos-manager.model';
import { Store } from '@ngxs/store';
import { ErrorService } from 'src/app/services/error/error.service';
import { ApiType } from 'src/app/pos.config';
import { ProductType } from 'src/app/models';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { AppStateHelper } from 'src/app/store/app/app.state.helper';

@Injectable({
  providedIn: 'root'
})
export class PosManagerServiceService {
  private SERVICES_API = 'pos_products';
  constructor(
    private http: HttpClient,
    private store: Store,
    private readonly errorService: ErrorService
  ) {}
  private getBaseUrl() {
    const baseUrl = this.store.selectSnapshot(APP_STATE_TOKEN).apiBaseUrl;
    const posManagerApiUrl = AppStateHelper.getPosManagerApiUrl(baseUrl);

    return `${posManagerApiUrl}${this.SERVICES_API}`;
  }

  public getServices(): Observable<POSManagerService[]> {
    return this.http
      .get<{ products: POSManagerService[] }>(`${this.getBaseUrl()}`)
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) =>
          response.products.filter(
            (item) => item.product_type === ProductType.SERVICE
          )
        )
      );
  }

  public createService(
    service: Partial<POSManagerService>
  ): Observable<POSManagerService> {
    if (!service) {
      throw new Error('Service can not be empty');
    }
    return this.http
      .post<POSManagerService>(`${this.getBaseUrl()}`, service)
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response)
      );
  }

  public updateService(
    id: number,
    data: Partial<POSManagerService>
  ): Observable<POSManagerService> {
    if (!id) {
      throw new Error('Service id can not be empty');
    }
    if (!data) {
      throw new Error('Service data can not be empty');
    }
    return this.http
      .put<POSManagerService>(`${this.getBaseUrl()}/${id}`, data)
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response)
      );
  }

  public deleteService(id: number) {
    return this.http
      .delete<{ success: boolean }>(`${this.getBaseUrl()}/${id}`)
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.success)
      );
  }
}
