import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  ModifierPrompt,
  ModifierPromptRequest,
  POSManagerProduct,
  PosManagerSharedObjects,
  PosQuantityRestriction,
  POSProductQuantityRestrictionsRequest,
  POSProductQuantityRestrictionsResponse,
  SetPriceBookPayload,
  SetPriceBookResponse
} from 'src/app/models/pos-manager.model';
import { ApiType } from 'src/app/pos.config';
import { ErrorService } from 'src/app/services/error/error.service';
import { AppStateHelper } from 'src/app/store/app/app.state.helper';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';

@Injectable({
  providedIn: 'root'
})
export class PosManagerService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private readonly errorService: ErrorService
  ) {}

  getSharedObjects(): Observable<PosManagerSharedObjects> {
    const url = `${this.getBaseUrl()}shared_objects`;
    return this.http.get<PosManagerSharedObjects>(url).pipe(
      this.errorService.retryPipe(ApiType.Query),
      map((response) => response)
    );
  }

  getModifierPrompts(): Observable<ModifierPrompt[]> {
    return this.http
      .get<{ modifier_prompts: ModifierPrompt[] }>(
        `${this.getBaseUrl()}modifier_prompts`
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.modifier_prompts)
      );
  }

  createModifierPrompt(
    data: ModifierPromptRequest
  ): Observable<ModifierPrompt> {
    return this.http
      .post<ModifierPrompt>(`${this.getBaseUrl()}modifier_prompts`, data)
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response)
      );
  }

  updateModifierPrompt(
    id: number,
    data: ModifierPromptRequest
  ): Observable<ModifierPrompt> {
    return this.http
      .put<ModifierPrompt>(`${this.getBaseUrl()}modifier_prompts/${id}`, data)
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response)
      );
  }

  deleteModifierPrompt(id: number): Observable<boolean> {
    return this.http
      .delete<{ success: boolean }>(
        `${this.getBaseUrl()}modifier_prompts/${id}`
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.success)
      );
  }

  getProducts(): Observable<POSManagerProduct[]> {
    return this.http
      .get<{ products: POSManagerProduct[] }>(
        `${this.getBaseUrl()}pos_products`
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.products)
      );
  }

  setPriceBook({
    priceBookId,
    itemType,
    itemId,
    price_type,
    price
  }: SetPriceBookPayload): Observable<SetPriceBookResponse> {
    return this.http
      .post<SetPriceBookResponse>(
        this.getBaseUrl() + 'pos_price_books/' + priceBookId + '/set_price',
        {
          pos_price_book_item: {
            price_bookable_type: itemType,
            price_bookable_id: itemId,
            price_type,
            price
          }
        }
      )
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  private getBaseUrl(): string {
    const baseUrl = this.store.selectSnapshot(APP_STATE_TOKEN).apiBaseUrl;
    const posManagerApiUrl = AppStateHelper.getPosManagerApiUrl(baseUrl);

    return posManagerApiUrl;
  }

  getQuantityRestrictions(date: string): Observable<PosQuantityRestriction[]> {
    return this.http
      .get<POSProductQuantityRestrictionsResponse>(
        `${this.getBaseUrl()}pos_quantity_restrictions`,
        { params: { date } }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response?.quantity_restrictions || []),
        catchError(() => of([]))
      );
  }

  createQuantityRestriction(
    data: POSProductQuantityRestrictionsRequest
  ): Observable<PosQuantityRestriction> {
    return this.http
      .post<PosQuantityRestriction>(
        `${this.getBaseUrl()}pos_quantity_restrictions`,
        data
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response)
      );
  }

  updateQuantityRestriction(
    data: Partial<POSProductQuantityRestrictionsRequest>
  ): Observable<PosQuantityRestriction> {
    return this.http
      .put<PosQuantityRestriction>(
        `${this.getBaseUrl()}pos_quantity_restrictions/${data.id}`,
        data
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response)
      );
  }

  deleteQuantityRestriction(restriction_id: number): Observable<boolean> {
    return this.http
      .delete<{ success: boolean }>(
        `${this.getBaseUrl()}pos_quantity_restrictions/${restriction_id}`
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.success)
      );
  }

  getEmbeddedDashboardUrl(): Observable<string> {
    return this.http
      .get<{ iframe_url: string }>(`${this.getBaseUrl()}pos_dashboard`)
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((res) => res.iframe_url)
      );
  }

  get canAccessReportingPage(): boolean {
    const { facility_details, operator } =
      this.store.selectSnapshot(APP_STATE_TOKEN);
    return (
      facility_details?.enable_pos_beta_reports &&
      !!operator.permissions.view_pos_beta_reports_in_pos_manager
    );
  }
}
