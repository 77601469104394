import { normalize } from 'normalizr';
import _ from 'lodash';

import {
  Diner,
  dinersSchemaArray,
  NormalizedEntities,
  NormalizedSeatData,
  NormalizedTransactionData,
  PosTable,
  Seat,
  seatsSchemaArray,
  Table,
  tablesArraySchema,
  Ticket,
  TicketItem,
  ticketItemsSchemaArray,
  ticketsSchemaArray,
  TicketTransaction,
  transactionsSchemaArray
} from 'src/app/models';
import { modifierPromptsSchemaArray } from '../models/normalized-models/pos-manager/normalized-modifier-prompt.model';
import { ModifierPrompt } from '../models/pos-manager.model';

export class NormalizrHelper {
  static normalizeTables(tables: PosTable[]) {
    const normalizedData = normalize<
      Table,
      Pick<NormalizedEntities, 'tables' | 'seats'>,
      number[]
    >(tables, tablesArraySchema);
    return normalizedData;
  }
  static normalizeSeats(seats: Seat[]): NormalizedSeatData {
    const normalizedData = normalize<
      Seat,
      Pick<NormalizedEntities, 'seats'>,
      number[]
    >(seats, seatsSchemaArray);
    const data = {
      result: normalizedData.result,
      entities: {
        seats: _.keyBy(
          _.map(normalizedData.entities.seats, (s) => {
            s.ticket = s.ticket || s.device_ticket_uuid;
            return s;
          }),
          (s) => s.id
        )
      }
    };
    return data;
  }

  static normalizeDiners(diners: Diner[]) {
    const normalizedData = normalize<
      Diner,
      Pick<NormalizedEntities, 'diners'>,
      number[]
    >(diners, dinersSchemaArray);
    return normalizedData;
  }

  static normalizeTickets(tickets: Ticket[]) {
    const normalizedData = normalize<
      Ticket,
      Pick<
        NormalizedEntities,
        'tickets' | 'ticket_items' | 'transactions' | 'diners'
      >,
      string[]
    >(tickets, ticketsSchemaArray);
    return normalizedData;
  }

  static normalizeTicketItems(ticketItems: TicketItem[]) {
    const normalizedData = normalize<
      TicketItem,
      Pick<NormalizedEntities, 'ticket_items'>,
      string[]
    >(ticketItems, ticketItemsSchemaArray);
    return normalizedData;
  }

  static normalizeTransactions(
    transactions: TicketTransaction[]
  ): NormalizedTransactionData {
    const normalizedData = normalize<
      TicketTransaction,
      Pick<NormalizedEntities, 'transactions'>,
      number[]
    >(transactions, transactionsSchemaArray);

    return normalizedData;
  }

  static normalizeModifierPrompts(modifierPrompts: ModifierPrompt[]) {
    const normalizedData = normalize<
      ModifierPrompt,
      Pick<NormalizedEntities, 'modifierPrompts' | 'modifierPromptOptions'>,
      number[]
    >(modifierPrompts, modifierPromptsSchemaArray);
    return normalizedData;
  }
}
