import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonInputNumberBehaviorDirective } from './ion-input-number-behavior.directive';

@NgModule({
  declarations: [IonInputNumberBehaviorDirective],
  imports: [CommonModule],
  exports: [IonInputNumberBehaviorDirective]
})
export class IonInputNumberBehaviorDirectiveModule {}
