import { createPropertySelectors, createSelector } from '@ngxs/store';

import { LocationState, LocationStateModel } from './location.state';
import { LocationType, MealTime } from 'src/app/models';
import { LocationStateHelper } from './location.state.helper';

export class LocationSelectors {
  private static slices =
    createPropertySelectors<LocationStateModel>(LocationState);

  static getLocation = createSelector(
    [LocationSelectors.slices.location],
    (location) => location
  );

  private static paymentLocations = createSelector(
    [LocationSelectors.slices.location],
    (location) => location.payment_locations
  );

  // move from location.selectors.ts
  static getPaymentLocations = createSelector(
    [LocationSelectors.paymentLocations],
    (paymentLocations) => paymentLocations || []
  );

  static getHasPaymentLocations = createSelector(
    [LocationSelectors.getPaymentLocations],
    (paymentLocations) => !!paymentLocations.length
  );

  static getDiningRoomSettings = createSelector(
    [LocationSelectors.slices.location],
    (location) => location.dining_room_settings
  );

  static getCoursingEnabled = createSelector(
    [LocationSelectors.getDiningRoomSettings],
    (diningRoomSettings) => diningRoomSettings.coursing_enabled
  );

  static reservation_meal_configurations = createSelector(
    [LocationSelectors.getLocation],
    (location) => location.reservation_meal_configurations
  );

  static enable_reservation = createSelector(
    [LocationSelectors.reservation_meal_configurations],
    (i) => !!i?.length
  );

  static getPriceBookId = createSelector(
    [LocationSelectors.slices.location],
    (location) => location.pos_price_book_id
  );

  static locationId = createSelector(
    [LocationSelectors.getLocation],
    (location) => location?.id
  );

  static locations = createSelector(
    [LocationSelectors.slices.locations],
    (locations) => locations
  );

  static reservation_settings = createSelector(
    [LocationSelectors.getLocation],
    (location) => location.reservation_settings
  );

  static locationType = createSelector(
    [LocationSelectors.getLocation],
    (location) => location?.type
  );

  static getIsServiceAppointment = createSelector(
    [LocationSelectors.locationType],
    (locationType) => locationType === LocationType.Services
  );

  static getIsQuantitySelectorAllowed = createSelector(
    [LocationSelectors.slices.location],
    ({ type }) =>
      [LocationType.DiningRoom, LocationType.QuickService].includes(type)
  );

  // move from location.selectors.ts
  static isSelfServeKioskLocation = createSelector(
    [LocationSelectors.locationType],
    (locationType) => locationType === LocationType.SelfServe
  );

  // move from location.selectors.ts
  static getReservationMealConfigurationByMeal = createSelector(
    [LocationSelectors.reservation_meal_configurations],
    (configurations) => (mealId: MealTime) =>
      configurations.find((i) => i.meal_id === mealId)
  );

  // move from location.selectors.ts
  static getLocationsExceptCurrent = createSelector(
    [LocationSelectors.getLocation, LocationSelectors.locations],
    (location, locations) => locations.filter((i) => i.id !== location.id)
  );

  // move from location.selectors.ts
  static getDiningRoomLocations = createSelector(
    [LocationSelectors.locations],
    (locations) => locations.filter((x) => x.type === LocationType.DiningRoom)
  );

  private static currentMealId = createSelector(
    [LocationSelectors.slices.currentMealId],
    (currentMealId) => currentMealId
  );

  // move from location.selectors.ts
  static getMealId = createSelector(
    [LocationSelectors.currentMealId, LocationSelectors.locationType],
    (currentMealId, locationType) =>
      LocationStateHelper.getMealId(currentMealId, locationType)
  );

  // move from location.selectors.ts
  static getEnabledToGoOrders = createSelector(
    [LocationSelectors.getLocation],
    (location) => location?.enable_takeout_tab
  );

  // move from location.selectors.ts

  // move from location.selectors.ts
  static getIsDiningRoom = createSelector(
    [LocationSelectors.locationType],
    (locationType) => locationType === LocationType.DiningRoom
  );

  // move from location.selectors.ts
  static getIsQuickService = createSelector(
    [LocationSelectors.locationType],
    (locationType) => locationType === LocationType.QuickService
  );

  // move from location.selectors.ts
  static getIsCoursingEnabled = createSelector(
    [LocationSelectors.getLocation],
    (location) => LocationStateHelper.getIsCoursingEnabled(location)
  );

  // move from location.selectors.ts
  static getHideFireTable = createSelector(
    [LocationSelectors.getLocation],
    (location) =>
      location &&
      location.type === LocationType.DiningRoom &&
      location.dining_room_settings &&
      location.dining_room_settings.hide_fire_table
  );

  // move from location.selectors.ts
  static getIsPersonalizedResidentMenus = createSelector(
    [LocationSelectors.getLocation],
    (location) => LocationStateHelper.getIsPersonalizedResidentMenus(location)
  );

  // move from location.selectors.ts
  static getIsAutoChooseMealPlan = createSelector(
    [LocationSelectors.getLocation],
    (location) => location?.auto_choose_mealplan_when_available
  );

  // move from location.selectors.ts
  static getWeightContainers = createSelector(
    [LocationSelectors.getLocation],
    (location) => location?.tare_weight_containers || []
  );

  // move from location.selectors.ts
  static getAutoAddProductsForMealPlan = createSelector(
    [LocationSelectors.currentMealId, LocationSelectors.getLocation],
    (currentMealId, location) =>
      LocationStateHelper.getAutoAddProductsForMealPlan(currentMealId, location)
  );

  // move from location.selectors.ts
  static getAcceptedPaymentMethods = createSelector(
    [LocationSelectors.getLocation],
    (location) => location?.accepted_payment_methods
  );

  // move from location.selectors.ts
  static getIsEnabledManualCreditCard = createSelector(
    [LocationSelectors.getAcceptedPaymentMethods],
    (accepted_payment_methods) => accepted_payment_methods?.manual_credit_card
  );

  // move from location.selectors.ts
  static getCreditCardProcessingType = createSelector(
    [LocationSelectors.getLocation],
    (location) => location?.credit_card_processing
  );

  static getAutoCloseOnFullPayment = createSelector(
    [LocationSelectors.getLocation],
    (location) => location?.auto_close_on_full_payment
  );

  static getExternalDisplayImage = createSelector(
    [LocationSelectors.getLocation],
    (location) => location?.external_display_image
  );

  static getSelfServeSettings = createSelector(
    [LocationSelectors.getLocation],
    (location) => location?.self_serve_settings
  );
}
