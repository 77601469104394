import { HttpErrorResponse } from '@angular/common/http';
import {
  ReservationViewFocus,
  TableReservation,
  TableReservationDTO
} from 'src/app/models';

// * date?: yyyy-MM-dd
export class GetTableReservations {
  static readonly type = '[Table Reservation] Fetch Table Reservation';
  constructor(
    public readonly date?: string,
    public readonly diner_name?: string
  ) {}
}

export class SetDinerSearchTableReservations {
  static readonly type =
    '[Table Reservation] Set Diner Search Table Reservation';
  constructor(public readonly reservations: TableReservation[]) {}
}

export class GetTableReservationsSuccess {
  static readonly type = '[Table Reservation] Fetch Table Reservation Success';
  constructor(
    public readonly reservations: TableReservation[],
    public readonly isDinerSearching: boolean
  ) {}
}

export class GetTableReservationsError {
  static readonly type = '[Table Reservation] Fetch Table Reservation Error';
  constructor(public readonly error: HttpErrorResponse) {}
}

export class CreateTableReservation {
  static readonly type = '[Table Reservation] Create Table Reservation';
  constructor(public readonly dto: TableReservationDTO) {}
}

export class CreateTableReservationSuccess {
  static readonly type = '[Table Reservation] Create Table Reservation Success';
  constructor(public readonly reservation: TableReservation) {}
}

export class CreateTableReservationError {
  static readonly type = '[Table Reservation] Create Table Reservation Error';
  constructor(public readonly error: HttpErrorResponse) {}
}

export class EditTableReservation {
  static readonly type = '[Table Reservation] Edit Table Reservation';
  constructor(
    public readonly id: number,
    public readonly dto: TableReservationDTO
  ) {}
}

export class EditTableReservationSuccess {
  static readonly type = '[Table Reservation] Edit Table Reservation Success';
  constructor(public readonly reservation: TableReservation) {}
}

export class EditTableReservationError {
  static readonly type = '[Table Reservation] Edit Table Reservation Error';
  constructor(public readonly error: HttpErrorResponse) {}
}

export class DeleteTableReservation {
  static readonly type = '[Table Reservation] Delete Table Reservation';
  constructor(public readonly reservation: TableReservation) {}
}

export class DeleteTableReservationSuccess {
  static readonly type = '[Table Reservation] Delete Table Reservation Success';
  constructor(public readonly id: number) {}
}

export class DeleteTableReservationError {
  static readonly type = '[Table Reservation] Delete Table Reservation Error';
  constructor(public readonly error: HttpErrorResponse) {}
}

export class SetReservationViewFocus {
  static readonly type = '[Table Reservation] Set Reservation View Focus';
  constructor(public readonly view: ReservationViewFocus) {}
}
