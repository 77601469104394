import {
  DeliveryType,
  GatewayTransactionResult,
  PosPaymentRequest,
  PaymentResult,
  TakeOutDeliveryOrder,
  Ticket,
  TicketItem,
  TicketItemRequest,
  TicketType,
  AddTicketItemResponse,
  LocationType,
  AddTicketItemResult
} from 'src/app/models';
import { DateHelper, GeneralHelper } from 'src/app/helpers';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  filter,
  map,
  of,
  switchMap,
  tap,
  throwError
} from 'rxjs';
import { LOG_ROCKET_CUSTOM_EVENTS, LogRocketProvider } from 'src/app/providers';
import {
  ResetWaitingTicketItem,
  VoidTicketItem
} from 'src/app/store/ticket-items/ticket-items.action';
import { SEATS_STATE_TOKEN } from 'src/app/store/seats/seats.state';

import { ApiType } from 'src/app/pos.config';
import { BaseService } from '../base.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddTicketItemError,
  SetTicketCreatingStatus
} from 'src/app/store/tickets/tickets.action';
import { Store } from '@ngxs/store';
import { TICKETS_STATE_TOKEN } from 'src/app/store/tickets/tickets.state';
import { TICKET_ITEMS_STATE_TOKEN } from 'src/app/store/ticket-items/ticket-items.state';
import { TicketHelper } from 'src/app/helpers/ticket.helper';
import _ from 'lodash';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';
import { TABLES_STATE_TOKEN } from 'src/app/store/tables/tables.state.model';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { MENU_STATE_TOKEN } from 'src/app/store/menu/menu.state.model';
import { LocationStateHelper } from 'src/app/store/location/location.state.helper';

@Injectable({
  providedIn: 'root'
})
export class TicketService extends BaseService {
  private creatingTickets: string[] = [];
  voidingTicketItems: string[] = [];
  deletingTickets: string[] = [];

  private get selectedTicketItemId(): string {
    return this.store.selectSnapshot(TICKET_ITEMS_STATE_TOKEN)
      .selectedTicketItem;
  }

  private get selectedTicketId(): string {
    return this.store.selectSnapshot(TICKETS_STATE_TOKEN).selectedTicket.data;
  }

  constructor(
    store: Store,
    private http: HttpClient,
    private logRocketProvider: LogRocketProvider,
    private readonly errorService: ErrorService
  ) {
    super(store);
  }

  //#region Ticket

  private getTicketUrl(uuid: string, url?: string) {
    const locationUrl = this.getLocationUrl('tickets');
    const ticketUrl = uuid ? `${locationUrl}/${uuid}` : locationUrl;
    return url ? `${ticketUrl}/${url}` : ticketUrl;
  }

  getTickets(): Observable<Ticket[]> {
    return this.http
      .get<{ tickets: Ticket[] }>(this.getLocationUrl('tickets'))
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.tickets)
      );
  }

  getTakeoutTickets(): Observable<TakeOutDeliveryOrder[]> {
    return this.http
      .get<{ tickets: TakeOutDeliveryOrder[] }>(
        this.getLocationUrl('tickets?takeout_only=true')
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.tickets)
      );
  }

  getTicket(device_ticket_uuid: string): Observable<Ticket> {
    return this.http
      .get<{ ticket: Ticket }>(this.getTicketUrl(device_ticket_uuid))
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.ticket)
      );
  }

  createTicket(defaults: Partial<Ticket> = null): Observable<Ticket> {
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isDiningRoom: boolean = locationType === LocationType.DiningRoom;
    const isServiceAppointment: boolean =
      locationType === LocationType.Services;
    const { currentMealId, location } =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN);
    const currentMeal: number = LocationStateHelper.getMealId(
      currentMealId,
      location.type
    );
    const selectedMeal =
      this.store.selectSnapshot(MENU_STATE_TOKEN).selected_meal_id;
    const operator = this.store.selectSnapshot(APP_STATE_TOKEN).operator;
    const meal_id =
      isDiningRoom && !defaults?.meal_id
        ? currentMeal
        : defaults?.meal_id || selectedMeal || currentMeal;
    const seat_id = this.store.selectSnapshot(SEATS_STATE_TOKEN).selectedSeat;

    let data: Ticket = {
      //Quick Service may have a local Ticket UUID waiting to be used
      //If so, use that one unless the user is changing the selected meal on a Ticket without items
      device_ticket_uuid:
        !isDiningRoom && this.selectedTicketId && !defaults
          ? this.selectedTicketId
          : GeneralHelper.generateUuid(),
      pos_operator_id: operator.id,
      meal_id,
      type: isDiningRoom ? TicketType.SeatedTicket : TicketType.QuickTicket,
      seat_id,
      table_id:
        this.store.selectSnapshot(TABLES_STATE_TOKEN).selectedTable.data,
      delivery_type: isDiningRoom ? DeliveryType.TO_TABLE : DeliveryType.TO_GO,
      ticket_date: DateHelper.format(new Date())
    };

    if (isServiceAppointment) {
      delete data.meal_id;
    }

    //If there's default data set for the Ticket, use it
    if (defaults) {
      data = { ...data, ...defaults };
    }

    const isCreatingTicket =
      this.creatingTickets.indexOf(data.device_ticket_uuid) > -1;

    if (isCreatingTicket) {
      return of(data);
    }

    this.creatingTickets.push(data.device_ticket_uuid);

    this.store.dispatch(new SetTicketCreatingStatus(true));

    return this.http
      .post<{ success: boolean; ticket: Ticket }>(
        this.getLocationUrl('tickets'),
        data,
        { params: { loadingText: 'Creating ticket...' } }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map(
          (response) =>
            response.ticket && TicketHelper.initializeTicket(response.ticket)
        ),
        tap({
          finalize: () => {
            this.store.dispatch(new SetTicketCreatingStatus(false));
            this.creatingTickets.splice(
              this.creatingTickets.indexOf(data.device_ticket_uuid),
              1
            );
          }
        })
      );
  }

  assignDinerToTicket(
    diner_id: number,
    ticketUuid?: string
  ): Observable<{ status: string }> {
    return this.http
      .post<{ status: string }>(
        this.getTicketUrl(ticketUuid || this.selectedTicketId, 'assign_diner'),
        {
          diner_id
        }
      )
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  clearDinerAssignment(): Observable<Ticket> {
    return this.http
      .delete<{
        success?: boolean;
        status?: string;
        ticket?: Ticket;
      }>(this.getTicketUrl(this.selectedTicketId, 'clear_diner_assignment'))
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        switchMap((res) =>
          res.success || res.status === 'success' ? of(res.ticket) : EMPTY
        )
      );
  }

  addMealPlan(
    ticket_uuid: string,
    pos_mealplan_id: number
  ): Observable<Ticket> {
    return this.http
      .post<{ success; ticket }>(
        this.getTicketUrl(ticket_uuid, 'assign_mealplan'),
        { pos_mealplan_id }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        filter((response) => response.success),
        map((response) => response.ticket)
      );
  }

  removeMealPlan(ticket_uuid: string): Observable<Ticket> {
    return this.http
      .delete<{ success: boolean; ticket: Ticket }>(
        this.getTicketUrl(ticket_uuid, 'clear_mealplan_assignment')
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => (response.success ? response.ticket : null))
      );
  }

  addTicketDiscount(
    ticket_uuid: string,
    pos_operator_id: number,
    pos_discount_id: number
  ) {
    return this.http
      .post<{ success: boolean; ticket: Ticket }>(
        this.getTicketUrl(ticket_uuid, 'discounts'),
        { pos_discount_id, pos_operator_id },
        { params: { loadingText: 'Applying discount...' } }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => (response.success ? response.ticket : null))
      );
  }

  removeTicketDiscount(ticket_uuid: string, discount_id: number) {
    return this.http
      .delete<{ success: boolean; ticket: Ticket }>(
        this.getTicketUrl(ticket_uuid, `discounts/${discount_id}`),
        { params: { loadingText: 'Removing discount...' } }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => (response.success ? response.ticket : null))
      );
  }

  updateTicket(
    ticket: Partial<Ticket>
  ): Observable<{ success: boolean; ticket: Ticket }> {
    return this.http
      .put<{ success: boolean; ticket: Ticket }>(
        this.getTicketUrl(this.selectedTicketId),
        ticket
      )
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  deleteTicket(id: string) {
    this.deletingTickets.push(id);
    return this.http
      .delete<{ status: string }>(this.getTicketUrl(id, 'delete'))
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        tap({
          finalize: () =>
            this.deletingTickets.splice(this.deletingTickets.indexOf(id))
        })
      );
  }

  voidTicket(id: string, data): Observable<any> {
    this.deletingTickets.push(id);

    return this.http.put(this.getTicketUrl(id, 'void'), data).pipe(
      this.errorService.retryPipe(ApiType.Mutate),
      tap({
        finalize: () =>
          this.deletingTickets.splice(this.deletingTickets.indexOf(id))
      })
    );
  }

  // use for takeout only
  cancelTicket(ticket_uuid: string, pos_operator_id: number): Observable<any> {
    return this.http
      .put<{ success: boolean; ticket: Ticket }>(
        this.getTicketUrl(ticket_uuid, 'cancel'),
        {
          pos_operator_id
        }
      )
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
    // .pipe(map((response) => (response.success ? response : null)));
  }

  fireTicket(ticket_uuid: string, pos_operator_id: number): Observable<any> {
    return this.http
      .post<{ status: string }>(this.getTicketUrl(ticket_uuid, 'fire_ticket'), {
        pos_operator_id
      })
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => (response.status === 'success' ? ticket_uuid : null))
      );
  }

  closeTicket(ticket_uuid: string): Observable<any> {
    return this.http
      .put(this.getTicketUrl(ticket_uuid, 'close'), null)
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  makePaymentGatewayTransaction(
    ticket_uuid: string,
    {
      amount: transaction_amount,
      payment_terminal_id: pos_card_terminal_id,
      operator_id: pos_operator_id,
      collect_manual_card_entry_on_pinpad
    }: PosPaymentRequest
  ): Observable<GatewayTransactionResult> {
    return this.http
      .post<GatewayTransactionResult>(
        this.getTicketUrl(ticket_uuid, 'payment_gateway_transactions'),
        null,
        {
          params: {
            transaction_amount,
            pos_card_terminal_id,
            pos_operator_id,
            collect_manual_card_entry_on_pinpad
            // the loading text doesn't work for this request, it always shows the payment in process modal
          }
        }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        filter((response) => !!response)
      );
  }

  makePaymentTransactions(
    ticket_uuid: string,
    payment_request: PosPaymentRequest
  ): Observable<PaymentResult> {
    return this.http
      .post<PaymentResult>(
        this.getTicketUrl(ticket_uuid, 'transactions'),
        payment_request,
        {
          params: { loadingText: 'Payment request in progress...' }
        }
      )
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  getTrancloudTransaction(ticket_uuid: string, gateway_transaction_id: number) {
    return this.http
      .get<GatewayTransactionResult>(
        this.getTicketUrl(
          ticket_uuid,
          `payment_gateway_transactions/${gateway_transaction_id}`
        )
      )
      .pipe(this.errorService.retryPipe(ApiType.Query));
  }

  lockTicket(device_ticket_uuid: string, pos_operator_id: number) {
    return this.http
      .post<{ status: string }>(
        this.getTicketUrl(device_ticket_uuid, 'lock_ticket'),
        null,
        { params: { pos_operator_id } }
      )
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  unlockTicket(device_ticket_uuid: string) {
    return this.http
      .delete<{ status: string }>(
        this.getTicketUrl(device_ticket_uuid, 'unlock_ticket')
      )
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  addTaxExemption(device_ticket_uuid: string) {
    return this.http
      .post<{ success: boolean; ticket: Ticket }>(
        this.getTicketUrl(device_ticket_uuid, 'add_tax_exemption'),
        { tax_exemption_reason: 'Department Exempt.' }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => (response.success ? response.ticket : null))
      );
  }

  removeTaxExemption(device_ticket_uuid: string) {
    return this.http
      .delete<{ success: boolean; ticket: Ticket }>(
        this.getTicketUrl(device_ticket_uuid, 'remove_tax_exemption')
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => (response.success ? response.ticket : null))
      );
  }

  deleteRefundTicket(device_ticket_uuid: string) {
    return this.http
      .delete<{ status: string }>(
        this.getTicketUrl(device_ticket_uuid, 'delete')
      )
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  //#endregion

  //#region Ticket Item

  private getTicketItemUrl(
    uuid: string,
    url?: string,
    isGetTicketItemUuid = true
  ) {
    const ticketItemUuid = isGetTicketItemUuid && uuid;
    const ticketUrl = this.getTicketUrl(this.selectedTicketId, 'ticket_items');
    const baseTicketItemUrl = ticketItemUuid
      ? `${ticketUrl}/${ticketItemUuid}`
      : ticketUrl;
    if (url) {
      return `${baseTicketItemUrl}/${url}`;
    }
    return url ? `${baseTicketItemUrl}/${url}` : baseTicketItemUrl;
  }

  private addTicketItem(
    data: TicketItemRequest
  ): Observable<AddTicketItemResponse> {
    return this.http
      .post<{ success: boolean; ticket_items: TicketItem[]; ticket: Ticket }>(
        this.getTicketItemUrl(
          this.selectedTicketItemId || data.device_ticket_item_uuid,
          null,
          false
        ),
        data
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        filter((response) => response.success),
        map((response) => ({
          ticket_items: response.ticket_items,
          ticket: response.ticket
        })),
        tap((result) => {
          const device_ticket_uuid = result.ticket.device_ticket_uuid;

          const device_ticket_item_uuid = (
            data.parent_uuid
              ? _.compact(
                  _.flatten(result.ticket_items.map((ti) => ti.ticket_items))
                )
              : result.ticket_items
          ).find(
            (ti) => ti.device_ticket_item_uuid === data.device_ticket_item_uuid
          );
          this.logRocketProvider.track(
            LOG_ROCKET_CUSTOM_EVENTS.TicketItem.Created,
            { device_ticket_uuid, device_ticket_item_uuid }
          );
        }),
        catchError((error: HttpErrorResponse) => {
          // this.store.dispatch(new ResetWaitingTicketItem());
          this.store.dispatch(new AddTicketItemError(error));
          return throwError(() => error);
        })
      );
  }

  addTicketItems(
    ticketItems: TicketItemRequest[],
    destroy$: Subject<void>,
    result?: AddTicketItemResult[]
  ): Observable<AddTicketItemResponse>[] {
    return ticketItems.map((new_item) =>
      this.addTicketItem(new_item).pipe(
        tap(({ ticket, ticket_items }) => {
          if (!_.isArray(result)) {
            return;
          }

          result.push({
            ticket_items,
            ticket,
            ticket_item: ticket_items.find(
              (ti) =>
                ti.device_ticket_item_uuid === new_item.device_ticket_item_uuid
            )
          });
        }),
        catchError(() => {
          // if one of the ticket item failed to add, cancel all the adding ticket items
          destroy$.next();
          return EMPTY;
        })
      )
    );
  }

  updateTicketItem(
    device_ticket_item_uuid: string,
    data: Partial<TicketItem>
  ): Observable<any> {
    return this.http
      .put(this.getTicketItemUrl(device_ticket_item_uuid), data)
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  printTicket(
    ticket_uuid: string,
    printer_id: number,
    open_cash_drawer: boolean
  ) {
    return this.http
      .post<{ success: boolean }>(
        this.getTicketUrl(ticket_uuid, 'print_receipt'),
        { printer_id, open_cash_drawer }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.success)
      );
  }

  cancelTicketItem(device_ticket_item_uuid: string): Observable<Ticket> {
    /** Ticket item is cancelling */
    if (this.voidingTicketItems.indexOf(device_ticket_item_uuid) > -1) {
      return of(null);
    }
    this.voidingTicketItems.push(device_ticket_item_uuid);
    return this.http
      .delete<{ success: boolean; ticket: Ticket }>(
        this.getTicketItemUrl(device_ticket_item_uuid)
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        filter((response) => response.success),
        map((response) => response.ticket),
        catchError((error) => {
          this.store.dispatch(new ResetWaitingTicketItem(true));
          return throwError(() => error);
        }),
        tap({
          finalize: () =>
            this.voidingTicketItems.splice(
              this.voidingTicketItems.indexOf(device_ticket_item_uuid)
            )
        })
      );
  }

  voidTicketItem(
    device_ticket_item_uuid: string,
    data: VoidTicketItem
  ): Observable<Ticket> {
    /** Ticket item is voiding */
    if (this.voidingTicketItems.indexOf(device_ticket_item_uuid) > -1) {
      return;
    }
    this.voidingTicketItems.push(device_ticket_item_uuid);

    return this.http
      .put<{ success: boolean; ticket: Ticket }>(
        this.getTicketItemUrl(device_ticket_item_uuid, 'void'),
        data
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        filter((response) => response.success),
        map((response) => response.ticket),
        catchError((error) => {
          this.store.dispatch(new ResetWaitingTicketItem(true));
          return throwError(() => error);
        }),
        tap({
          finalize: () =>
            this.voidingTicketItems.splice(
              this.voidingTicketItems.indexOf(device_ticket_item_uuid)
            )
        })
      );
  }

  firePartialTickets(
    pos_operator_id: number,
    device_ticket_item_uuid?: string,
    course?: number,
    ticket_uuid?: string
  ) {
    const data: any = device_ticket_item_uuid
      ? { device_ticket_item_uuid }
      : { course };
    data.pos_operator_id = pos_operator_id;
    return this.http
      .post<{ status: string }>(
        this.getTicketUrl(this.selectedTicketId || ticket_uuid, 'fire_ticket'),
        data
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => (response.status === 'success' ? true : null))
      );
  }

  addTicketItemDiscount(
    ticket_item_uuid: string,
    pos_operator_id: number,
    pos_discount_id: number
  ) {
    return this.http
      .post<{ success: boolean; ticket: Ticket }>(
        this.getTicketUrl(this.selectedTicketId, 'discounts'),
        { ticket_item_uuid, pos_operator_id, pos_discount_id }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => (response.success ? response.ticket : null))
      );
  }

  removeTicketItemDiscount(ticket_item_uuid: string, discount_id: number) {
    return this.http
      .delete<{ success: boolean; ticket: Ticket }>(
        this.getTicketUrl(this.selectedTicketId, `discounts/${discount_id}`),
        { body: { ticket_item_uuid } }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => (response.success ? response.ticket : null))
      );
  }

  //#endregion
}
