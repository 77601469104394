import { schema } from 'normalizr';
import {
  GiftCardData,
  Scale,
  Tax,
  TicketDiscount,
  TicketItem,
  TicketItemStatus,
  TicketItemType
} from '..';
import _ from 'lodash';

const ticketItemProcessStrategy = (entity: TicketItem, parent, key) =>
  _.has(entity, 'scale_data')
    ? {
        ...entity,
        scale_data_parsed:
          entity.scale_data && _.isString(entity.scale_data)
            ? JSON.parse(entity.scale_data.replace(/\\"/g, '"'))
            : null
      }
    : entity;

export const ticketItemsSchema = new schema.Entity<TicketItem>(
  'ticket_items',
  {},
  {
    idAttribute: 'device_ticket_item_uuid',
    processStrategy: ticketItemProcessStrategy
  }
);
export const ticketItemsSchemaArray = new schema.Array(ticketItemsSchema);

ticketItemsSchema.define({
  ticket_items: [ticketItemsSchema]
});

export interface NormalizedTicketItem {
  applied_discount_id?: number;
  base_price: number;
  course: number;
  device_ticket_item_uuid: string;
  discount_amount: number;
  food_id?: number;
  name: string;
  notes?: string;
  parent_uuid: string;
  plate_id?: number;
  pos_product_id?: number;
  pre_tax_subtotal: number;
  production_notes: string;
  scale_data?: string;

  // scale_data_parsed?: Scale;

  status: TicketItemStatus;
  tax: Tax;
  tax_amount: number;
  total: number;
  type: TicketItemType;
  ticket_items: string[];
  can_be_discounted?: boolean;
  synced?: boolean;
  discounts?: TicketDiscount[];
  gift_card_data?: GiftCardData;
  is_refunded?: boolean;
  source_ticket_id?: number;
  category_id: number;
  is_declining_balance_plan?: boolean;
  is_discounted_by_meal_plan?: boolean;
  price_after_meal_plan_discount?: number;
  meal_plan_discount_amount?: number;
  discount_amount_with_meal_plan?: number;
  created_at?: Date;

  service_booking_id?: number | null;

  // meal plan values
  mealplan_valid_item?: true;
  mealplan_discount_applied?: string;

  // KMS quantity
  kitchen_quantity?: string;

  // The extraProp is used to display. The logic is handled in the FE
  extraPropItemHasNotes?: boolean;
  extraPropIsItemModified?: boolean;
  extraPropIsBeingModified?: boolean;
  extraPropIsInFiringMode?: boolean;
  extraPropIsSelectedToFire?: boolean;
  extraPropShowPriceAfterDiscount?: boolean;
  extraPropHasTax?: boolean;
  extraPropHasStrikeText?: boolean;
  extraPropIsCovered?: boolean;

  local_attributes: {
    selected?: boolean;
    removed?: boolean;
  };

  // TODO to be removed after have new ticket item modifier
  pos_ticket_item_modifier_attributes?: any[];
  pos_ticket_item_modifiers?: any[];
}
