import { PriceType } from 'src/app/models';
import {
  POSFullMenu,
  POSMenuRequest,
  POSModifierPromptAssignment,
  POSProduct,
  POSProductEditDto,
  PosQuantityRestriction,
  POSProductQuantityRestrictionsRequest,
  PriceBookItemType,
  SetPriceBookPayload,
  SharedObject,
  TaxSettings
} from 'src/app/models/pos-manager.model';

export class GetPOSProducts {
  static readonly type = '[Item Manager] Get Products';
}

export class GetPOSProductsSuccess {
  static readonly type = '[Item Manager] Get Products Success';
  constructor(public products: POSProduct[]) {}
}

export class CreatePOSProduct {
  static readonly type = '[Item Manager] Create POS Product';
  constructor(
    public readonly product: POSProductEditDto,
    public readonly locationId: number
  ) {}
}

export class SetPriceBookForItem {
  static readonly type = '[Item Manager] Set Price Book For Item';
  constructor(public readonly payload: SetPriceBookPayload) {}
}

export class CreatePOSProductSuccess {
  static readonly type = '[Item Manager] Create POS Product Success';
  constructor(public readonly newProduct: POSProduct) {}
}

export class CreatePOSProductError {
  static readonly type = '[Item Manager] Create POS Product Error';
  constructor(public readonly errorMessage: string) {}
}

export class UpdatePOSProduct {
  static readonly type = '[Item Manager] Update POS Product';
  constructor(
    public readonly id: number,
    public readonly locationId: number,
    public readonly product: Partial<POSProductEditDto>,
    public readonly assignments: POSModifierPromptAssignment[],
    public readonly deletedAssignmentIds: number[],
    public readonly initialTaxState: TaxSettings[]
  ) {}
}

export class UpdatePOSProductSuccess {
  static readonly type = '[Item Manager] Update POS Product Success';
  constructor(
    public readonly updatedProduct: POSProduct,
    public readonly isPriceUpdated: boolean
  ) {}
}

export class DeletePOSProduct {
  static readonly type = '[Item Manager] Delete POS Product';
  constructor(public id: number) {}
}

export class GetPOSMenus {
  static readonly type = '[Item Manager] Get POS Menus';
  constructor(public request: POSMenuRequest) {}
}

export class GetPOSMenusSuccess {
  static readonly type = '[Item Manager] Get POS Menus Success';
  constructor(
    public readonly menu: POSFullMenu,
    public readonly qtyRestrictions: PosQuantityRestriction[]
  ) {}
}

export class SelectPOSProduct {
  static readonly type = '[Item Manager] Select POS Product';
  constructor(public id: number) {}
}

export class SelectMenuItem {
  static readonly type = '[Item Manager] Select Menu Item';
  constructor(public menuItemId: number) {}
}

export class UpdateMenuItemAssignments {
  static readonly type = '[Item Manager] Update Menu Item Assignments';
  constructor(
    public menuItemId: number,
    public assignments: POSModifierPromptAssignment[],
    public deletedAssignmentIds: number[]
  ) {}
}

export class UpdateMenuItem {
  static readonly type = '[Item Manager] Update Menu Item';
  constructor(
    public readonly payload: {
      menuItemId: number;
      price?: number;
      priceType: PriceType;
      assignments?: POSModifierPromptAssignment[];
      deletedAssignmentIds?: number[];
      itemType: PriceBookItemType;
      locationId: number;
    }
  ) {}
}

export class UpdateMenuItemSuccess {
  static readonly type = '[Item Manager] Update Menu Item Success';
  constructor(
    public readonly isPriceUpdated: boolean,
    public readonly data: UpdateMenuItem,
    public readonly results: POSModifierPromptAssignment[]
  ) {}
}

export class GetProductQuantityRestrictions {
  static readonly type = '[POS Manager] Get Product Quantity Restrictions';
  constructor(public selectedDate?: string) {}
}

export class CreateProductQuantityRestriction {
  static readonly type = '[POS Manager] Create Product Quantity Restriction';
  constructor(public restrictions: POSProductQuantityRestrictionsRequest) {}
}

export class CreateProductQuantityRestrictionSuccess {
  static readonly type =
    '[POS Manager] Create Product Quantity Restriction Success';
  constructor(public restriction: PosQuantityRestriction) {}
}

export class PatchProductQuantityRestrictionsSuccess {
  static readonly type =
    '[POS Manager] Patch Product Quantity Restriction Success';
}

export class UpdateProductQuantityRestriction {
  static readonly type = '[POS Manager] Update Product Quantity Restriction';

  constructor(
    public readonly restrictions: Partial<POSProductQuantityRestrictionsRequest>
  ) {}
}

export class UpdateProductQuantityRestrictionSuccess {
  static readonly type =
    '[POS Manager] Update Product Quantity Restriction Success';
  constructor(public restriction: PosQuantityRestriction) {}
}

export class DeleteProductQuantityRestriction {
  static readonly type = '[POS Manager] Delete Product Quantity Restriction';
  constructor(public restrictionId: number) {}
}

export class DeleteProductQuantityRestrictionSuccess {
  static readonly type =
    '[POS Manager] Delete Product Quantity Restriction Success';
  constructor(public restrictionId: number) {}
}
