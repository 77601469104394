import { Injectable } from '@angular/core';

import { Ticket } from 'src/app/models';
import { EloExternalScreen } from './elo-external-screen';

@Injectable({
  providedIn: 'root'
})
export class ExternalScreenProvider {
  static screenGraphics = '';

  externalScreenInstance: EloExternalScreen;
  defaultGraphicScreen = 'mealsuite-pos.png';
  isQuickService = false;
  isActive = false;

  constructor() {
    this.externalScreenInstance = new EloExternalScreen();
    ExternalScreenProvider.screenGraphics =
      ExternalScreenProvider.screenGraphics || this.defaultGraphicScreen;
  }

  startWelcomeScreen(): void {
    this.externalScreenInstance.startWelcomeScreen();
    this.isQuickService = true;
  }

  initializeOrder(): void {
    this.externalScreenInstance.initializeOrder(null);
  }

  displayOrderDetails(orderDetails: Ticket): void {
    if (this.isQuickService) {
      this.externalScreenInstance.displayOrderDetails(orderDetails);
    }
  }

  displayPaymentDetails(paymentDetails: Ticket) {
    if (this.isQuickService) {
      this.externalScreenInstance.displayPaymentDetails(paymentDetails);
    }
  }

  displayHome() {
    this.externalScreenInstance.displayHome();
  }

  showMessage(message: any) {
    this.externalScreenInstance.showMessage(message);
  }

  private displayGraphics() {
    this.externalScreenInstance.updateGraphics(
      ExternalScreenProvider.screenGraphics
    );
  }

  updateGraphics(graphicsUrl: string = null) {
    ExternalScreenProvider.screenGraphics = graphicsUrl
      ? graphicsUrl
      : this.defaultGraphicScreen;
    this.displayGraphics();
  }
  testData(message: any) {
    console.log('External Screen -> testData', message);
  }

  turnOnBacklight() {}

  turnOffBacklight() {}
}
