import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { MealHelper } from 'src/app/helpers';
import { MealTime } from 'src/app/models';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';

@Injectable({
  providedIn: 'root'
})
export class MealProvider {
  constructor(private readonly _store: Store) {}

  getCurrentMealId(): MealTime {
    return MealHelper.getMealIdByTime(
      this._store.selectSnapshot(LOCATION_STATE_TOKEN).location.meal_end_times
    );
  }
}
