import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { RefundsModal } from './refunds.modal';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { ReplacePipeModule } from 'src/app/pipes/replace-pipe/replace-pipe.module';
import { NumericKeyboardComponentModule } from 'src/app/components/shared/numeric-keyboard/numeric-keyboard-component.module';
import { NegativePipeModule } from 'src/app/pipes/negative-pipe/negative-pipe.module';
import { PrinterByIdPipeModule } from 'src/app/pipes/printer-by-id-pipe/printer-by-id-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DebounceClickDirectiveModule,
    ReplacePipeModule,
    NumericKeyboardComponentModule,
    NegativePipeModule,
    PrinterByIdPipeModule
  ],
  declarations: [RefundsModal],
  exports: [RefundsModal]
})
export class RefundsModalModule {}
