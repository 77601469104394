<div class="pos-ssk-confirm-modal">
  <div class="pos-ssk-confirm-modal-wrapper">
    <h1 *ngIf="title" class="pos-ssk-confirm-modal-header">
      {{title}}
    </h1>

    <div class="pos-manager-confirm-modal-content">
      <p class="ion-text-center" *ngIf="description">
        {{description}}
      </p>
    </div>


    <div class="grid gap-px-10">
      <pos-ssk-round-button [btnLabel]="yesBtn.text"
                            [color]="yesBtn.color"
                            [fill]="yesBtn.fill"
                            expand="block" [fixedButton]="false"
                            (onClick)="modalCtrl.dismiss(true)"></pos-ssk-round-button>
      <pos-ssk-round-button [btnLabel]="noBtn.text" [color]="noBtn.color" [fill]="noBtn.fill" expand="block"
                            [fixedButton]="false"
                            (onClick)="modalCtrl.dismiss(false)"></pos-ssk-round-button>
    </div>
  </div>
</div>