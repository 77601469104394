import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlurButtonDirective } from './blur-button.directive';

@NgModule({
  declarations: [BlurButtonDirective],
  imports: [CommonModule],
  exports: [BlurButtonDirective]
})
export class BlurButtonDirectiveModule {}
