import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import {
  POSProduct,
  POSProductEditDto
} from 'src/app/models/pos-manager.model';
import { ApiType } from 'src/app/pos.config';
import { ErrorService } from 'src/app/services/error/error.service';
import { AppStateHelper } from 'src/app/store/app/app.state.helper';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';

@Injectable({ providedIn: 'root' })
export class PosProductService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private readonly errorService: ErrorService
  ) {}
  private getBaseUrl() {
    const baseUrl = this.store.selectSnapshot(APP_STATE_TOKEN).apiBaseUrl;
    const posManagerApiUrl = AppStateHelper.getPosManagerApiUrl(baseUrl);

    return `${posManagerApiUrl}pos_products`;
  }

  createProduct(
    product: Partial<Omit<POSProductEditDto, 'price_type' | 'price'>>
  ): Observable<POSProduct> {
    if (!product) {
      throw new Error('Product can not be empty');
    }
    return this.http
      .post<POSProduct>(`${this.getBaseUrl()}`, product)
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  updateProduct(
    id: number,
    data: Partial<POSProductEditDto>
  ): Observable<POSProduct> {
    if (!id) {
      throw new Error('Product id can not be empty');
    }
    if (!data) {
      throw new Error('Product data can not be empty');
    }

    return this.http
      .put<POSProduct>(`${this.getBaseUrl()}/${id}`, data)
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  deleteProduct(id: number): Observable<boolean> {
    return this.http
      .delete<{ success: boolean }>(`${this.getBaseUrl()}/${id}`)
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.success)
      );
  }
}
