export class DeviceRegistrationPageError {
  static readonly type = '[Device Registration] Error';
  constructor(public errorCode: string) {}
}

export class LoginPageError {
  static readonly type = '[Login] Error';
  constructor(public errorCode: string) {}
}

export class RequestError {
  static readonly type = '[Request] Error';
  constructor(public errorCode: string) {}
}

export class ResetError {
  static readonly type = '[Error] Reset Error';
}

export class HttpRequestError {
  static readonly type = '[Error] Http Request Error';
  constructor(public readonly errorMessage: string) {}
}
export class AppliedTicketDiscountError {
  static readonly type = '[Error] Applied Ticket Discount Error';
  constructor(public errorMessage: string) {}
}

export class ExceptionError {
  static readonly type = '[Error] Exception Error';
  constructor(public errorMessage: string) {}
}
