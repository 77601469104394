import { AlertOptions } from '@ionic/core';
import { ErrorCodeKey } from 'src/app/models';

interface ErrorMessage {
  title: string;
  message: string;
}

export class SelfServiceConstant {
  static readonly RESIDENT_TYPE = 'Resident';
  static readonly STAFF_TYPE = 'Staff Member';
  static readonly GUEST_TYPE = 'Guest';

  static readonly REACHED_MAX_ALERT_OPTS = (categoryName: string) =>
    <AlertOptions>{
      mode: 'ios',
      cssClass: 'reached-max-amount-alert',
      header: `You have reached the maximum amount allowed for "${categoryName}".`,
      message:
        'To select more, please place order with your dining service team.',
      buttons: ['OK']
    };
}

export class SelfServiceHomeConstants {
  static readonly IDLE_TIME = 120;
  static readonly ERROR_CODES: { [key in ErrorCodeKey]: ErrorMessage } = {
    [ErrorCodeKey.MultipleDiners]: {
      title: 'Warning',
      message: 'There are multiple diners have the same badge/RFID Tag code.'
    },
    [ErrorCodeKey.NotFoundDiner]: {
      title: 'Error',
      message: 'This ID cannot be found.'
    }
  };
}
