import { NgModule } from '@angular/core';

import { PosLoadingModal } from './component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [PosLoadingModal],
  imports: [CommonModule, IonicModule]
})
export class PosLoadingModalModule {}
