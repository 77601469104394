import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';

import { AddItemModifierPromptsModal } from './item-modifier-prompts.modal';
import { IsOptionSelectedPipeModule } from '../../pipes/is-option-selected-pipe/is-option-selected-pipe.module';

@NgModule({
  declarations: [AddItemModifierPromptsModal],
  imports: [
    CommonModule,
    IonicModule,
    FontAwesomeModule,
    IsOptionSelectedPipeModule
  ],
  exports: [AddItemModifierPromptsModal]
})
export class AddItemModifierPromptsModalModule {}
