<ion-menu *ngIf="menuAttrs$ | async as menuAttrs"
          swipeGesture="false"
          [side]="menuAttrs.side"
          [type]="menuAttrs.type"
          [menuId]="menuId$ | async"
          [style.--max-width]="menuAttrs.maxWidth"
          [style.--min-width]="menuAttrs.minWidth"
          [style.--width]="menuAttrs.width"
          contentId="menu-view"
          (ionDidClose)="onMenuClose()">
  <ng-template [cdkPortalOutlet]="portal$ | async"></ng-template>
</ion-menu>

<!-- <div class="ion-page" id="menu-view">
  <ng-content></ng-content>
</div> -->