export enum MealName {
  Breakfast = 'Breakfast',
  Lunch = 'Lunch',
  Diner = 'Dinner'
}

export enum SnackName {
  Snack_AM = 'Snack AM',
  Snack_PM = 'Snack PM',
  Snack_HS = 'Snack HS'
}
