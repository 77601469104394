import { schema } from 'normalizr';

import { dinersSchema } from '.';
import { MealPlan, TransactionRefund } from '../';
import {
  DeliveryType,
  PosBaseTicket,
  RefundTicket,
  TakeoutOrderPaymentStatus,
  TakeoutOrderStatus,
  Tax,
  Ticket,
  TicketDiscount,
  TicketSource,
  TicketStatus,
  TicketType
} from '../ticket.model';
import { ticketItemsSchema } from './normalized-ticket-item.model';
import { transactionsSchema } from './normalized-transaction.model';

const ticketProcessStrategy = (entity: Ticket) => {
  //Transform balances to decimal numbers as BE will return them as string
  const propNames = [
    'outstanding_balance',
    'calculated_total',
    'mealplan_balance_used',
    'pre_tax_subtotal',
    'tax_total',
    'total_base_price',
    'total_discounts'
  ];
  propNames.forEach((propName) => {
    if (entity[propName]) {
      entity[propName] = parseFloat(entity[propName]);
    }
  });

  return entity;
};

export const ticketsSchema = new schema.Entity<Ticket>(
  'tickets',
  {
    ticket_items: [ticketItemsSchema],
    transactions: [transactionsSchema],
    diner: dinersSchema
  },
  {
    idAttribute: 'device_ticket_uuid',
    processStrategy: ticketProcessStrategy
  }
);
export const ticketsSchemaArray = new schema.Array(ticketsSchema);

export interface NormalizedTicket extends PosBaseTicket {
  applied_discount_id?: number;
  calculated_total?: number;
  created_at?: string;
  delivery_note?: string;
  delivery_type?: DeliveryType;
  device_id?: string;
  device_ticket_uuid: string;
  diner_id?: number;
  diner_name?: string;
  diner?: number;
  id?: number;
  locked?: boolean;
  locked_by_operator_id?: number;
  meal_id?: number;
  meal_plan?: MealPlan;
  meal_plan_id?: number;
  mealplan_balance_used?: number;
  mealplan_tender_type?: string;
  number?: number;
  operator_id?: number;
  outstanding_balance?: number;
  pre_tax_subtotal?: number;
  seat_id?: number;
  status?: TicketStatus;
  table_id?: number;
  tax_exempt?: boolean;
  tax_exemption_reason?: string;
  tax_total?: number;
  ticket_items?: string[];
  total_base_price?: number;
  total_discounts?: number;
  transactions?: number[];
  type?: TicketType;
  taxes?: Tax[];
  refunds?: TransactionRefund[];
  refund_tickets?: RefundTicket[];
  discounts?: TicketDiscount[];
  meal_plan_debited?: boolean;
  askedForApplyMealPlan?: boolean;
  local_attributes: {
    isItemsFired?: boolean;
  };
  change_due?: number;
  can_be_cancelled?: boolean;
  meal_delivery_area_timing_id?: number;
  order_source?: TicketSource;
  ticket_date: string;
  course?: number;
  takeout_order_status?: TakeoutOrderStatus;
  takeout_order_payment_status?: TakeoutOrderPaymentStatus;
}
