import _ from 'lodash';

import { GeneralHelper, ScaleHelper } from '.';
import { CommonConstant } from '../constants';
import {
  Diner,
  DinerType,
  MacroGrid,
  MacroGridItem,
  MealTime,
  Menu,
  MenuCategory,
  MenuItem,
  MenuItemType,
  NormalizedDiner,
  PriceType,
  ProductType,
  Scale
} from '../models';
import {
  POSMenu,
  PosMenuItem,
  POSProduct,
  PosQuantityRestriction
} from 'src/app/models/pos-manager.model';
import {
  PosStoredMenuTally,
  ResidentMenuTally
} from 'src/app/store/menu/menu.state.model';

export class MenuHelper {
  /** Add the price to the given menu item */
  static addPriceTo(menu_item: MenuItem, course?: number) {
    MenuHelper.addScaleData(menu_item);
    // Set price
    menu_item.sort = menu_item.sort || CommonConstant.MAX_VALUE;
    menu_item.course =
      course || CommonConstant.ALL_COURSES.includes(menu_item.course)
        ? menu_item.course
        : CommonConstant.NO_COURSE;
    menu_item.amount = menu_item.amount || 1;
    menu_item.unit = menu_item.unit || 'Each';
    menu_item.price_type = menu_item.price_type || PriceType.FIXED;
    menu_item.price_before_meal_plan_discount = menu_item.price;
    menu_item.price_after_meal_plan_discount = menu_item.price;
    return menu_item;
  }

  static addScaleData(menu_item: MenuItem) {
    if (
      menu_item.price_type === PriceType.FIXED ||
      menu_item.price_type === PriceType.VARIABLE
    ) {
      return;
    }

    const scaleData: Scale = {
      weight: 0,
      weight_unit: ScaleHelper.PriceTypeToWeightType(menu_item.price_type),
      weight_unit_price: menu_item.price,
      item_price_by_weight: 0
    };
    menu_item.scale = scaleData;
  }

  static getAllCategoryItems(menuItems: MenuItem[]): _.Dictionary<MenuItem[]> {
    return _.groupBy(
      menuItems.filter(
        (i) =>
          !i.modifier &&
          (i.price_type === PriceType.FIXED ||
            // allow variable gift card
            (i.price_type === PriceType.VARIABLE &&
              i.product_type === ProductType.GIFT_CARD))
      ),
      'category_id'
    );
  }

  static getDisplayMenuItems(
    menuItems: MenuItem[],
    selectedMacroGrid: MacroGrid | MacroGridItem
  ): _.Dictionary<MenuItem[]> {
    let result = _.cloneDeep(menuItems);

    if (
      selectedMacroGrid &&
      'category_details' in selectedMacroGrid &&
      selectedMacroGrid.category_details
    ) {
      result = result.filter(
        (i) => i.category_id === selectedMacroGrid.category_details.category_id
      );
    }

    return _.groupBy(
      result.filter(
        (i) =>
          !i.modifier &&
          (i.price_type === PriceType.FIXED ||
            // allow variable gift card
            (i.price_type === PriceType.VARIABLE &&
              i.product_type === ProductType.GIFT_CARD))
      ),
      'category_id'
    );
  }

  static getCategoriesByDisplayMenuItems(
    displayMenuItems: _.Dictionary<MenuItem[]>,
    menu: Menu
  ): MenuCategory[] {
    const displayCategoryIds = Object.keys(displayMenuItems);

    return menu.categories
      .filter(
        (category) =>
          displayCategoryIds.includes(`${category.id}`) && !category.modifier
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  static applyQuantityRestrictions(
    menu: Menu,
    restrictions: PosQuantityRestriction[]
  ): Menu {
    let currentMenuItems = menu?.menu_items || [];
    let currentAAMenuItems = menu?.also_available || [];
    let currentProducts = menu?.products || [];

    //Apply quantity restrictions to menu items, also available menu items and products
    if (currentAAMenuItems?.length) {
      currentAAMenuItems = currentAAMenuItems.map((item) => {
        const quantity = restrictions.find((q) => q.food_id === item.food_id);
        return { ...item, quantity_restriction: quantity || null };
      });
    }

    if (currentMenuItems?.length) {
      currentMenuItems = currentMenuItems.map((item) => {
        const quantity = restrictions.find((q) => q.food_id === item.food_id);
        return { ...item, quantity_restriction: quantity || null };
      });
    }

    if (currentProducts?.length) {
      currentProducts = currentProducts.map((product) => {
        const quantity = restrictions.find((q) => q.product_id === product.id);
        return { ...product, quantity_restriction: quantity || null };
      });
    }

    //Update the menu object with the quantity restricted items and products
    menu = {
      ...menu,
      menu_items: currentMenuItems,
      also_available: currentAAMenuItems,
      products: currentProducts
    };

    return menu;
  }

  /**
   * This function is used to get the id of the menu item or product
   * @param item food or product
   */
  static getIdFromMenuItem(item: PosMenuItem | POSMenu | POSProduct): {
    food_id: number;
    pos_product_id: number;
  } {
    const food_id = item['food_id'];
    return {
      food_id,
      pos_product_id: food_id ? null : item['id']
    };
  }

  static applyRestrictionsForPosManagerMenu(
    menuItems: POSMenu[],
    products: POSProduct[],
    qtyRestrictions: PosQuantityRestriction[]
  ): ((POSMenu | POSProduct) & {
    pos_quantity_restriction: PosQuantityRestriction | null;
  })[] {
    const result = _.concat<POSMenu | POSProduct>(menuItems, products).map(
      (i) => {
        const restriction = qtyRestrictions.find((qr) => {
          const { food_id, pos_product_id } = this.getIdFromMenuItem(i),
            isFood = !_.isNil(food_id),
            isProduct = !_.isNil(pos_product_id);
          if (isFood) {
            return qr.food_id === food_id;
          }

          if (isProduct) {
            return qr.product_id === pos_product_id;
          }

          return false;
        });

        return { ...i, pos_quantity_restriction: restriction || null };
      }
    );
    return result;
  }

  static applyRestrictionForMacroGridItem(
    items: MacroGridItem[],
    quantity_restrictions: PosQuantityRestriction[]
  ): MacroGridItem[] {
    return items?.map((i) => {
      const productId = i.product_details?.id;
      const quantity_restriction = productId
        ? quantity_restrictions.find(
            (qr) => qr.product_id === i.product_details.id
          )
        : null;

      return { ...i, quantity_restriction };
    });
  }

  static getSelectedMenuDate(mealDateString: string): string {
    return mealDateString.slice(0, 10);
  }

  static isMenuItemSoldOut(
    id: number,
    food_id: number,
    type: MenuItemType,
    qtyRestrictions: PosQuantityRestriction[]
  ): boolean {
    switch (type) {
      case MenuItemType.Food:
        return !!qtyRestrictions.find((qr) => qr.food_id === food_id)?.sold_out;
      case MenuItemType.Product:
        return !!qtyRestrictions.find((qr) => qr.product_id === id)?.sold_out;
      default:
        return false;
    }
  }

  static checkMenuItemsMatchingDinerAllergens(
    items: MenuItem[],
    dinerAllergens: string[]
  ): void {
    items.forEach((mi) => {
      const menuItemAllergens = mi.allergen
        ? mi.allergen.split(',').map((mia) => mia.toLowerCase().trim())
        : null;
      if (menuItemAllergens) {
        const matchingAllergens = menuItemAllergens.reduce(
          (accumulator: string[], current) =>
            dinerAllergens.includes(current)
              ? [...accumulator, current]
              : accumulator,
          []
        );
        if (matchingAllergens.length) {
          mi.allergenMatchingDiner = matchingAllergens.join(',');
        }
      }
    });
  }

  static getAllMenuItems(
    menu: Menu,
    menu_tally: PosStoredMenuTally,
    selectedDiner: Diner,
    meal_id: MealTime
  ): MenuItem[] {
    const dinerMenuTally = MenuHelper.getSelectedMenuTally(
        menu_tally,
        selectedDiner?.id,
        meal_id
      ),
      isLoadMenuTally =
        selectedDiner?.type === DinerType.Resident && !!dinerMenuTally;

    let menuData = _.cloneDeep(menu);

    if (isLoadMenuTally) {
      const tallyData = _.cloneDeep(dinerMenuTally);
      menuData = { ...menuData, ...tallyData };
    }

    if (!menuData) {
      return [];
    }

    if (!isLoadMenuTally) {
      if (
        selectedDiner &&
        selectedDiner.type === DinerType.Resident &&
        selectedDiner.allergens
      ) {
        const allergens = selectedDiner.allergens
          .split(';')
          .map((a) => a.toLowerCase().replace('allergen', ''));
        if (menuData.menu_items.length) {
          MenuHelper.checkMenuItemsMatchingDinerAllergens(
            menuData.menu_items,
            allergens
          );
        }
        if (menuData.also_available.length) {
          MenuHelper.checkMenuItemsMatchingDinerAllergens(
            menuData.also_available,
            allergens
          );
        }
      }
    }

    let result = menuData.menu_items
      .map((mi) => {
        mi.is_also_available = false;
        return mi;
      })
      .sort((a, b) => GeneralHelper.alphaSortByProperty('name', a, b));
    if (menuData.also_available) {
      result = result.concat(
        menuData.also_available
          .map((mi) => {
            mi.is_also_available = true;
            return mi;
          })
          .sort((a, b) => GeneralHelper.alphaSortByProperty('name', a, b))
      );
    }
    if (menuData.products) {
      result = result.concat(
        menuData.products.sort((a, b) =>
          GeneralHelper.alphaSortByProperty('name', a, b)
        )
      );
    }

    return result;
  }

  static getMenuItems(
    selected_macro_grid: MacroGrid | MacroGridItem,
    menu: Menu,
    menu_tally: PosStoredMenuTally,
    selectedDiner: NormalizedDiner,
    isServiceAppointment: boolean,
    meal_id: MealTime
  ): MenuItem[] {
    const menuTally = MenuHelper.getSelectedMenuTally(
        menu_tally,
        selectedDiner?.id,
        meal_id
      ),
      isLoadMenuTally =
        selectedDiner?.type === DinerType.Resident && !!menuTally;

    let menuData = _.cloneDeep(menu);

    if (isLoadMenuTally) {
      const tallyData = _.cloneDeep(menuTally);
      menuData = { ...menuData, ...tallyData };
    }

    if (!menuData) {
      return [];
    }

    const category_details =
      selected_macro_grid &&
      (<MacroGridItem>selected_macro_grid).category_details;

    if (category_details && !isServiceAppointment) {
      if (!category_details.show_standard_menu_items) {
        menuData.menu_items = [];
      }
      if (!category_details.show_non_food_products) {
        menuData.products = [];
      }
      if (!category_details.show_also_available_menu) {
        menuData.also_available = [];
      }
    }

    if (!isLoadMenuTally) {
      if (
        selectedDiner &&
        selectedDiner.type === DinerType.Resident &&
        selectedDiner.allergens
      ) {
        const allergens = selectedDiner.allergens
          .split(';')
          .map((a) => a.toLowerCase().replace('allergen', ''));
        if (menuData.menu_items.length) {
          MenuHelper.checkMenuItemsMatchingDinerAllergens(
            menuData.menu_items,
            allergens
          );
        }
        if (menuData.also_available.length) {
          MenuHelper.checkMenuItemsMatchingDinerAllergens(
            menuData.also_available,
            allergens
          );
        }
      }
    }

    let result = menuData.menu_items
      .map((mi) => {
        mi.is_also_available = false;
        return mi;
      })
      .sort((a, b) => GeneralHelper.alphaSortByProperty('name', a, b));
    if (menuData.also_available) {
      result = result.concat(
        menuData.also_available
          .map((mi) => {
            mi.is_also_available = true;
            return mi;
          })
          .sort((a, b) => GeneralHelper.alphaSortByProperty('name', a, b))
      );
    }
    if (menuData.products) {
      result = result.concat(
        menuData.products.sort((a, b) =>
          GeneralHelper.alphaSortByProperty('name', a, b)
        )
      );
    }

    return result;
  }

  private static getSelectedMenuTally(
    menu_tally: PosStoredMenuTally,
    dinerId: number,
    meal_id: MealTime
  ): ResidentMenuTally {
    const menuTally = menu_tally || {},
      dinerMenuTally = menuTally[dinerId] || {};

    return dinerMenuTally[meal_id];
  }
}
