import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { ModalController } from '@ionic/angular';

export interface ConfirmModalDismissData {
  isRedBtnClick?: boolean;
  isGrayBtnClick?: boolean;
}

@Component({
  selector: 'pos-confirm-modal',
  templateUrl: './confirm.modal.html',
  styleUrls: ['./confirm.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmModal {
  @Input() header: string;
  @Input() subTitle: string;
  @Input() redBtnLabel = 'No';
  @Input() grayBtnLabel = 'Yes';
  @Input() cssClass: string;
  @Input() redBtnHidden = false;

  readonly faTimes = faTimes;

  constructor(private readonly modalCtrl: ModalController) {}

  closeModal() {
    this.modalCtrl.dismiss();
  }

  onClick(isGrayBtn: boolean) {
    if (isGrayBtn) {
      this.modalCtrl.dismiss({ isGrayBtnClick: true });
    } else {
      this.modalCtrl.dismiss({ isRedBtnClick: true });
    }
  }
}
