import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomPriceItemModal } from './custom-price-item.modal';
import { IonicModule } from '@ionic/angular';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { NumericKeyboardComponentModule } from 'src/app/components/shared/numeric-keyboard/numeric-keyboard-component.module';

@NgModule({
  declarations: [CustomPriceItemModal],
  imports: [
    CommonModule,
    IonicModule,
    DebounceClickDirectiveModule,
    NumericKeyboardComponentModule
  ],
  exports: [CustomPriceItemModal]
})
export class CustomPriceItemModalModule {}
