import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModifierPromptsAssignmentModal } from './modifier-prompts-assignment.modal';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ModifierPromptsAssignmentModal],
  imports: [CommonModule, IonicModule, NgSelectModule, ReactiveFormsModule],
  exports: [ModifierPromptsAssignmentModal]
})
export class ModifierPromptsAssignmentModalModule {}
