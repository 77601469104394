import { MealTime } from 'src/app/models';

export class MealConstant {
  static readonly BREAKFAST_ID = MealTime.Breakfast;
  static readonly LUNCH_ID = MealTime.Lunch;
  static readonly DINNER_ID = MealTime.Dinner;
  static readonly MEAL_IDS = [
    MealConstant.BREAKFAST_ID,
    MealConstant.LUNCH_ID,
    MealConstant.DINNER_ID
  ];

  static readonly SNACK_AM_ID = 2;
  static readonly SNACK_PM_ID = 4;
  static readonly SNACK_HS_ID = 6;
  static readonly SNACK_IDS = [
    MealConstant.SNACK_AM_ID,
    MealConstant.SNACK_PM_ID,
    MealConstant.SNACK_HS_ID
  ];

  static readonly MEAL_NOTE_PROPERTY_NAME_MAP: { [key: string]: string } = {
    1: 'breakfast_note',
    3: 'lunch_note',
    5: 'dinner_note'
  };
}
