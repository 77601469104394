import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { EditTableModal } from './edit-table.modal';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';

@NgModule({
  declarations: [EditTableModal],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    DebounceClickDirectiveModule
  ],
  exports: [EditTableModal]
})
export class EditTableModalModule {}
