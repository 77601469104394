import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable, tap } from 'rxjs';

import {
  RefundResponse,
  RefundTransactionRequest,
  TransactionFilter,
  TransactionList
} from 'src/app/models';
import { BaseService } from '../base.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { ApiType } from 'src/app/pos.config';

@Injectable({
  providedIn: 'root'
})
export class TransactionLookupService extends BaseService {
  constructor(
    store: Store,
    private http: HttpClient,
    private readonly errorService: ErrorService
  ) {
    super(store);
  }

  getTransactionUrl(id?) {
    const url = 'transactions';
    if (id) {
      return `${url}/${id}`;
    }
    return url;
  }

  getTransactions(
    params: Partial<TransactionFilter>
  ): Observable<TransactionList[]> {
    return this.http
      .get<TransactionList[]>(this.getTransactionUrl(), {
        params
      })
      .pipe(this.errorService.retryPipe(ApiType.Query));
  }

  getMealPlanTransactions(
    params: Partial<TransactionFilter>
  ): Observable<TransactionList[]> {
    return this.http
      .get<{ success: boolean; transactions: TransactionList[] }>(
        'mealplan_transactions',
        { params }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => (response?.success ? response.transactions : []))
      );
  }

  refund(
    ticket_uuid: string,
    pos_operator_id: number,
    ticket_items_uuid: string[]
  ) {
    let requestBody: any = { pos_operator_id };
    if (ticket_items_uuid?.length) {
      requestBody = {
        ...requestBody,
        individual_ticket_item_ids: ticket_items_uuid.join(',')
      };
    }

    return this.http
      .post<RefundResponse>(
        this.getLocationUrl(`tickets/${ticket_uuid}/refund`),
        null,
        { params: requestBody }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        tap((response) => console.log('ddevbug refund response: ', response))
      );
  }

  addRefundTransaction(
    ticket_uuid: string,
    refund_request: RefundTransactionRequest,
    pos_operator_id: number
  ) {
    return this.http
      .post<RefundResponse>(
        this.getLocationUrl(`tickets/${ticket_uuid}/add_refund_transaction`),
        null,
        { params: { ...refund_request, pos_operator_id } }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        tap((response) =>
          console.log('ddevbug addRefundTransaction response: ', response)
        )
      );
  }
}
