import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WeightedItemModal } from './weighted-item.modal';
import { IonicModule } from '@ionic/angular';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { FormsModule } from '@angular/forms';
import { NumericKeyboardComponentModule } from 'src/app/components/shared/numeric-keyboard/numeric-keyboard-component.module';

@NgModule({
  declarations: [WeightedItemModal],
  imports: [
    CommonModule,
    IonicModule,
    DebounceClickDirectiveModule,
    FormsModule,
    NumericKeyboardComponentModule
  ],
  exports: [WeightedItemModal]
})
export class WeightedItemModalModule {}
