import { schema } from 'normalizr';
import {
  ChargeAccount,
  Diet,
  DietIntervention,
  Diner,
  DinerType,
  Fluid,
  MealPlan,
  Texture
} from '..';

export const dinersSchema = new schema.Entity<Diner>(
  'diners',
  {},
  {
    idAttribute: 'id'
  }
);
export const dinersSchemaArray = new schema.Array(dinersSchema);

export interface NormalizedDiner {
  id: number;
  first_name: string;
  last_name: string;
  type?: DinerType;
  live_location?: string;
  client_id?: number;
  ehr_internal_id?: number;
  ehr_external_id?: number;
  badge_id?: number;
  mealsuite_id?: number;
  photo?: string;
  allergens?: string;
  breakfast_note?: string;
  lunch_note?: string;
  dinner_note?: string;
  daily_note?: string;
  misc_note?: string;
  diets?: Diet[];
  textures?: Texture[];
  fluid?: Fluid;
  charge_accounts?: ChargeAccount[];
  meal_plan?: MealPlan;
  diet_interventions?: DietIntervention[];
}
