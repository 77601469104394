import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';

import { UnlockTicket } from '../store/tickets/tickets.action';
import { TICKETS_STATE_TOKEN } from 'src/app/store/tickets/tickets.state';

@Injectable({ providedIn: 'root' })
export class UnlockTicketResolver implements Resolve<boolean> {
  constructor(private store: Store) {}

  resolve(): boolean {
    const ticketsState = this.store.selectSnapshot(TICKETS_STATE_TOKEN);
    const selectedTicketId = ticketsState.selectedTicket.data;
    const ticketsById = ticketsState.tickets.byId;
    const selectedTicket =
      !!selectedTicketId && !!ticketsById
        ? ticketsById[selectedTicketId]
        : null;

    if (selectedTicket && selectedTicket?.locked) {
      this.store.dispatch(new UnlockTicket(selectedTicket.device_ticket_uuid));
    }

    return true;
  }
}
