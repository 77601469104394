import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PatientInformationModal } from './patient-information.modal';
import { DebounceClickDirectiveModule } from 'src/app/directives/debounce-click/debounce-click-directive.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [PatientInformationModal],
  imports: [CommonModule, IonicModule, DebounceClickDirectiveModule],
  exports: [PatientInformationModal]
})
export class PatientInformationModalModule {}
