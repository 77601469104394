export enum DinerAssignmentView {
  PREASSIGNED = 'preassigned',
  LOOKUP = 'lookup',
  OTHER_FACILITIES = 'other_facilities'
}

export class ViewConstant {
  static readonly DINER_ASSIGNMENT_VIEW = {
    PREASSIGNED: DinerAssignmentView.PREASSIGNED,
    LOOKUP: DinerAssignmentView.LOOKUP,
    OTHER_FACILITIES: DinerAssignmentView.OTHER_FACILITIES
  };

  static readonly HOST_MODE_TAB_VIEW = {
    TABLE: 'table',
    DINER: 'diner'
  };
}
