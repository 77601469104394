import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { OrderSetupModal } from './order-setup.component';
import { TimePickerComponentModule } from './time-picker/time-picker-component.module';
import { OrderTypeRadioGroupComponentModule } from './order-type-radio-group/order-type-radio-group-component.module';
import { MealRadioGroupComponentModule } from './meal-radio-group/meal-radio-group-component.module';

@NgModule({
  declarations: [OrderSetupModal],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TimePickerComponentModule,
    OrderTypeRadioGroupComponentModule,
    MealRadioGroupComponentModule
  ],
  exports: [OrderSetupModal]
})
export class OrderSetupModalModule {}
