import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';

import {
  POSSchedule,
  POSScheduleRequest
} from 'src/app/models/pos-manager.model';
import { ErrorService } from 'src/app/services/error/error.service';
import { ApiType } from 'src/app/pos.config';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { AppStateHelper } from 'src/app/store/app/app.state.helper';

@Injectable({
  providedIn: 'root'
})
export class PosSchedulesService {
  private SCHEDULE_API = 'service_calendars';

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store,
    private readonly errorService: ErrorService
  ) {}
  private getBaseUrl(): string {
    const baseUrl = this.store.selectSnapshot(APP_STATE_TOKEN).apiBaseUrl;
    const posManagerApiUrl = AppStateHelper.getPosManagerApiUrl(baseUrl);

    return `${posManagerApiUrl}${this.SCHEDULE_API}`;
  }

  getSchedules() {
    return this.http
      .get<{ service_calendars: POSSchedule[] }>(`${this.getBaseUrl()}`)
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.service_calendars)
      );
  }
  getScheduleDetail(id: number) {
    return this.http
      .get<{ service_calendar: POSSchedule }>(`${this.getBaseUrl()}/${id}`)
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.service_calendar)
      );
  }

  createSchedule(schedule: Partial<POSScheduleRequest>) {
    if (!schedule) {
      throw new Error('Schedule can not be empty');
    }
    return this.http
      .post<{ service_calendar: POSSchedule }>(`${this.getBaseUrl()}`, schedule)
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.service_calendar)
      );
  }

  updateSchedule(id: number, data: Partial<POSScheduleRequest>) {
    if (!id) {
      throw new Error('Schedule id can not be empty');
    }
    if (!data) {
      throw new Error('Schedule data can not be empty');
    }
    return this.http
      .put<{ service_calendar: POSSchedule }>(
        `${this.getBaseUrl()}/${id}`,
        data
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.service_calendar)
      );
  }

  deleteSchedule(id: number) {
    return this.http
      .delete<{ success: boolean }>(`${this.getBaseUrl()}/${id}`)
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.success)
      );
  }
}
