import { HttpErrorResponse } from '@angular/common/http';
import {
  GatewayTransactionResult,
  GiftCard,
  PosPaymentRequest,
  PaymentResult,
  Ticket
} from 'src/app/models';

export class GetBusinessLines {
  static readonly type = '[Payment] Get Business Lines';
}

export class GetDepartments {
  static readonly type = '[Payment] Get Departments';
  constructor(public search_text?: string, public business_line_id?: number) {}
}

export class ResetDepartments {
  static readonly type = '[Payment] Reset Departments';
}

export class MakePayment {
  static readonly type = '[Payment] Make Payment';
  constructor(
    public readonly ticket: Ticket,
    public readonly payment_request: PosPaymentRequest
  ) {}
}

export class MakePaymentFailed {
  static readonly type = '[Payment] Make Payment Failed';
  constructor(
    public readonly error: HttpErrorResponse,
    public readonly paymentData: MakePayment
  ) {}
}

export class MakePaymentError {
  static readonly type = '[Payment] Make Payment Error';
  constructor(
    public readonly error: HttpErrorResponse,
    public readonly posPaymentData: MakePayment
  ) {}
}

export class MakePaymentSuccess {
  static readonly type = '[Payment] Make Payment Success';
  constructor(
    public device_ticket_uuid: string,
    public payment: PaymentResult
  ) {}
}

export class InitiateGatewayTransaction {
  static readonly type = '[Payment] Initiate Gateway Transaction';
  constructor(public gatewayTransactionResult: GatewayTransactionResult) {}
}

export class OnRetryGatewayTransaction {
  static readonly type = '[Payment] Retry Gateway Transaction';
}

export class OnGatewayTransactionSuccess {
  static readonly type = '[Payment] Gateway Transaction Success';
}

export class OnGatewayTransactionError {
  static readonly type = '[Payment] Gateway Transaction Error';
  constructor(
    public readonly gatewayError: string,
    public readonly cmdStatus?: string
  ) {}
}

export class GetGiftCard {
  static readonly type = '[Payment] Get gift card';
  constructor(public identification_number: string) {}
}

export class GetGiftCardSuccess {
  static readonly type = '[Payment] Get gift card success';
  constructor(public giftCard: GiftCard) {}
}

export class GetGiftCardFailed {
  static readonly type = '[Payment] Get gift card failed';
  constructor(public giftCard: GiftCard) {}
}

export class CancelGatewayTransaction {
  static readonly type = '[Payment Page] Cancel Gateway Transaction';
}

export class CancelGatewayTransactionSuccess {
  static readonly type = '[Payment Page] Cancel Gateway Transaction Success';
}

export class PayOnPickup {
  static readonly type = '[Payment Page] Pay on Pickup';
  constructor(public deviceTicketUuid: string) {}
}

export class PayOnPickupSuccess {
  static readonly type = '[Payment Page] Pay on Pickup Success';
  constructor(public readonly ticket: Ticket) {}
}

export class PayOnPickupError {
  static readonly type = '[Payment Page] Pay on Pickup Error';
  constructor(public errorMessage: string) {}
}

export class FinalizeMealPlanTicket {
  static readonly type = '[Payment] Finalize Meal Plan Ticket';
  constructor(public readonly deviceTicketUuid: string) {}
}

export class FinalizeMealPlanTicketSuccess {
  static readonly type = '[Payment] Finalize Meal Plan Ticket Success';
  constructor(public readonly ticket: Ticket) {}
}

export class FinalizeMealPlanTicketError {
  static readonly type = '[Payment] Finalize Meal Plan Ticket Error';
  constructor(
    public readonly errorMessage: string,
    public readonly error?: HttpErrorResponse
  ) {}
}
