import { BaseData, WeightUnit } from '.';
import {
  POSModifierPromptAssignment,
  PosQuantityRestriction
} from './pos-manager.model';

export enum ProductType {
  INVENTORIED_PRODUCT = 'inventoried_product',
  NON_INVENTORIED_PRODUCT = 'non_inventoried_product',
  GIFT_CARD = 'gift_card',
  SERVICE = 'service'
}

export type PriceByWeightType =
  | 'price_by_g'
  | 'price_by_oz'
  | 'price_by_lb'
  | 'price_by_kg';

export enum PriceType {
  FIXED = 'fixed',
  PRICE_BY_G = 'price_by_g',
  PRICE_BY_KG = 'price_by_kg',
  PRICE_BY_OZ = 'price_by_oz',
  PRICE_BY_LB = 'price_by_lb',
  VARIABLE = 'variable'
}

export enum MenuItemType {
  Food = 'food',
  Product = 'product',
  Plate = 'plate'
}

export enum ProductRefillType {
  FIXED = 'fixed',
  VARIABLE = 'variable'
}

export const PriceTypeWeightTypeMap: Record<PriceByWeightType, WeightUnit> = {
  price_by_g: WeightUnit.Gram,
  price_by_oz: WeightUnit.Ounce,
  price_by_lb: WeightUnit.Pound,
  price_by_kg: WeightUnit.Kilograms
};

export interface MenuCategory {
  id: number;
  modifier: boolean;
  name: string;
  course: number;
  overwritten_names: string[];
}

export interface Menu {
  menu_items: MenuItem[];
  also_available: MenuItem[];
  products?: Product[];
  plates?: Plate[];
  diner_id?: number;
  categories: MenuCategory[];
}

export interface BaseMenuItem extends BaseData {
  food_id?: number;
  price?: number;
  category_id: number;
  price_type: PriceType;
  course?: number;
  amount: number | string;
  unit: string;
  can_be_discounted?: boolean;
  sort?: number;
  is_like?: boolean;
  is_dislike?: boolean;
  quantity?: number;
  product_type?: ProductType;
  refill_type?: ProductRefillType;
  type?: MenuItemType;
  scale?: Scale;
  price_before_meal_plan_discount?: number;
  price_after_meal_plan_discount?: number;
  is_declining_balance_plan?: boolean;
  is_discounted_by_meal_plan?: boolean;
  meal_plan_discount_amount?: number;
  barcode?: string;
  gift_card_code?: string;
  is_also_available?: boolean;
  modifier_prompt_assignments?: POSModifierPromptAssignment[];
  modifier: boolean;
}

export interface MenuItem extends BaseMenuItem {
  allergen?: string;
  src_food_id?: number;
  extraPropShowPriceAfterDiscount?: boolean;
  extraPropIsVariablePrice?: boolean;
  extraPropHasStrikeText?: boolean;
  extraPropIsCovered?: boolean;
  allergenMatchingDiner?: string;
  quantity_restriction?: PosQuantityRestriction;
}

export type MenuQuantityRestriction = Omit<PosQuantityRestriction, 'id'>;

export interface Product extends BaseMenuItem {
  barcode?: string;
}

export interface PlateMenuItem extends MenuItem {
  selected?: boolean;
}

export interface Plate extends BaseData {
  is_also_available: boolean;
  price: number;
  photo: string;
  child_item: PlateMenuItem[];
  selected_child_item: PlateMenuItem[];
}

export interface Scale {
  weight?: number;
  weight_without_container?: number;
  weight_unit?: WeightUnit;
  weight_unit_price?: number;
  item_price_by_weight?: number;
}

export interface MenuTallyFoodDetail {
  always_order: boolean;
  amount: string;
  category_id: number;
  category_sort: number;
  choices: number[];
  day: number;
  display_name: string;
  food_id: number;
  increment_amount: number;
  is_dislike: boolean;
  is_from_aa_menu: boolean;
  is_like: boolean;
  is_sub: boolean;
  item_source: string;
  menu_id: number;
  unit: string;
  week: number;
  food_specific_note?: string;

  price_type?: PriceType;
  modifier?: boolean;
}

export interface RequestMealId {
  dateMealString: string;
  date: string;
  mealId: number;
}
