import { PaymentRequestType } from '../models';

export class RefundsConstants {
  static readonly REFUND_METHODS_MAP = {
    [PaymentRequestType.CASH]: {
      id: PaymentRequestType.CASH,
      icon: 'cash-outline',
      key: 'cash'
    },
    [PaymentRequestType.CREDIT_CARD]: {
      id: PaymentRequestType.CREDIT_CARD,
      icon: 'card-outline',
      key: 'credit_card'
    },
    [PaymentRequestType.CREDIT_CARD_TRANCLOUD]: {
      id: PaymentRequestType.CREDIT_CARD_TRANCLOUD,
      icon: 'card-outline',
      key: 'credit_card_trancloud'
    },
    [PaymentRequestType.CREDIT_CARD_DC_DIRECT]: {
      id: PaymentRequestType.CREDIT_CARD_DC_DIRECT,
      icon: 'card-outline',
      key: 'credit_card_dc_direct'
    },
    [PaymentRequestType.GIFT_CARD]: {
      id: PaymentRequestType.GIFT_CARD,
      icon: 'gift-outline',
      key: 'gift_card'
    },
    [PaymentRequestType.BILL_TO_ROOM]: {
      id: PaymentRequestType.BILL_TO_ROOM,
      icon: 'bed-outline',
      key: 'bill_to_room'
    },
    [PaymentRequestType.CHARGE_ACCOUNT]: {
      id: PaymentRequestType.CHARGE_ACCOUNT,
      icon: 'person-circle-outline',
      key: 'diner_charge_account'
    }
  };
}
