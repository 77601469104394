import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { produce } from 'immer';
import _ from 'lodash';

import {
  GetTicketsSuccess,
  UpdateLocalTicketRelationships
} from '../tickets/tickets.action';
import { NormalizedTransaction } from 'src/app/models/normalized-models/normalized-transaction.model';
import { NormalizrHelper } from 'src/app/helpers';
import { MakePaymentSuccess } from 'src/app/store/payment/payment.action';
import { NormalizedObject } from 'src/app/models';

export interface TransactionsStateModel {
  transactions: NormalizedObject<NormalizedTransaction, number>;
}

export const TRANSACTIONS_STATE_TOKEN = new StateToken<TransactionsStateModel>(
  'transactions'
);

@State({
  name: TRANSACTIONS_STATE_TOKEN,
  defaults: {
    transactions: {
      byId: null,
      allIds: []
    }
  }
})
@Injectable()
export class TransactionsState {
  constructor() {}

  @Selector()
  static transactions(state: TransactionsStateModel) {
    return state.transactions;
  }

  @Action([GetTicketsSuccess, UpdateLocalTicketRelationships])
  getTicketsSuccess(
    ctx: StateContext<TransactionsStateModel>,
    payload: GetTicketsSuccess | UpdateLocalTicketRelationships
  ) {
    const normalizedTransactions = payload.entities.transactions;
    if (normalizedTransactions) {
      ctx.setState(
        produce((draft: TransactionsStateModel) => {
          draft.transactions = {
            byId: {
              ...draft.transactions.byId,
              ...normalizedTransactions
            },
            allIds: _.union(
              draft.transactions.allIds,
              Object.keys(normalizedTransactions).map((key) => +key)
            )
          };
        })
      );
    }
  }

  @Action(MakePaymentSuccess)
  makePaymentSuccess(
    ctx: StateContext<TransactionsStateModel>,
    { payment }: MakePaymentSuccess
  ) {
    const normalizeTransactions = NormalizrHelper.normalizeTransactions([
      payment.transaction
    ]).entities.transactions;
    ctx.setState(
      produce((draft: TransactionsStateModel) => {
        if (normalizeTransactions) {
          draft.transactions = {
            byId: {
              ...draft.transactions.byId,
              ...normalizeTransactions
            },
            allIds: _.union(
              draft.transactions.allIds,
              Object.keys(normalizeTransactions).map((key) => +key)
            )
          };
        }
      })
    );
  }
}
