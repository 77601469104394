import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { MealDeliveryAreaTiming } from 'src/app/models';
import { ApiType } from 'src/app/pos.config';
import { BaseService } from 'src/app/services/base.service';
import { ErrorService } from 'src/app/services/error/error.service';

@Injectable()
export class TakeoutDeliveryService extends BaseService {
  constructor(
    store: Store,
    private readonly http: HttpClient,
    private readonly errorService: ErrorService
  ) {
    super(store);
  }

  loadMdaTimings(date: string): Observable<MealDeliveryAreaTiming[]> {
    return this.http
      .get<{ meal_delivery_area_timings: MealDeliveryAreaTiming[] }>(
        this.getLocationUrl('meal_delivery_area_timings'),
        {
          params: { date }
        }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((res) => res?.meal_delivery_area_timings)
      );
  }
}
