import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { TicketStatus, TicketTransaction } from '.';

export type PaymentMethodOptionId = PaymentType | 'pay_on_pickup';

export interface PaymentMethodOption {
  id: PaymentMethodOptionId;
  key: string;
  faIcon: IconDefinition;
  self_service_readable_name: string;
  name: string;
}

export enum PaymentType {
  Cash = 'cash',
  Credit = 'credit_card',
  CreditCardTrancloud = 'credit_card_trancloud',
  CreditCardDcDirect = 'credit_card_dc_direct',
  CreditStripe = 'credit_card_stripe',
  ManualCredit = 'manual_credit_card',
  GiftCard = 'gift_card',
  BillToRoom = 'bill_to_room',
  ChargeAccount = 'charge_account',
  ChargeToDepartment = 'charge_to_department',
  MealPlan = 'meal_plan'
}

export enum CreditCardRefundStatus {
  Completed = 'complete',
  Declined = 'declined'
}

export enum PaymentRequestType {
  CASH = 'Pos::Transaction::Cash',
  BILL_TO_ROOM = 'Pos::Transaction::BillToRoom',
  GIFT_CARD = 'Pos::Transaction::GiftCard',
  CHARGE_ACCOUNT = 'Pos::Transaction::ChargeAccount',
  CREDIT_CARD = 'Pos::Transaction::CreditManual',
  CREDIT_CARD_TRANCLOUD = 'Pos::Transaction::CreditTranCloud',
  CREDIT_CARD_DC_DIRECT = 'Pos::Transaction::CreditDcDirect',
  CREDIT_CARD_STRIPE = 'Pos::Transaction::CreditStripe',
  ZERO_VALUE = 'Pos::Transaction::ZeroValue'
}

export interface PaymentMethod {
  name: string;
  key: string;
  code: PaymentType;
}

export interface PosPaymentRequest {
  type: PaymentRequestType;
  amount: number;
  operator_id: number;
  payment_location_id: number;
  cash_amount?: number;
  change_due?: number;
  manual_credit_card_processing?: boolean;
  payment_terminal_id?: number;
  gift_card_identification_number?: string;
  diner_id?: number;
  pos_charge_account_id?: number;
  collect_manual_card_entry_on_pinpad?: boolean;
}

export interface PaymentResult {
  transaction: TicketTransaction;
  new_balance: string;
  ticket_status: TicketStatus;
}

export interface RStream {
  CmdStatus: string;
  DSIXReturnCode: string;
  ResponseOrigin: string;
  SequenceNo: string;
  TextResponse: string;
  UserTrace: string;
}

export interface PaymentGatewayResponse {
  RStream?: RStream;
  Rstream?: RStream;
}

export interface GatewayTransactionResult {
  success: boolean;
  gateway_transaction_id: number;
  status: string;
  transaction_amount: number;
  payment_gateway_response?: PaymentGatewayResponse;
  transaction?: TicketTransaction;
  new_balance?: string;
  ticket_status?: TicketStatus;
  stripe_payment_secret?: string;
  stripe_pk_test?: string;
  stripe_pk_production?: string;
  stripe_account_id?: string;
}

export enum AccordionView {
  TENDER = 'tender',
  AMOUNT = 'amount',
  PAY_AT_PICKUP = 'pay-at-pickup'
}

export enum GatewayTransactionModalView {
  IN_PROGRESS = 'in_progress',
  SUCCESS = 'success',
  ERROR = 'error'
}

export enum CardTerminalTypes {
  TRANCLOUD = 'Pos::CardTerminals::TranCloud',
  STRIPE = 'Pos::CardTerminals::StripeEPos'
}
